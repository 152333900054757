import { OverflowMenuHorizontal, SettingsAdjust } from '@carbon/icons-react'
import { Box, Divider, MenuList } from '@mui/material'
import { GridApiPro } from '@mui/x-data-grid-pro'

import { TableExportSelect } from './filters/TableExportSelect'
import { Menu_Header, use_menu_nav } from './menus'
import { Menu_Panel_Id } from './menus/constants'
import {
  Menu_Item_Label,
  Menu_Item_Right,
  Section,
  Styled_ChevronRight,
  Styled_Menu_Item
} from './menus/shared_styled_components'

type InsightsTableFilterPanelRootProps = {
  tableId: string
  tableTitle: string | undefined
  tableRef?: React.MutableRefObject<GridApiPro>
}

export function InsightsTableFilterPanelRoot(
  props: InsightsTableFilterPanelRootProps
) {
  const { create_set_selected_panel_id } = use_menu_nav()
  const { tableId, tableTitle = '', tableRef } = props

  return (
    <Box width={400}>
      <MenuList>
        <Menu_Header title={tableTitle} />

        <Styled_Menu_Item
          onClick={create_set_selected_panel_id(Menu_Panel_Id.DATASET_COLUMNS)}
        >
          <Menu_Item_Label>
            <OverflowMenuHorizontal height={18} width={18} /> Columns
          </Menu_Item_Label>

          <Menu_Item_Right>
            <Styled_ChevronRight />
          </Menu_Item_Right>
        </Styled_Menu_Item>
        <Styled_Menu_Item
          onClick={create_set_selected_panel_id(Menu_Panel_Id.GROUP_BY)}
        >
          <Menu_Item_Label>
            <SettingsAdjust height={18} width={18} /> Group By
          </Menu_Item_Label>

          <Menu_Item_Right>
            <Styled_ChevronRight />
          </Menu_Item_Right>
        </Styled_Menu_Item>

        <Divider sx={{ marginBlock: 0.5 }} />

        <Section sx={{ paddingBlock: 0 }}>
          <TableExportSelect tableId={tableId} tableRef={tableRef} />
        </Section>
      </MenuList>
    </Box>
  )
}
