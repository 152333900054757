import { Help } from '@carbon/icons-react'

import { Spin, Tooltip } from '@/components'

import { useTranslator } from '../i18n'
import { OhmUserInteractionMessage } from '../types'

type OhmUserMessageProps = {
  message?: OhmUserInteractionMessage
  loading?: boolean
}

export const OhmUserMessage = (props: OhmUserMessageProps) => {
  const { message, loading } = props
  const { content: uiContent, title, feedbackMessage, internal } = message ?? {}

  const { translate } = useTranslator()

  return (
    <div className='max-w-xl self-end rounded-xl bg-blue-100 text-sm'>
      {title && (
        <div className='flex select-none items-center justify-between border-b border-blue-300 px-3 py-1 text-xs text-blue-400'>
          {title}
          {internal && (
            <Tooltip
              arrow={{
                pointAtCenter: true
              }}
              placement='topRight'
              title={translate('thisIsAnInternalInteraction')}
            >
              <Help className='cursor-pointer' size={14} />
            </Tooltip>
          )}
        </div>
      )}
      <div className='px-3 py-2'>{uiContent}</div>
      {feedbackMessage ? (
        <div className='flex select-none items-center justify-end border-t border-blue-300 px-3 py-1 text-xs text-blue-400'>
          {loading ? (
            <div className='flex flex-1 items-center gap-x-2 text-gray-500'>
              <Spin size='small' /> {translate('processing')}
            </div>
          ) : (
            feedbackMessage
          )}
        </div>
      ) : null}
    </div>
  )
}
