import { useAtom, useAtomValue } from 'jotai'
import { isEqual, omit } from 'lodash-es'
import { ComponentProps, useEffect } from 'react'

import { Typography } from '@/components'
import { InsightsChartStateless } from '@/insights/__components/InsightsChartStateless'
import { mapChartStateToChartTemplate } from '@/insights/charts/transform'
import {
  chartStatesFamily,
  initialChartState
} from '@/insights/jotai/charts.atoms'
import { cn, omitNullishOrEmptyValues } from '@/utils'

import {
  VisualizationType,
  visualizationAtomFamily
} from '../../store/report.molecule'
import { Visualization } from '../../types'

const { Title } = Typography

type EditableReportChartProps = ComponentProps<
  typeof InsightsChartStateless
> & {
  showEditControls?: boolean
}

export const EditableReportChart = (props: EditableReportChartProps) => {
  const { chartId = '', showEditControls = true, config: storedConfig } = props

  const chartState = useAtomValue(chartStatesFamily({ id: chartId }))
  const [visualization, setVisualization] = useAtom(
    visualizationAtomFamily({ id: chartId })
  )

  // When we first load the chart, we don't want to trigger the onConfigStateChange
  // because it will start off being the same as the initial state, and lead to
  // unexpected behavior
  useEffect(() => {
    const isInitialState = isEqual(
      { ...initialChartState, id: chartId },
      chartState
    )

    if (isInitialState) return

    const mappedReportChartConfig = omitNullishOrEmptyValues(
      mapChartStateToChartTemplate(chartState)
    )

    if (!isEqual(mappedReportChartConfig, storedConfig)) {
      setVisualization(prev => {
        const next: Visualization = {
          ...prev,
          config: mappedReportChartConfig,
          type: VisualizationType.Chart
        }
        return isEqual(prev, next) ? prev : next
      })
    }
  }, [chartState, chartId, setVisualization, storedConfig])

  return (
    <div className='relative min-h-10'>
      <div className={cn({ 'absolute top-0 left-0 z-10': showEditControls })}>
        <Title
          className='[&>button]:text-sm flex flex-row items-baseline gap-x-1 !mb-0'
          editable={
            showEditControls
              ? {
                  onChange: (newTitle: string) => {
                    if (newTitle === '') return
                    setVisualization(prev => ({ ...prev, title: newTitle }))
                  }
                }
              : false
          }
          level={5}
        >
          {visualization.title}
        </Title>
      </div>
      <div className='py-2'>
        <InsightsChartStateless
          {...omit(props, ['title'])}
          showFilterControls={showEditControls}
        />
      </div>
    </div>
  )
}
