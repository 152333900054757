import { Spin } from 'antd'
import { useEffect, useState } from 'react'

import { useTranslator } from '../i18n'

const MESSAGES_KEYS = [
  'thinkingMessages.thinking',
  'thinkingMessages.meditating',
  'thinkingMessages.pondering',
  'thinkingMessages.stillInDeepThought'
]

export const OhmThinking = () => {
  const [message, setMessage] = useState(MESSAGES_KEYS[0])

  const { translate } = useTranslator()

  useEffect(() => {
    const timers = MESSAGES_KEYS.slice(1).map((_, index) =>
      setTimeout(() => setMessage(MESSAGES_KEYS[index + 1]), (index + 1) * 7500)
    )

    return () => timers.forEach(timer => clearTimeout(timer))
  }, [])

  return (
    <div className='flex items-center gap-x-2 !text-gray-500'>
      <Spin />
      <pre>{translate(message)}</pre>
    </div>
  )
}
