import { PropsWithChildren } from 'react'

import { use_menu_nav } from './Menu_Nav_Context'

export function Menu_Panel(props: PropsWithChildren<{ panel_id: string }>) {
  const { children, panel_id, ...rest } = props
  const { selected_panel_id } = use_menu_nav()

  return (
    <div
      aria-labelledby={`simple-tab-${panel_id}`}
      hidden={selected_panel_id !== panel_id}
      id={`simple-tabpanel-${panel_id}`}
      role='tabpanel'
      {...rest}
    >
      {selected_panel_id === panel_id && children}
    </div>
  )
}
