import { Contents } from '@jupyterlab/services'
import { nanoid } from 'nanoid'
import { RefObject, useCallback, useMemo } from 'react'

import { useSession } from '@/hooks'

import { JupyterBridgeId, JupyterBridgeMessageType } from '../constants'

function postBridgeMessage<T>(
  iframeRef: React.RefObject<HTMLIFrameElement>,
  type: JupyterBridgeMessageType,
  payload?: T,
  operationId?: string,
  kernelId?: string
) {
  iframeRef?.current?.contentWindow?.postMessage(
    {
      kernelId,
      operationId,
      payload,
      source: JupyterBridgeId,
      type
    },
    '*'
  )
}

export const useMessageBridge = (
  iframeRef: RefObject<HTMLIFrameElement>,
  kernelId: string = nanoid()
) => {
  const sessionInformation = useSession()
  const { workspaceKey } = sessionInformation

  const postIdentify = useCallback(() => {
    // Can't send a function over postmessage
    const { updateSessionInfo, ...rest } = sessionInformation
    postBridgeMessage(iframeRef, JupyterBridgeMessageType.Identify, rest)
  }, [iframeRef, sessionInformation])

  const postAck = useCallback(() => {
    postBridgeMessage(iframeRef, JupyterBridgeMessageType.Ack)
  }, [iframeRef])

  const postExecuteTransientCode = useCallback(
    (code: string, operationId: string) => {
      postBridgeMessage(
        iframeRef,
        JupyterBridgeMessageType.ExecuteTransientCode,
        { code, kernelId, operationId }
      )
    },
    [iframeRef, kernelId]
  )

  const postSaveNotebookToPath = useCallback(
    (notebook: Contents.IModel) => {
      postBridgeMessage(
        iframeRef,
        JupyterBridgeMessageType.SaveNotebookToPath,
        notebook
      )
    },
    [iframeRef]
  )

  const postListNotebooksInPath = useCallback(
    (path: string) => {
      postBridgeMessage(
        iframeRef,
        JupyterBridgeMessageType.ListNotebooksInPath,
        { path, workspaceKey }
      )
    },
    [iframeRef, workspaceKey]
  )

  const toReturn = useMemo(
    () => ({
      postAck,
      postExecuteTransientCode,
      postIdentify,
      postListNotebooksInPath,
      postSaveNotebookToPath
    }),
    [
      postAck,
      postExecuteTransientCode,
      postIdentify,
      postListNotebooksInPath,
      postSaveNotebookToPath
    ]
  )

  return toReturn
}
