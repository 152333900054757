import saveAs from 'file-saver'
import { isString } from 'lodash-es'

import { useTranslator } from '../i18n'
import { OhmOutputContainer } from './OhmOutputContainer'

type OhmImageProps = {
  imgData: any
  alt?: string
  descriptor: string
  fileName: string
  format: string
}
export const OhmImage = (props: OhmImageProps) => {
  const { alt, imgData, descriptor, fileName, format } = props

  const { translate } = useTranslator()

  let safeImageData = imgData
  // If the image data is not a string, we need to convert it to a base64 string
  // as it is likely a PIL Image object, which returns as an object
  // (keys are array indices, values are pixel values )
  if (!isString(imgData)) {
    safeImageData = btoa(
      String.fromCharCode.apply(null, Object.values(imgData) as number[])
    )
  }

  const imgDataUri = `data:image/${format};base64,${safeImageData}`
  const sanitizedFileName =
    fileName ?? `${descriptor.replace(/[^a-zA-Z0-9 ]/g, '')}.${format}`

  const downloadOutput = () => saveAs(imgDataUri, sanitizedFileName)

  return (
    <OhmOutputContainer
      modalProps={{
        children: <img alt={alt ?? descriptor} src={imgDataUri} />,
        classNames: {
          content: 'max-w-[90vw] max-h-[90vh]',
          wrapper:
            '[&_.ant-modal]:max-w-[90vw] [&_.ant-modal]:max-h-[90vh] [&_.ant-modal]:!h-fit [&_.ant-modal]:!w-fit'
        },
        title: translate('plot')
      }}
      onDownload={downloadOutput}
    >
      <img alt={alt ?? descriptor} className='max-h-[250px]' src={imgDataUri} />
    </OhmOutputContainer>
  )
}
