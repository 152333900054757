import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

/**
 * A hook to get a logger that can be toggled on and off with a query parameter.
 */
const useLogger = () => {
  const [searchParams] = useSearchParams()

  const debug =
    searchParams.get('debug') === 'true' || searchParams.get('debug') === 'ui'
  const verbose =
    searchParams.get('verbose') === 'true' ||
    searchParams.get('verbose') === 'ui'

  const logDebug = useCallback(
    (message?: any, ...optionalParams: any[]) => {
      if (!debug) return

      console.log(
        `%c[DEBUG]%c: ${message}`,
        'background: lightyellow; color: black;',
        '',
        ...optionalParams
      )
    },
    [debug]
  )

  const logVerbose = useCallback(
    (message?: any, ...optionalParams: any[]) => {
      if (!verbose) return
      console.log(
        `%c[VERBOSE]%c: ${message}`,
        'background: lightblue; color: black;',
        '',
        ...optionalParams
      )
    },
    [verbose]
  )

  return useMemo(
    () => ({
      ...console,
      debug: logDebug,
      verbose: logVerbose
    }),
    [logDebug, logVerbose]
  )
}

export default useLogger
