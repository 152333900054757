import { Search } from '@carbon/icons-react'
import { InputRef, type ListProps, Spin } from 'antd'
import Fuse, { IFuseOptions } from 'fuse.js'
import { type ListIteratee, Many } from 'lodash'
import { sortBy } from 'lodash-es'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { List, Modal } from './FrameworkWrapper'
import { DebouncedInput } from './Inputs/DebouncedInput'

type SpotlightSearchProps<T> = {
  searchItems?: T[]
  searchOptions?: IFuseOptions<T>
  onSelectItem?: (item: T) => void
  listProps?: ListProps<T>
  resultsSorter?: Many<ListIteratee<T>>
  prompt?: ReactNode
  isOpen?: boolean
  onOpenChange?: (open: boolean) => void
  disabled?: boolean
  loading?: boolean
  inputPlaceholder?: string
}

export const SpotlightSearch = <T,>(props: SpotlightSearchProps<T>) => {
  const {
    searchItems = [],
    searchOptions = {},
    listProps = {},
    resultsSorter,
    prompt,
    isOpen,
    onOpenChange,
    onSelectItem,
    disabled = false,
    loading,
    inputPlaceholder
  } = props
  const inputRef = useRef<InputRef>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const [isModalOpen, setIsModalOpen] = useState(isOpen || false)
  const [searchResults, setSearchResults] = useState(searchItems)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const fuse = useMemo(() => {
    return new Fuse(searchItems, searchOptions)
  }, [searchItems, searchOptions])

  const showModal = () => {
    setIsModalOpen(true)
    onOpenChange?.(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    onOpenChange?.(false)
  }

  // Update search results when debounced search value changes
  useEffect(() => {
    let results = []
    if (searchTerm) {
      const matches = fuse.search(searchTerm).map(result => result.item)
      results = matches
    } else {
      results = searchItems
    }

    results = resultsSorter ? sortBy(results, resultsSorter) : results

    setSearchResults(results)
    setSelectedIndex(-1) // Reset selected index when search results change
  }, [searchTerm, fuse, resultsSorter, searchItems])

  useEffect(() => {
    setIsModalOpen(isOpen || false)
  }, [isOpen])

  useHotkeys('meta+k, ctrl+k', showModal, {
    preventDefault: true,
    enableOnFormTags: true,
    enabled: !disabled
  })

  /**
   * Handles keyboard navigation for the spotlight search component.
   *
   * @param {React.KeyboardEvent} event - The keyboard event triggered by user interaction.
   *
   * - ArrowUp or Shift+Tab: Moves the selection up in the search results.
   * - ArrowDown or Tab: Moves the selection down in the search results.
   * - Enter: Selects the currently highlighted search result.
   *
   * The function prevents the default behavior of the event when navigating through the search results.
   */
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowUp' || (event.shiftKey && event.key === 'Tab')) {
      setSelectedIndex(prevIndex => Math.max(prevIndex - 1, -1))
      event.preventDefault()
    } else if (event.key === 'ArrowDown' || event.key === 'Tab') {
      setSelectedIndex(prevIndex =>
        Math.min(prevIndex + 1, searchResults.length - 1)
      )
      event.preventDefault()
    } else if (event.key === 'Enter' && selectedIndex >= 0) {
      onSelectItem?.(searchResults[selectedIndex])
    }
  }

  useEffect(() => {
    if (selectedIndex === -1) {
      inputRef.current?.focus()
      return
    }
    const selectedElement = listRef.current?.querySelectorAll('li > button')?.[
      selectedIndex ?? 0
    ] as HTMLButtonElement

    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
      selectedElement.focus()
    }
  }, [selectedIndex])

  return (
    <>
      {prompt}
      <Modal
        afterOpenChange={
          // Focus input when modal opens
          open => open && inputRef.current?.focus()
        }
        classNames={{
          header: '!mb-0',
          content: '!p-0 max-h-[80vh] flex flex-col',
          body: 'flex-1 overflow-y-auto scrollbar-thumb-rounded-md scrollbar-thin scrollbar-thumb-gray-400'
        }}
        closable={false}
        footer={null}
        onCancel={handleCancel}
        open={isModalOpen}
        style={{ minHeight: '33vh' }}
        title={
          <DebouncedInput
            ref={inputRef}
            className='border-t-none rounded-b-none border-x-0'
            onChange={setSearchTerm}
            onFocus={() => setSelectedIndex(-1)}
            onKeyDown={handleKeyDown}
            placeholder={inputPlaceholder}
            prefix={<Search className='mr-1 text-gray-400' />}
            size='large'
          />
        }
        zIndex={1300}
      >
        {loading ? (
          <div className='flex h-64 items-center justify-center'>
            <Spin />
          </div>
        ) : (
          <div className='flex-1 overflow-auto' onKeyDown={handleKeyDown}>
            <List
              ref={listRef}
              bordered
              className='overflow-y-auto'
              dataSource={searchResults}
              {...listProps}
            />
          </div>
        )}
      </Modal>
    </>
  )
}
