const messages = {
  common: {
    added: 'Added',
    changes: 'Changes',
    dateCreated: 'Date Created',
    description: 'Description',
    diff: 'Diff',
    download: 'Download',
    error: 'Error',
    expand: 'Expand',
    fileName: 'File Name',
    lastModified: 'Last Modified',
    modified: 'Modified',
    note: 'Note',
    removed: 'Removed',
    size: 'Size',
    title: 'Title',
    xBytes_one: '{{count}} byte',
    xBytes_other: '{{count}} bytes',
    xRowsPerPage: '{{count}} rows per page'
  }
}

export default messages
