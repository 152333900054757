import { Draggable, View, ViewOff } from '@carbon/icons-react'
import { forwardRef } from 'react'

import { Button, Tag, Tooltip } from '@/components'

type DatasetColumnProps = {
  visible: boolean
  label: string
  units: string | null | undefined
  isGroupKey: boolean
  onToggleVisibility: () => void
}
export const DatasetColumnItem = forwardRef<HTMLDivElement, DatasetColumnProps>(
  (props, ref) => {
    const { visible, label, units, isGroupKey, onToggleVisibility } = props
    return (
      <div
        ref={ref}
        className='flex select-none items-center gap-x-2 overflow-hidden'
      >
        {visible && (
          <div className='flex items-center'>
            <Draggable className='self-center text-gray-500' />
          </div>
        )}
        <Tag className='mr-0 flex flex-1 items-baseline justify-between self-start overflow-hidden bg-white py-1'>
          <div className='max-w-64 overflow-hidden text-ellipsis text-xs font-medium'>
            {label}
          </div>
          {units != null && (
            <Tooltip title={`Unit: ${units}`}>
              <div className='font-mono text-xs font-medium'>({units})</div>
            </Tooltip>
          )}
        </Tag>
        <Button
          className='h-auto self-center p-1 text-gray-400'
          disabled={visible && isGroupKey} // Disable visibility toggle if column is the group by property
          onClick={onToggleVisibility}
          type='text'
        >
          {visible ? <ViewOff /> : <View />}
        </Button>
      </div>
    )
  }
)
DatasetColumnItem.displayName = 'DatasetColumnItem'
