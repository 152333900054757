import { Information } from '@carbon/icons-react'
import { ICell } from '@jupyterlab/nbformat'
import { ReactNode } from 'react'

import { Popover } from '@/components'

import { DatasetTag } from '../components/DatasetTag'
import { useTranslator } from '../i18n'
import { OhmAiMessageType, OhmUserInteractionMessage } from '../types'
import { CodeFoundry } from '../utils/codeFoundry'

type WithDatasetsProps = {
  datasetIds: string[]
}
function SelectingDatasets({ datasetIds }: WithDatasetsProps) {
  const { translate } = useTranslator()
  return (
    <div>
      {translate('selectingDatasets', { count: datasetIds.length })}:
      <div className='flex flex-wrap items-center gap-0.5'>
        {datasetIds.map(id => (
          <DatasetTag key={id} text={id} />
        ))}
      </div>
    </div>
  )
}

function DatasetsSelected({ datasetIds }: WithDatasetsProps) {
  const { translate } = useTranslator()
  const datasetCount = datasetIds.length

  return (
    <div className='flex flex-1 select-none items-center justify-between gap-x-4'>
      {translate('nDatasetsSelected', { count: datasetCount })}
      {
        <Popover
          arrow={{
            pointAtCenter: true
          }}
          classNames={{
            root: 'max-w-[30vw]'
          }}
          placement='topRight'
          title={
            <div className='flex flex-col gap-1'>
              {datasetCount === 0 ? (
                <div className='text-sm font-normal italic'>
                  {translate('noDatasetsSelected')}
                </div>
              ) : (
                <>
                  <div className='text-sm font-medium'>
                    {translate('selectedNDatasets', { count: datasetCount })}
                  </div>
                  <div className='flex flex-wrap items-center gap-0.5 font-normal'>
                    {datasetIds.map(id => (
                      <DatasetTag key={id} text={id} />
                    ))}
                  </div>
                </>
              )}
            </div>
          }
        >
          <Information className='cursor-pointer' size={14} />
        </Popover>
      }
    </div>
  )
}

export function useMessageFoundry() {
  const { translate } = useTranslator()

  function makeSelectingNotebookMessage(fileName: string) {
    const safeFilename = fileName
      .replace('.shared.ipynb', '')
      .replace('.ipynb', '')
    return translate('selectingNotebookX', { x: safeFilename })
  }
  function makeSelectingDatasetsMessage(datasetIds: string[]) {
    const datasetIdStr = datasetIds.join(', ')
    return translate('selectingDatasetsX', {
      count: datasetIds.length,
      x: datasetIdStr
    })
  }

  const makeSelectDatasetMessage = ({
    datasetIds,
    promptId,
    content
  }: {
    datasetIds: string[]
    promptId: string
    content?: ReactNode
  }) => {
    return {
      cells: [
        {
          cell_type: 'code',
          metadata: {},
          source: CodeFoundry.makeSelectDatasets(datasetIds)
        }
      ],
      content: content ?? <SelectingDatasets datasetIds={datasetIds} />,
      feedbackMessage: <DatasetsSelected datasetIds={datasetIds} />,
      internal: true,
      promptId,
      title: translate('datasetsChanged'),
      toString() {
        return makeSelectingDatasetsMessage(datasetIds)
      },
      type: OhmAiMessageType.UserMessage as const
    }
  }

  const makeSelectNotebookMessage = ({
    fileName,
    promptId,
    content,
    cells
  }: {
    fileName: string
    promptId: string
    cells: ICell[]
    content?: ReactNode
  }): OhmUserInteractionMessage => {
    return {
      cells,
      content: content ?? makeSelectingNotebookMessage(fileName),
      feedbackMessage: translate('xSelected', { x: fileName }),
      internal: true,
      promptId,
      title: translate('notebookSelected'),
      toString() {
        return makeSelectingNotebookMessage(fileName)
      },
      type: OhmAiMessageType.UserMessage as const
    }
  }

  return {
    makeSelectDatasetMessage,
    makeSelectNotebookMessage
  }
}
