import { Grid2, styled } from '@mui/material'

import { Search_Input } from '@/components/Search_Input/Search_Input'
import { Insights_Datasets_Table_Filter_Panel } from '@/insights/tables/Insights_Datasets_Table_Filter_Panel'

type DatasetTableHeaderProps = {
  queryString?: string
  rowCount?: number
  selectedRowCount?: number
  loading?: boolean
  onSearch: (new_value: string) => void
}
export function DatasetTableHeader(props: DatasetTableHeaderProps) {
  const { onSearch, queryString = '' } = props

  return (
    <>
      <Grid2 container className='mb-2'>
        <Grid2 size={{ sm: 3, xs: 12 }}>
          <Search_Input default_value={queryString} on_change={onSearch} />
        </Grid2>
        <Filter_Panel_Wrapper size={{ sm: 'grow', xs: 12 }}>
          <Insights_Datasets_Table_Filter_Panel />
        </Filter_Panel_Wrapper>
      </Grid2>
    </>
  )
}

const Filter_Panel_Wrapper = styled(Grid2)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingBottom: 0,
  paddingTop: 0,

  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0
  }
}))
