import { styled } from '@mui/material'
import { GridFilterPanel } from '@mui/x-data-grid'

export const Insights_Datasets_Table_Filter_Panel = styled(props => (
  <GridFilterPanel
    {...props}
    disableRemoveAllButton
    filterFormProps={{
      valueInputProps: {
        InputComponentProps: {
          variant: 'filled',
          label: undefined,
          placeholder: 'Value'
        }
      }
    }}
  />
))(({ theme }) => {
  const commonInputBaseStyles = {
    borderRadius: 0,
    borderColor: theme.palette.divider
  }

  return {
    '.MuiFilledInput-input': {
      fontSize: 11
    },
    '.MuiDataGrid-panelContent': {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    '.MuiDataGrid-panelFooter': {
      display: 'inline-flex',
      alignSelf: 'flex-start',
      padding: 0,
      margin: 0,
      '.MuiButton-root': {
        border: `1px solid ${theme.palette.divider}`,
        color: theme.palette.gray[500],
        padding: theme.spacing(0.75, 1.5),
        fontSize: 11
      }
    },
    '.MuiDataGrid-filterForm': {
      alignItems: 'flex-start',
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      '&:first-of-type': {
        '.MuiDataGrid-filterFormLogicOperatorInput': {
          display: 'none'
        },
        '.MuiDataGrid-filterFormColumnInput': {
          '.MuiInputBase-root': {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius
          }
        }
      },
      '&:not(:first-of-type)': {
        '.MuiDataGrid-filterFormColumnInput': {
          '.MuiInputBase-root': commonInputBaseStyles
        }
      }
    },
    '.MuiFormControl-root': {
      '&:not(.MuiDataGrid-filterFormLogicOperatorInput)': {
        '.MuiInputBase-root': commonInputBaseStyles
      },
      // '&.MuiDataGrid-filterFormOperatorInput': {
      //   width: 90,
      //   '.MuiInputBase-root': {
      //     borderRight: 0,
      //     minWidth: 90,
      //     width: 90
      //   }
      // },
      '&.MuiDataGrid-filterFormColumnInput': {
        // width: 120,
        '.MuiInputBase-root': {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderRight: 0
        }
      },
      '&.MuiDataGrid-filterFormLogicOperatorInput': {
        margin: 0,
        '.MuiInputBase-root': {
          minWidth: 70,
          width: 70,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRight: 0
        }
      },
      '&.MuiDataGrid-filterFormValueInput': {
        width: 150,
        ':empty': {
          display: 'none'
        },
        '.MuiFormLabel-root': {
          display: 'none'
        },
        '.MuiInputBase-root': {
          borderBlock: `1px solid ${theme.palette.divider}`,
          borderRight: 0,
          color: theme.palette.text.primary,
          '.MuiFilledInput-input': {
            height: 'auto'
          }
        },
        '.MuiAutocomplete-inputRoot': {
          '.MuiAutocomplete-tag': {
            marginBlock: 0,
            marginLeft: 0
          }
        }
      },
      '&.MuiDataGrid-filterFormDeleteIcon': {
        alignItems: 'stretch',
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        border: `1px solid ${theme.palette.divider}`,
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        order: 99,
        '.MuiIconButton-root': {
          color: theme.palette.gray[400],
          borderRadius: 5,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      '.MuiDataGrid-panelContent': {
        marginTop: theme.spacing(1),
        marginLeft: 0,
        paddingLeft: 0
      },
      '.MuiDataGrid-panelFooter': {
        marginLeft: 0,
        padding: 0
      }
    }
  }
})
