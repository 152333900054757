import numbro from 'numbro'

import { CHART_SIG_FIGS } from '../../charts'
import {
  Absolute_Time_Byterat_Property,
  Any_Time_Byterat_Property,
  Byterat_Property,
  Non_Absolute_Time_Byterat_Property
} from '../../charts/models/byterat_properties.model'
import { format_axis_tick } from '../../charts/utils'

/**
 * Configuration object for formatting axis labels in a chart.
 *
 * @property {boolean} hideOverlappingLabels - Determines whether overlapping labels should be hidden.
 * @property {boolean} showDuplicates - Determines whether duplicate labels should be shown. This is false if the type is not 'category' and the propertyKey is included in Any_Time_Byterat_Property.
 * @property {number} rotate - The rotation angle for the labels.
 * @property {Function | undefined} formatter - A function to format the axis labels based on the type and propertyKey.
 *
 * The formatter function is determined by the following conditions:
 * - If the type is 'category' or the propertyKey is included in Absolute_Time_Byterat_Property, the formatter is undefined.
 * - If the propertyKey is included in Non_Absolute_Time_Byterat_Property, the formatter converts the value to hours.
 * - Otherwise, the formatter handles small values and exponential notation:
 *   - If the absolute value of the number is less than a very small threshold, it returns '0'.
 *   - If the value is already shown in exponential form or is smaller than a threshold, it formats the value in exponential notation.
 *   - If the value is NaN, it returns 'NaN'.
 *   - Otherwise, it formats the number with thousand separators and a specified number of significant figures.
 */
export const _getAxisLabelFormatting = (
  propertyKey: Byterat_Property,
  type = 'numeric'
) => {
  let hasAlreadyShownExponential = false
  let lastExponentialShown = 0
  const format = {
    showDuplicates:
      type !== 'category' && Any_Time_Byterat_Property.includes(propertyKey),
    rotate: 0,
    formatter:
      type === 'category' ||
      Absolute_Time_Byterat_Property.includes(propertyKey)
        ? undefined
        : Non_Absolute_Time_Byterat_Property.includes(propertyKey)
          ? function (val: number) {
              return format_axis_tick(val / (1000 * 60 * 60))
            }
          : function (val: number) {
              const threshold = 1 / Math.pow(10, CHART_SIG_FIGS)
              const smallestNumberToFormat = Math.pow(10, -12)

              if (isNaN(val)) {
                return 'NaN'
              } else if (Math.abs(val) < smallestNumberToFormat) {
                return '0'
              } else if (
                hasAlreadyShownExponential ||
                (Math.abs(val) < threshold && val !== 0)
              ) {
                const exponentialValue = val.toExponential().split('e')
                let coefficient = parseFloat(exponentialValue[0])
                let exponent = parseInt(exponentialValue[1])

                if (hasAlreadyShownExponential) {
                  // If we've already shown an exponential and this exponent is not 0,
                  // we want to keep the exponent the same and multiply coefficient
                  // by 10^(exponent - lastExponentialShown)
                  if (exponent === 0) {
                    return parseFloat(coefficient.toFixed(CHART_SIG_FIGS))
                  } else if (Math.abs(val) > threshold) {
                    return val.toFixed(CHART_SIG_FIGS)
                  } else {
                    coefficient *= Math.pow(10, exponent - lastExponentialShown)
                    // Update the exponent to show for this current value. We must do this after
                    // we've multiplied the coefficient by the difference in exponents, otherwise
                    // we'll be multiplying by 1 every time.
                    exponent = lastExponentialShown
                  }
                } else {
                  // If this is the first time we're showing an exponential, we want to show the
                  // exponent as-is, but we need to remember that we've shown an exponential
                  hasAlreadyShownExponential = true
                  // Save the exponent shown for this value
                  lastExponentialShown = exponent
                }

                // Format the coefficient to have the specified number of significant figures and
                // return the coefficient and exponent in exponential notation (basically
                // rebuilding the original value)
                return `${parseFloat(
                  coefficient.toFixed(CHART_SIG_FIGS)
                )}e${exponent}`
              } else {
                return numbro(val).format({
                  thousandSeparated: true,
                  mantissa: CHART_SIG_FIGS,
                  trimMantissa: true
                })
              }
            }
  }

  return format
}
