import { ChevronDown, ColorPalette } from '@carbon/icons-react'
import { Box, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material'
import { useAtom } from 'jotai'

import { chartStatesFamily } from '@/insights/jotai/charts.atoms'

import { CHART_TYPE } from '../../charts/chart_options'

const OMITTED_CHART_TYPES = [CHART_TYPE.SCATTER]
const VALID_CHART_TYPES = Object.entries(CHART_TYPE).filter(
  ([, chart_type]) => !OMITTED_CHART_TYPES.includes(chart_type)
)
const CHART_TYPE_NAME_MAP: Partial<Record<CHART_TYPE, string>> = {
  [CHART_TYPE.LINE]: 'Line',
  [CHART_TYPE.LINE_MARKER]: 'Scatterplot'
}

export function ChartTypeSelect({ chartId }: { chartId: string }) {
  // Jotai chart state
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: chartId })
  )
  const { chartType } = chartState

  return (
    <Select
      IconComponent={ChevronDown}
      MenuProps={{
        slotProps: { paper: { elevation: 4 } }
      }}
      onChange={(e: SelectChangeEvent) => {
        setChartState({
          ...chartState,
          chartType: e.target.value as CHART_TYPE
        })
      }}
      renderValue={value => {
        return (
          <Select_Value>
            <ColorPalette height={18} width={18} />
            {CHART_TYPE_NAME_MAP[value as CHART_TYPE]}
          </Select_Value>
        )
      }}
      sx={{
        border: 0,
        boxShadow: 'none',
        width: '100%',
        padding: 0,
        '& .MuiSelect-icon': {
          top: 'auto',
          color: theme => theme.palette.gray['500'],
          width: '1rem',
          height: '1rem'
        },
        '& .MuiSelect-select': {
          paddingInline: theme => theme.spacing(1),
          '&:hover': {
            backgroundColor: theme => theme.palette.grey[100]
          }
        }
      }}
      value={chartType}
    >
      {VALID_CHART_TYPES.map(([key, value]) => (
        <Menu_Item_Label key={key} selected={chartType === value} value={value}>
          {CHART_TYPE_NAME_MAP[value]}
        </Menu_Item_Label>
      ))}
    </Select>
  )
}

const Menu_Item_Label = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.sm,
  paddingBlock: theme.spacing(1.25),
  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[100],
    '&:hover,&:focus': {
      backgroundColor: theme.palette.grey[200]
    }
  }
}))

const Select_Value = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
  flex: 1,
  fontSize: theme.typography.sm
}))
