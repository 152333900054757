import { Add, TrashCan, YAxis } from '@carbon/icons-react'
import { AutoTextSize } from 'auto-text-size'
import { useAtomValue } from 'jotai'
import { cloneDeep, startCase } from 'lodash-es'
import { FocusEvent, Fragment, useMemo } from 'react'

import { Button, DebouncedInput, OptGroup, Select } from '@/components'
import { RangeValue, emptyArray } from '@/constants'
import {
  Filter_Option,
  Normalize_Type,
  Property_Option
} from '@/gql_generated/graphql'
import { makeAxisTitle } from '@/insights/charts/transform'
import { format_property_label } from '@/insights/charts/utils'
import Numeric_Filter from '@/insights/controls/filters/Numeric_Filter'
import { NormalizeByOption } from '@/insights/jotai/charts.atoms'
import {
  VisualizationType,
  visualizationAtomFamily
} from '@/insights/reports/store/report.molecule'
import { mapListKeysToValues } from '@/utils'

import { MenuHeader, MenuItemControl } from '../../../controls'
import { useVisualizationItem } from '../../VisualizationContext'

type PanelYAxisProps = {
  propertyOptions: (Property_Option | Filter_Option)[]
  normalizeOptions?: NormalizeByOption[]
  loading?: boolean
}

const MAX_Y_AXIS_METRICS = 2

/**
 * PanelYAxis component renders the Y-Axis configuration panel for a chart visualization.
 *
 * @param {PanelYAxisProps} props - The properties for the PanelYAxis component.
 */
export function PanelYAxis(props: PanelYAxisProps) {
  const { propertyOptions, normalizeOptions = [], loading = true } = props
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()

  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { config, type } = visualization

  const selectablePropertyOptions = useMemo(
    () => mapListKeysToValues(propertyOptions),
    [propertyOptions]
  )

  const groupedPropertyOptions = useMemo(() => {
    const options: OptGroup[] = []
    return selectablePropertyOptions.reduce((acc, option) => {
      const sourceName = option.metric_source ?? 'Other'
      const group = acc.find(g => g.title === sourceName)

      if (group) {
        group.options.push(option)
      } else {
        acc.push({
          label: (
            <AutoTextSize
              className='font-semibold text-gray-500 self-center'
              maxFontSizePx={14}
              minFontSizePx={11}
            >
              {sourceName}
            </AutoTextSize>
          ),
          className: '!sticky -top-1 !bg-white !rounded-none',
          title: sourceName,
          options: [option]
        })
      }

      return acc.sort((a, b) => {
        if (a.title === b.title) {
          return a.title.localeCompare(b.title)
        }
        return a.title?.localeCompare(b.title ?? '') ?? 0
      })
    }, options)
  }, [selectablePropertyOptions])

  const selectableNormalizeOptions = useMemo(
    () => mapListKeysToValues(normalizeOptions),
    [normalizeOptions]
  )

  if (type !== VisualizationType.Chart) return null

  const {
    yAxisProperties = emptyArray,
    yPropertyRanges = emptyArray,
    normalizeByProperties
  } = config

  /**
   * Creates a handler for changing the metric of a Y-Axis.
   *
   * @param {number} index - The index of the Y-Axis.
   */
  const makeOnMetricChange = (index: number) => (newMetric: string) => {
    if (selectablePropertyOptions == null) return
    const newYAxisProperties = [...yAxisProperties]
    const newYMetric = selectablePropertyOptions.find(
      ({ key }) => key === newMetric
    )
    if (newYMetric == null) return

    newYAxisProperties[index] = {
      ...newYMetric,
      labelOverride: undefined
    }
    updateVisualizationConfig({ yAxisProperties: newYAxisProperties })
  }

  /**
   * Creates a handler for changing the normalization metric of a Y-Axis.
   *
   * @param {number} index - The index of the Y-Axis.
   */
  const makeOnNormalizeByChange =
    (index: number) => (normalizeBy: Nullable<string>) => {
      const newYAxisProperties = [...yAxisProperties]
      const yProperty = newYAxisProperties[index]
      const { key } = yProperty
      const nextNormalizeByProperties = cloneDeep(normalizeByProperties)
      const selectedOption = normalizeOptions.find(
        ({ key }) => key === normalizeBy
      )

      newYAxisProperties[index] = {
        ...yProperty,
        labelOverride: undefined
      }

      if (normalizeBy != null) {
        nextNormalizeByProperties[key] = {
          ...selectedOption,
          key: normalizeBy,
          type: Normalize_Type.DatasetProperty,
          label: selectedOption?.label ?? startCase(normalizeBy)
        }
      } else {
        delete nextNormalizeByProperties[key]
      }

      updateVisualizationConfig({
        normalizeByProperties: nextNormalizeByProperties,
        yAxisProperties: newYAxisProperties
      })
    }

  const makeOnAxisLabelChange =
    (index: number) =>
    (newValue = '') => {
      const nextYAxisProperties = cloneDeep(yAxisProperties)
      if (newValue.trim().length > 0) {
        nextYAxisProperties[index].labelOverride = newValue
      } else {
        delete nextYAxisProperties[index].labelOverride
      }
      updateVisualizationConfig({
        yAxisProperties: nextYAxisProperties
      })
    }

  /**
   * Creates a handler for changing the range value of a Y-Axis.
   *
   * @param {number} index - The index of the Y-Axis.
   * @param {RangeValue} pos - The position (Min or Max) of the range value.
   */
  const makeOnRangeValueChange =
    (index: number, pos: RangeValue) =>
    (event: FocusEvent<HTMLInputElement, Element>) => {
      const value = parseFloat(event.target.value)
      const newYPropertyRanges = [...yPropertyRanges]
      newYPropertyRanges[index] = [
        pos === RangeValue.Min ? value : yPropertyRanges[index]?.[0],
        pos === RangeValue.Max ? value : yPropertyRanges[index]?.[1]
      ]
      updateVisualizationConfig({
        yPropertyRanges: newYPropertyRanges
      })
    }

  /**
   * Handler for adding a new Y-Axis.
   */
  const onAddAxis = () => {
    if (yAxisProperties.length >= MAX_Y_AXIS_METRICS) return
    updateVisualizationConfig({
      yAxisProperties: [...yAxisProperties, { key: '', label: '' }]
    })
  }

  /**
   * Creates a handler for removing a Y-Axis.
   *
   * @param {number} index - The index of the Y-Axis to remove.
   */
  const onRemoveAxis = (index: number) => () => {
    const newYAxisProperties = yAxisProperties.filter((_, idx) => idx !== index)
    updateVisualizationConfig({ yAxisProperties: newYAxisProperties })
  }

  return (
    <div>
      <MenuHeader
        title={
          <div className='flex flex-row gap-x-2 items-center'>
            <YAxis />
            Y-Axis
          </div>
        }
      />
      <div className='p-2'>
        <div className='flex flex-col gap-2 items-stretch'>
          {yAxisProperties.map((yAxis, index) => {
            const yAxisNormalization = normalizeByProperties?.[yAxis.key]
            return (
              <Fragment key={index}>
                <MenuItemControl
                  emphasize
                  inputId={`y-axis-type-${index}`}
                  label={`Y-Axis ${index + 1}`}
                  loading={loading}
                >
                  <Select
                    showSearch
                    allowClear={false}
                    aria-labelledby={`y-axis-type-${index}`}
                    className='flex-1'
                    id={`y-axis-type-${index}`}
                    onChange={makeOnMetricChange(index)}
                    options={groupedPropertyOptions}
                    placeholder='Add Metric'
                    value={{
                      ...yAxis,
                      label: format_property_label(yAxis.label, yAxis.units)
                    }}
                    virtual={false}
                  />
                </MenuItemControl>
                <MenuItemControl
                  inputId={`y-axis-normalize-by-${index}`}
                  label={`Normalize by`}
                  loading={loading}
                >
                  <Select
                    allowClear
                    showSearch
                    aria-labelledby={`y-axis-normalize-by-${index}`}
                    className='flex-1'
                    id={`y-axis-normalize-by-${index}`}
                    onChange={makeOnNormalizeByChange(index)}
                    options={selectableNormalizeOptions}
                    placeholder='Add Metric'
                    value={
                      yAxisNormalization
                        ? format_property_label(
                            yAxisNormalization.label,
                            yAxisNormalization.units
                          )
                        : undefined
                    }
                  />
                </MenuItemControl>
                <MenuItemControl inputId='x-axis-label' label='Label Override'>
                  <DebouncedInput
                    allowClear
                    className='text-xs h-8'
                    id='x-axis-label'
                    onChange={makeOnAxisLabelChange(index)}
                    placeholder={makeAxisTitle(yAxis, normalizeByProperties)}
                    value={yAxis.labelOverride ?? ''}
                  />
                </MenuItemControl>
                <MenuItemControl
                  inputId='x-axis-limits'
                  label='Limits (Min - Max)'
                >
                  <Numeric_Filter
                    onBlur={makeOnRangeValueChange(index, RangeValue.Min)}
                    placeholder='Min'
                    sx={{ '.MuiInputBase-input': { fontSize: '0.75rem' } }}
                    value={yPropertyRanges[index]?.[0] ?? undefined}
                  />
                  <Numeric_Filter
                    onBlur={makeOnRangeValueChange(index, RangeValue.Max)}
                    placeholder='Max'
                    sx={{ '.MuiInputBase-input': { fontSize: '0.75rem' } }}
                    value={yPropertyRanges[index]?.[1] ?? undefined}
                  />
                </MenuItemControl>
                {yAxisProperties.length > 1 && (
                  <Button
                    className='text-xs font-semibold self-end px-0.5 gap-x-1 text-gray-500'
                    onClick={onRemoveAxis(index)}
                    size='small'
                    type='text'
                  >
                    <TrashCan size={14} />
                    Remove Y-Axis {index + 1}
                  </Button>
                )}
              </Fragment>
            )
          })}
          {yAxisProperties.length < MAX_Y_AXIS_METRICS && (
            <Button
              className='text-xs font-semibold self-start px-0.5'
              color='primary'
              onClick={onAddAxis}
              size='small'
              variant='text'
            >
              <Add size={16} />
              Add Y-Axis
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
