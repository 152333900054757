const ALLOWLISTED_BYTERAT_EMAILS = [
  'penelope@byterat.io',
  'penelope.jones@byterat.io',
  'paul.sinclair@byterat.io'
]

// Blocklist byterat development accounts from session replay
export const isSessionReplayEnabledForEmail = (email: string) => {
  return (
    !email.endsWith('byterat.io') || ALLOWLISTED_BYTERAT_EMAILS.includes(email)
  )
}
