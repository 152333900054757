import { FormLabel, Grid2, Grid2Props, styled } from '@mui/material'
import { LabelHTMLAttributes, PropsWithChildren } from 'react'

type Menu_Item_Input_Label_Props = PropsWithChildren<{
  emphasize?: boolean
  grid_item_size?: Grid2Props['size']
  htmlFor?: LabelHTMLAttributes<HTMLLabelElement>['htmlFor']
}>
export function Menu_Item_Input_Label(props: Menu_Item_Input_Label_Props) {
  const { htmlFor, children, emphasize, grid_item_size } = props
  return (
    <Grid2 size={grid_item_size}>
      <Styled_Input_Label emphasize={emphasize} htmlFor={htmlFor}>
        {children}
      </Styled_Input_Label>
    </Grid2>
  )
}

export const Styled_Input_Label = styled(FormLabel)<{ emphasize?: boolean }>(
  ({ theme, emphasize }) => ({
    color: theme.palette.text.primary,
    ...theme.typography.body2,
    fontWeight: emphasize
      ? theme.typography.fontWeightBold
      : theme.typography.fontWeightMedium,
    margin: 0
  })
)
