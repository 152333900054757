import { useTranslation } from 'react-i18next'

import { I18nNamespaces } from '@/i18n'

export function useTranslator() {
  const { t } = useTranslation(I18nNamespaces.OhmAi)

  return {
    t,
    translate: t
  }
}
