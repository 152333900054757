import { useState } from 'react'
import { Trans } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'

import { Button, notification } from '@/components'
import { I18nNamespaces } from '@/i18n'

import { useTranslator } from '../i18n'

export const OhmWelcome = () => {
  const [hideTips, setHideTips] = useLocalStorage('hideTips', false)
  const [notificationApi, notificationContextHolder] =
    notification.useNotification()
  // Store the initial value when component first mounts
  const [initialHideTips] = useState(hideTips)

  const { translate } = useTranslator()

  const onHideTips = () => {
    notificationApi.success({
      className: '!text-xs',
      message: (
        <span className='text-xs'>
          {translate('welcomeComponent.tipsHidden')}
        </span>
      )
    })
    setHideTips(true)
  }

  const renderTips = () => (
    <div className='space-y-4 text-xs text-gray-600 [&_li>ul]:mt-2'>
      <p className='text-sm'>{translate('welcomeComponent.intro')}</p>
      <ul className='list-outside list-disc space-y-2 [&>*]:ml-4'>
        <li>{translate('welcomeComponent.tips.tip1')}</li>
        <li>{translate('welcomeComponent.tips.tip2')}</li>
        <li>{translate('welcomeComponent.tips.tip3')}</li>
      </ul>
      <p>{translate('welcomeComponent.needIdeas')}</p>
      <ul className='list-outside list-disc space-y-2 [&>*]:ml-4'>
        <li>
          <span className='text-md'>
            {translate('welcomeComponent.ideas.selectDatasets.title')}
          </span>
          <ul className='list-outside list-disc space-y-2 [&>*]:ml-4'>
            <li>
              <span className='font-semibold'>
                {translate('welcomeComponent.ideas.obtainViz.title')}:
              </span>
              <ul className='list-outside list-disc space-y-2 [&>*]:ml-4'>
                <li>{translate('welcomeComponent.ideas.obtainViz.bullet1')}</li>
                <li>{translate('welcomeComponent.ideas.obtainViz.bullet2')}</li>
              </ul>
            </li>
            <li>
              <span className='font-semibold'>
                {translate('welcomeComponent.ideas.createCharts.title')}:
              </span>
              <ul className='list-outside list-disc space-y-2 [&>*]:ml-4'>
                <li>
                  {translate('welcomeComponent.ideas.createCharts.bullet1')}
                </li>
                <li>
                  {translate('welcomeComponent.ideas.createCharts.bullet2')}
                </li>
              </ul>
            </li>
            <li>
              <span className='font-semibold'>
                {translate('welcomeComponent.ideas.extractInsights.title')}:
              </span>
              <ul className='list-outside list-disc space-y-2 [&>*]:ml-4'>
                <li>
                  <Trans
                    components={{
                      code: <code />
                    }}
                    i18nKey='welcomeComponent.ideas.extractInsights.bullet1'
                    ns={I18nNamespaces.OhmAi}
                  />
                </li>
                <li>
                  {translate('welcomeComponent.ideas.extractInsights.bullet2')}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      {!hideTips && (
        <p className='mt-4 text-xs'>
          <Trans
            components={{
              btn: (
                <Button
                  className='m-0 p-0 text-xs'
                  disabled={hideTips}
                  onClick={onHideTips}
                  type='link'
                />
              )
            }}
            i18nKey='welcomeComponent.alreadyKnowYourWayAround'
            ns={I18nNamespaces.OhmAi}
          />
        </p>
      )}
      <p className='mt-0 text-lg'>
        {translate('welcomeComponent.happyExploring')}
      </p>
    </div>
  )

  return (
    <div className='space-y-4 text-xs text-gray-600 [&_li>ul]:mt-2'>
      <p className='text-lg font-semibold text-black'>
        {translate('welcomeComponent.title')}
      </p>
      {notificationContextHolder}
      {initialHideTips ? (
        <pre className='whitespace-pre-wrap font-sans'>
          {translate('welcomeComponent.helloAgain')}
        </pre>
      ) : (
        renderTips()
      )}
    </div>
  )
}
