import { ChevronDown, Export } from '@carbon/icons-react'
import { IconButton, Menu, MenuItem, styled, useTheme } from '@mui/material'
import { GridApiPro } from '@mui/x-data-grid-pro'
import { message } from 'antd'
import { useAtomValue } from 'jotai'
import { useRef, useState } from 'react'

import { visualizationAtomFamily } from '@/insights/reports/store/report.molecule'

const EXPORT_TYPES = ['none', 'csv']
const VALID_EXPORT_TYPES = EXPORT_TYPES.slice(1)

export function TableExportSelect(props: {
  tableId: string
  tableRef?: React.MutableRefObject<GridApiPro>
}) {
  const { tableId, tableRef } = props
  const visualization = useAtomValue(visualizationAtomFamily({ id: tableId }))

  const triggerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (_event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleMenuItemClick = (exportType: string) => {
    handleClose()

    try {
      switch (exportType) {
        case 'csv':
          tableRef?.current?.exportDataAsCsv({
            fileName: `${visualization?.title ?? 'table'}-${tableId}`,
            delimiter: ',',
            includeHeaders: true
          })
          break
        default:
          message.error('Invalid export type')
          break
      }
    } catch (e) {
      message.error('Something went wrong')
      console.error('Error exporting the chart', e)
    }
  }

  const theme = useTheme()

  return (
    <>
      <IconButton
        disableRipple
        aria-controls={isOpen ? 'export-menu' : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: theme.palette.grey[100]
          }
        }}
      >
        <div
          ref={triggerRef}
          className='px-1 flex items-center gap-2 flex-1 font-bold'
          style={{
            color: theme.palette.text.primary,
            fontSize: theme.typography.sm
          }}
        >
          <Export height={18} width={18} />
          <div className='flex-1 text-left'>Export</div>
          <ChevronDown width={16} />
        </div>
      </IconButton>
      <Menu
        anchorEl={triggerRef.current}
        id='export-menu'
        MenuListProps={{
          'aria-labelledby': 'export-button',
          sx: { width: triggerRef.current?.clientWidth }
        }}
        onClose={handleClose}
        open={isOpen}
      >
        {VALID_EXPORT_TYPES.map(exportType => (
          <Menu_Item_Label
            key={exportType}
            onClick={() => handleMenuItemClick(exportType)}
          >
            {exportType.toUpperCase()}
          </Menu_Item_Label>
        ))}
      </Menu>
    </>
  )
}

const Menu_Item_Label = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.sm,
  paddingBlock: theme.spacing(1.25),
  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[100],
    '&:hover,&:focus': {
      backgroundColor: theme.palette.grey[200]
    }
  }
}))
