/**
 * Converts an HTML table (from the DOM) to a CSV string.
 *
 * @param {string} tableId - The ID of the HTML table to convert.
 * @param {string} [separator=','] - The separator to use in the CSV. Defaults to a comma.
 * @returns {string} The resulting CSV string.
 *
 * @example
 * const csv = htmlTableToCsv('myTableId');
 * console.log(csv);
 * // Output:
 * // "Header 1","Header 2"
 * // "Data 1","Data 2"
 */
export function htmlTableToCsv(
  tableId: string,
  separator: string = ','
): string {
  // Select rows from table_id
  const rows = document.querySelectorAll('#' + tableId + ' tr')
  // Construct csv
  const csv = []
  for (let i = 0; i < rows.length; i++) {
    const row = []
    const cols = rows[i].querySelectorAll('td, th')
    for (let j = 0; j < cols.length; j++) {
      const column = cols[j] as HTMLElement
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      let data = column.innerText
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace(/(\s\s)/gm, ' ')
        // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
        .replace(/"/g, '""')
      // Push escaped string
      row.push('"' + data + '"')
    }
    csv.push(row.join(separator))
  }
  return csv.join('\n')
}

/**
 * Converts an HTML table string to a CSV string.
 *
 * @param {string} tableString - The HTML table string to convert.
 * @param {string} [separator=','] - The separator to use in the CSV. Defaults to a comma.
 * @returns {string} The resulting CSV string.
 *
 * @example
 * const htmlTable = `
 * <table>
 *   <tr>
 *     <th>Header 1</th>
 *     <th>Header 2</th>
 *   </tr>
 *   <tr>
 *     <td>Data 1</td>
 *     <td>Data 2</td>
 *   </tr>
 * </table>`;
 * const csv = tableStringToCsv(htmlTable);
 * console.log(csv);
 * // Output:
 * // "Header 1","Header 2"
 * // "Data 1","Data 2"
 */
export function tableStringToCsv(
  tableString: string,
  separator: string = ','
): string {
  // Create a new table element and set its innerHTML to the provided table string
  const table = document.createElement('div')
  table.innerHTML = tableString

  // Select rows from the table
  const rows = table.querySelectorAll('tr')
  // Construct csv
  const csv = []
  for (let i = 0; i < rows.length; i++) {
    const row = []
    const cols = rows[i].querySelectorAll('td, th')
    for (let j = 0; j < cols.length; j++) {
      const column = cols[j] as HTMLElement
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      let data = column.innerText
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace(/(\s\s)/gm, ' ')
        // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
        .replace(/"/g, '""')
      // Push escaped string
      row.push('"' + data + '"')
    }
    csv.push(row.join(separator))
  }
  return csv.join('\n')
}

export function isTableStringEmpty(tableString: string): boolean {
  // Create a new table element and set its innerHTML to the provided table string
  const table = document.createElement('div')
  table.innerHTML = tableString

  // Select rows from the table
  const rows = table.querySelectorAll('tr')
  if (rows.length === 0) {
    return true
  }

  // Check if any of the rows have children
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].children.length > 0) {
      return false
    }
  }

  return true
}
