import { TrashCan, WarningHexFilled } from '@carbon/icons-react'
import { GridColDef } from '@mui/x-data-grid'
import { Button, Popconfirm } from 'antd'
import dayjs from 'dayjs'
import { startCase } from 'lodash-es'

import { DateFormats } from '@/constants'

import { TemplateLibraryKeys, ValidKeys } from '../constants'

export const getColumnDefs = (
  dataType: string,
  onDelete: (id: string) => void
): GridColDef[] => {
  return ValidKeys.sort((a, b) => {
    if (a === TemplateLibraryKeys.Id) return 1
    if (b === TemplateLibraryKeys.Id) return -1
    return 0
  }).map(key => ({
    field: key,
    minWidth: key === TemplateLibraryKeys.Id ? 120 : 200,
    flex: key === TemplateLibraryKeys.Id ? undefined : 1,
    disableReorder: key === TemplateLibraryKeys.Id,
    sortable: key !== TemplateLibraryKeys.Id,
    renderHeader: ({ field }) => {
      switch (field) {
        case TemplateLibraryKeys.Id:
          return ''
        default:
          return startCase(field)
      }
    },
    renderCell: ({ value, row }) => {
      switch (key) {
        case TemplateLibraryKeys.Title:
          return (
            <div className='font-semibold py-1 self-start overflow-hidden text-ellipsis'>
              {value}
            </div>
          )
        case TemplateLibraryKeys.Description:
          return <pre className='text-xs py-1'>{value}</pre>
        case TemplateLibraryKeys.CreatedAt:
        case TemplateLibraryKeys.UpdatedAt:
          return (
            <div className='py-2 self-start'>
              {value !== null
                ? dayjs.utc(value).local().format(DateFormats.DATE_TIME_WITH_TZ)
                : null}
            </div>
          )

        case TemplateLibraryKeys.Id:
          return (
            <div className='flex py-2 gap-x-2 self-start'>
              <Popconfirm
                key='delete'
                cancelText='No'
                description={
                  <>
                    <div className='my-2 text-xs'>
                      <span className='font-mono font-medium mr-1 p-0.5 bg-yellow-100'>
                        {row.title}
                      </span>
                      will be
                      <span className='font-semibold mx-1'>permanently</span>
                      deleted
                    </div>
                    <div className='text-xs font-medium text-gray-500'>
                      This action cannot be undone
                    </div>
                  </>
                }
                icon={
                  <WarningHexFilled className='mr-1 text-amber-500' size={22} />
                }
                okText='Yes'
                onConfirm={() => onDelete(row.id)}
                placement='topRight'
                title={`Are you sure you want to delete this ${dataType}?`}
              >
                <Button
                  danger
                  className='px-1.5 text-xs font-medium'
                  type='text'
                >
                  <TrashCan /> Delete
                </Button>
              </Popconfirm>
            </div>
          )

        default:
          return value
      }
    }
  }))
}
