const dict = {
  applyDatasets_one: 'Apply dataset',
  applyDatasets_other: 'Apply datasets',
  clearAllDatasets: 'Clear all datasets {{suffix}}',
  clearAllDatasetsSuffix: '(incl. unapplied)',
  clearAppliedDatasets_one: 'Clear applied dataset',
  clearAppliedDatasets_other: 'Clear applied datasets',
  clearChat: 'Clear chat',
  clearDatasets_one: 'Clear datasets',
  clearDatasets_other: 'Clear datasets',
  confirmOverwriteSharedNotebook: {
    inquiry: 'Do you want to overwrite the existing notebook with the new one?',
    title: 'Existing notebook found',
    warning:
      'Warning: This notebook will be saved and shared with all users who have access to this workspace.'
  },

  datasetsChanged: 'Datasets changed',
  deselectAllDatasets: 'Deselect all datasets',
  doesNotDeselectDatasets: '(does not deselect datasets)',
  errors: {
    errorRunningCode: 'Error running code'
  },
  hotkeys: 'Hotkeys',
  jupyterLiteError: 'JupyterLite error',
  jupyterStatus: {
    busy: 'Busy',
    error: 'Error',
    ready: 'Workspace ready',
    starting: 'Setting up workspace'
  },
  multiInstanceUnavailable: {
    body: `
      The AI functionality is only available in the main window of the Byterat application.
      Please ensure this is the only open window with Byterat to interact with the AI.\n
      If you have multiple windows open, close all other windows and refresh this page.
      If you believe you are seeing this message in error, try refreshing the page or contact support for assistance.
    `,
    headline: 'AI Interaction Unavailable'
  },
  nAppliedDatasets_one: '{{count}} applied dataset',
  nAppliedDatasets_other: '{{count}} applied datasets',
  nDatasetsSelected_one: '{{count}} dataset selected ✅',
  nDatasetsSelected_other: '{{count}} datasets selected ✅',
  nLines_one: '{{count}} line',
  nLines_other: '{{count}} lines',
  nUnappliedDatasets_one: '{{count}} unapplied dataset',
  nUnappliedDatasets_other: '{{count}} unapplied datasets',
  noDatasetsSelected: 'No datasets selected',
  noVisualizations: {
    potentialCodeIssue:
      'No visualization returned. This may indicate a code issue.',
    title: 'Empty visualization'
  },
  notebookCell: 'Notebook cell',
  notebookSelected: 'Notebook selected',
  notebooks: 'Notebooks',
  ohmAIPromptPlaceholder: 'What would you like to plot?',
  ohmAiGeneratedCode: 'ByteratAI generated code',
  output: 'Output',
  outputAI: 'AI Execution Output',
  pleaseSelectDatasets: 'Please select datasets to use with the notebook',
  plot: 'Plot',
  processing: 'Processing...',
  processingAIOutput: 'Analyzing thoughts...',
  quickPrompts: {
    dischargeVCycle: 'Plot discharge capacity v cycle',
    title: 'Quick prompts',
    voltageAndCurrentVTime: 'Plot voltage and current v time for cycle 1',
    voltageVCapacity: 'Plot voltage v capacity for cycles 1-9'
  },
  resultTable: 'Result table',
  saveAsNotebook: {
    description:
      'This will save the current conversation as a Jupyter notebook',
    note: 'This notebook will be saved in the <code>{{path}}</code> directory of your Notebooks.',
    saveFailure: 'Failed to save notebook',
    saveSuccessful: 'Notebook saved successfully',
    savedToPath: 'Notebook saved to {{path}}',
    title: 'Save as notebook'
  },
  selectAllOnCurrentPage: 'Select all (on current page)',
  selectNotebook: 'Select notebook',
  selectedNDatasets_one: 'Selected {{count}} dataset',
  selectedNDatasets_other: 'Selected {{count}} datasets',
  selectedNotebookExecuting: 'Executing selected notebook',
  selectedNotebookNotExecutingNoDatasets:
    'Not executing {{selectedNotebookName}} because no datasets are selected',
  selectingDatasetsX_one: 'Selecting datasets: [{{x}}]',
  selectingDatasetsX_other: 'Selecting datasets: [{{x}}]',
  selectingDatasets_one: 'Selecting dataset',
  selectingDatasets_other: 'Selecting datasets',
  selectingNotebookX: 'Selecting notebook: {{x}}',
  showHotkeys: 'Show hotkeys',
  spotlightSearch: {
    fileType: {
      ai: 'ByteratAI',
      shared: 'Shared'
    },
    inputPlaceholder: 'Search notebooks...',
    nCodeCells_one: '{{count}} code cell',
    nCodeCells_other: '{{count}} code cells'
  },
  systemMessage: 'System message',
  thinkingMessages: {
    meditating: 'Meditating...',
    pondering: 'Pondering...',
    stillInDeepThought: 'Still in very deep thought...',
    thinking: 'Thinking...'
  },
  thisIsAnInternalInteraction: 'This is an internal interaction',
  toggleDatasetFinder: 'Toggle dataset finder',
  untitledChat: 'Untitled chat',
  warnings: {
    maxSelections:
      'You can select up to {{max}} datasets. Please deselect one to add another'
  },
  welcomeComponent: {
    alreadyKnowYourWayAround:
      'Already know your way around? Click to <btn>hide tips at startup</btn>.',
    happyExploring: 'Happy exploring!',
    helloAgain: 'Hello again! How can I help analyze your battery data today?',
    ideas: {
      createCharts: {
        bullet1: 'Change axis label font size to 12',
        bullet2: 'Set the y-axis range between 0.01 and 1.42',
        title: 'Create charts then tailor visuals to your needs'
      },
      extractInsights: {
        bullet1:
          'Group data by <code>[metadata]</code> and calculate averages, medians, standard deviations, and other statistics.',
        bullet2: 'Render a table that highlights anomalous voltage data',
        title: 'Extract insights'
      },
      obtainViz: {
        bullet1: 'Plot discharge capacity vs cycle',
        bullet2:
          'Plot observation data: current vs time, voltage vs time, voltage vs discharge capacity, voltage vs charge capacity, and convert units if needed.',
        title: 'Obtain visualizations'
      },
      selectDatasets: { title: 'Select datasets and...' }
    },
    intro:
      "I'm here to help you analyze and visualize your battery data. Here are some tips to get you started",
    needIdeas: 'Need ideas?',
    tips: {
      tip1: 'Select datasets and notebooks using the buttons on the bottom left.',
      tip2: 'Type questions or commands below and press Enter to interact with the AI.',
      tip3: 'Use hotkeys for quick actions. Click the fire icon for a list.'
    },
    tipsHidden: 'Tips will no longer be shown at startup.',
    title: 'Welcome to ByteratAI!'
  },
  xApplied: '{{x}} (applied)',
  xRemoved: '{{x}} (removed)',
  xSelected: '{{x}} selected ✅',
  xUnapplied: '{{x}} (unapplied)'
}

export default dict
