import { Spin } from 'antd'

interface OhmLogoProps {
  loading?: boolean
  width?: number
  height?: number
}

export const OhmLogo = (props: OhmLogoProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading = false, width = 24, height = 24 } = props

  return loading ? <Spin size='small' /> : null
  // return (
  //   <svg width={width} height={height} viewBox='0 0 24 24'>
  //     <defs>
  //       <linearGradient id='pulseGradient' x1='0%' y1='0%' x2='0%' y2='100%'>
  //         <stop offset='0%' stopColor='#186AED'>
  //           {loading && (
  //             <animate
  //               attributeName='y1'
  //               values='0%;100%;0%'
  //               dur='2s'
  //               repeatCount='indefinite'
  //             />
  //           )}
  //         </stop>
  //         <stop offset='100%' stopColor='#0A3170'>
  //           {loading && (
  //             <animate
  //               attributeName='y1'
  //               values='100%;200%;100%'
  //               dur='2s'
  //               repeatCount='indefinite'
  //             />
  //           )}
  //         </stop>
  //       </linearGradient>
  //       <linearGradient id='textGradient' x1='0%' y1='0%' x2='0%' y2='100%'>
  //         <stop offset='0%' stopColor='white' />
  //         <stop offset='100%' stopColor='#186AED' />
  //       </linearGradient>
  //     </defs>
  //     <circle cx='12' cy='12' r='10' fill='url(#pulseGradient)' />
  //     <text
  //       x='12'
  //       y='13'
  //       dominantBaseline='middle'
  //       textAnchor='middle'
  //       fontSize='14'
  //       fontWeight='bold'
  //       fill={loading ? 'url(#textGradient)' : 'white'}
  //       style={
  //         loading
  //           ? {
  //               animation: 'textColorChange 2s ease-in-out infinite'
  //             }
  //           : undefined
  //       }
  //     >
  //       Ω
  //     </text>
  //     <style>{`
  //         @keyframes textColorChange {
  //           0% { fill: #186AED; }
  //           50% { fill: white; }
  //           100% { fill: #186AED; }
  //         }
  //       `}</style>
  //   </svg>
  // )
}
