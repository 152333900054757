import { TagProps } from 'antd'
import { ReactNode } from 'react'

import { Tag, Tooltip } from '@/components'
import { cn } from '@/utils'

type DatasetTagProps = TagProps & {
  text: ReactNode
  tooltipText?: string
  hideTooltip?: boolean
  className?: string
}
export const DatasetTag = (props: DatasetTagProps) => {
  const {
    text,
    hideTooltip = false,
    className,
    icon,
    tooltipText,
    ...rest
  } = props

  const renderedTag = (
    <Tag
      className={cn(
        'm-0 inline-flex min-w-[80px] max-w-[150px] cursor-pointer items-center gap-x-1 overflow-hidden whitespace-nowrap',
        className
      )}
      {...rest}
    >
      <div className='truncate'>{text}</div>
    </Tag>
  )

  if (hideTooltip) {
    return renderedTag
  }
  return (
    <Tooltip
      arrow={{ pointAtCenter: true }}
      classNames={{ root: 'text-xs' }}
      placement='topLeft'
      title={tooltipText ?? text}
    >
      {renderedTag}
    </Tooltip>
  )
}
