import { gql } from '@/gql_generated'

export const OHM_AI_CHAT_COMPLETION_V2 = gql(`
  mutation chat_completion_v2($organization_id: String!, $workspace_ids: [String!]!, $prompt: String!, $conversation_id: String!, $chat_history: [Ohm_Conversation_Message!]!, $dataset_keys: [String!]!) {
    chat_completion_v2(organization_id: $organization_id, workspace_ids: $workspace_ids, prompt: $prompt, conversation_id: $conversation_id, chat_history: $chat_history, dataset_keys: $dataset_keys) {
      response
      type
    }
  }
`)
