import { ICell, INotebookContent } from '@jupyterlab/nbformat'
import { groupBy } from 'lodash-es'

import { OhmAiMessage, OhmAiMessageType } from '../types'

export function convertToNotebook(messages: OhmAiMessage[]): INotebookContent {
  const cells: ICell[] = []

  // Group messages by promptId
  const messagesByPromptId = groupBy(messages, 'promptId')

  // Process each promptId group
  Object.entries(messagesByPromptId).forEach(([promptId, promptMessages]) => {
    const pythonCodeMessage = promptMessages.find(
      message => message.type === OhmAiMessageType.OhmPythonCode
    )
    const userMessageWithCells = promptMessages.find(
      message => message.type === OhmAiMessageType.UserMessage && message.cells
    )

    // Process if there's either a SystemPythonCode or a UserMessage with cells
    if (pythonCodeMessage || userMessageWithCells) {
      promptMessages.forEach(message => {
        switch (message.type) {
          case OhmAiMessageType.UserMessage:
            cells.push({
              cell_type: 'markdown',
              metadata: {},
              source: message.toString()
            })
            if (message.cells) {
              cells.push(...message.cells)
            }
            break
          case OhmAiMessageType.OhmPythonCode:
            cells.push({
              cell_type: 'code',
              execution_count: null,
              metadata: {},
              outputs: [],
              source: message.content?.toString() || ''
            })
            break
        }
      })
    }
  })

  return {
    cells,
    metadata: {
      kernelspec: {
        display_name: 'Python 3',
        language: 'python',
        name: 'python3'
      }
    },
    nbformat: 4,
    nbformat_minor: 4
  }
}
