import { useQuery } from '@apollo/client'
import { Add, Close, Save, TrashCan } from '@carbon/icons-react'
import { Box, Button, Grid2 } from '@mui/material'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  InsightsCycleFilters,
  chartStatesFamily
} from '@/insights/jotai/charts.atoms'
import { PropertyFilterWithValues } from '@/insights/types'

import {
  Filter_Option,
  Filter_Type,
  Property_Type
} from '../../../../gql_generated/graphql'
import { use_workspace_and_org_ids } from '../../../../navigation/hooks/use_workspace_and_org_ids'
import { Autocomplete_Filter_Pair } from '../../../controls/filters/Autocomplete_Filter_Pair'
import { Filter_Grid_Item } from '../../../controls/filters/Filter_Grid'
import { Menu_Header, use_menu_nav } from '../../../controls/menus'
import { Rounded_Icon_Button } from '../../../controls/menus/shared_styled_components'
import { Numeric_Property_Types } from '../../chart_options'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../../cycle_summaries/queries/get_cycle_summary_properties'
import {
  Byterat_Property,
  Excluded_Byterat_Property
} from '../../models/byterat_properties.model'

const DEFAULT_FILTER: PropertyFilterWithValues = {
  property: { key: 'cycle_number', label: 'Cycle Number' },
  filter_type: Filter_Type.NumericEquals,
  // @ts-expect-error - TODO: Fix types
  values: 1
}

export function InCyclePanelCycleFilters({ chartId }: { chartId: string }) {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  // Jotai chart state
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: chartId })
  )
  const { cycleFilters } = chartState

  const { data, loading } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id
  })

  const available_property_key_options =
    data?.get_cycle_summary_properties?.cycle_summary_properties || []

  // Local state
  const [filters, setFilters] = useState<InsightsCycleFilters>(cycleFilters)

  const handleAddCycleFilter = () => {
    setFilters(prev => ({
      ...prev,
      [uuidv4()]: {
        property: null,
        filter_type: Filter_Type.NumericEquals,
        values: []
      }
    }))
  }

  const handleDeleteCycleFilter = (filterId: string) => {
    setFilters(prev => {
      const new_filters = { ...prev }
      delete new_filters[filterId]

      // If no filters are left, add a default filter
      if (Object.keys(new_filters).length === 0) {
        return { [uuidv4()]: { ...DEFAULT_FILTER } }
      }

      return new_filters
    })
  }

  const handleCycleFilterPropertyChange = (
    filterId: string,
    property: Filter_Option | null
  ) => {
    setFilters(prev => ({
      ...prev,
      [filterId]: {
        ...prev[filterId],
        property: property
      }
    }))
  }

  const handleCycleFilterValuesChange = (
    filterId: string,
    values: Filter_Option[]
  ) => {
    setFilters(prev => ({
      ...prev,
      [filterId]: {
        ...prev[filterId],
        values: values
      }
    }))
  }

  const handleCycleFilterOperatorChange = (
    filterId: string,
    filterType: Filter_Type
  ) => {
    setFilters(prev => ({
      ...prev,
      [filterId]: {
        ...prev[filterId],
        filter_type: filterType
      }
    }))
  }

  const handleDeleteAllCycleFilters = () => {
    setFilters({ [uuidv4()]: { ...DEFAULT_FILTER } })
  }

  const handleSaveCycleFilters = () => {
    setChartState({
      ...chartState,
      cycleFilters: filters
    })
  }

  return (
    <>
      <Menu_Header on_go_back={return_to_root} title='Select Cycles' />

      <Box width={450}>
        <Grid2 container padding={1} rowGap={1}>
          <Grid2
            container
            alignItems='flex-start'
            gap={2}
            justifyContent='flex-start'
            marginLeft='auto'
            size={12}
          >
            <Filter_Grid_Item
              container
              alignItems='flex-start'
              flexDirection='column'
              gap={1}
              width='100%'
            >
              {Object.entries(filters).map(([key, filter]) => {
                return (
                  <Filter_Grid_Item
                    key={key}
                    container
                    alignItems='center'
                    width='100%'
                  >
                    <Autocomplete_Filter_Pair
                      center_operator={{
                        onChange: filter_type => {
                          handleCycleFilterOperatorChange(key, filter_type)
                        },
                        sx: {
                          '& .MuiInputBase-root': {
                            borderRadius: 0
                          }
                        }
                      }}
                      filter_type={filter.filter_type}
                      left_filter={{
                        value: filter.property,
                        options: available_property_key_options.filter(
                          _option => {
                            // TODO - Support non-numeric filter types
                            if (
                              !Numeric_Property_Types.includes(
                                _option?.type as Property_Type
                              )
                            ) {
                              return false
                            }

                            // TODO - hardcoded list of exclusions, until we have a way to filter by date/time properties
                            return !Excluded_Byterat_Property.includes(
                              _option.key as Byterat_Property
                            )
                          }
                        ),
                        placeholder: 'Select Filter Property',
                        multiple: false,
                        disableClearable: false,
                        onChange: (_e, value) =>
                          handleCycleFilterPropertyChange(key, value),
                        sx: {
                          '& .MuiInputBase-root': {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0
                          }
                        }
                      }}
                      loading={loading}
                      right_filter={{
                        value: filter.values as Filter_Option[],
                        options: [],
                        placeholder: 'Select Filter Values',
                        multiple: true,
                        disableClearable: false,
                        onChange: (_e, value) =>
                          handleCycleFilterValuesChange(key, value),
                        type: filter?.property?.type as Property_Type,
                        sx: {
                          '& .MuiInputBase-root': {
                            borderRadius: 0
                          }
                        }
                      }}
                    />
                    <Box
                      alignSelf='stretch'
                      display='flex'
                      flexDirection='column'
                    >
                      <Rounded_Icon_Button
                        onClick={() => handleDeleteCycleFilter(key)}
                        size='small'
                        sx={{
                          flex: 'auto',
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 6,
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 6,
                          border: theme => `1px solid ${theme.palette.divider}`
                        }}
                      >
                        <Close />
                      </Rounded_Icon_Button>
                    </Box>
                  </Filter_Grid_Item>
                )
              })}
              <Box display='flex' gap={1} justifyContent='flex-end'>
                <Button
                  onClick={handleAddCycleFilter}
                  size='small'
                  startIcon={<Add height='1em' width='1em' />}
                  variant='text'
                >
                  Add Filter
                </Button>
                {Object.entries(filters).length > 0 && (
                  <>
                    <Button
                      onClick={handleDeleteAllCycleFilters}
                      size='small'
                      startIcon={<TrashCan height='1em' width='1em' />}
                      variant='text'
                    >
                      Remove All
                    </Button>
                  </>
                )}
              </Box>
              <Button
                onClick={handleSaveCycleFilters}
                size='small'
                startIcon={<Save height='1em' width='1em' />}
                variant='text'
              >
                Apply Filters
              </Button>
            </Filter_Grid_Item>
          </Grid2>
        </Grid2>
      </Box>
    </>
  )
}
