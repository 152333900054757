import { DataCategorical } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'

import { Select } from '@/components'
import { Property_Option } from '@/gql_generated/graphql'
import {
  VisualizationType,
  visualizationAtomFamily
} from '@/insights/reports/store/report.molecule'
import { mapListKeysToValues } from '@/utils'

import { MenuHeader, MenuItemControl } from '../../../controls'
import { useVisualizationItem } from '../../VisualizationContext'

type PanelGroupByProps = {
  selectedColumns: string[]
  groupByOptions: Property_Option[]
  loading?: boolean
}

/**
 * PanelYAxis component renders the Y-Axis configuration panel for a chart visualization.
 *
 * @param {PanelGroupBy} props - The properties for the PanelYAxis component.
 */
export function PanelGroupBy(props: PanelGroupByProps) {
  const { selectedColumns, groupByOptions, loading = true } = props
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()

  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { config, type } = visualization
  if (type !== VisualizationType.Table) return null

  const { groupByProperty } = config

  /**
   * Handles the change event for the Group By select input.
   *
   * @param {string} value - The selected value for the Group By property.
   */
  const onGroupByChange = (value: Nullable<string>) => {
    if (groupByOptions == null) return

    if (value == null) {
      updateVisualizationConfig({ groupByProperty: null })
      return
    }

    const groupByProperty = groupByOptions.find(({ key }) => key === value)
    if (groupByProperty == null) return

    updateVisualizationConfig({ groupByProperty })

    // If the group by column is not in the list of selected columns, add it
    if (!selectedColumns || !selectedColumns.includes(value)) {
      updateVisualizationConfig({
        columns: [...selectedColumns, value]
      })
    }
  }

  return (
    <div>
      <MenuHeader
        title={
          <div className='flex flex-row gap-x-2 items-center'>
            <DataCategorical />
            Group By
          </div>
        }
      />
      <div className='p-2'>
        <div className='flex flex-col gap-2 items-stretch'>
          <MenuItemControl
            emphasize
            inputId='group-by-table'
            label='Group By'
            loading={loading}
          >
            <Select
              allowClear
              showSearch
              aria-labelledby='group-by'
              className='flex-1'
              id='group-by'
              onChange={onGroupByChange}
              options={mapListKeysToValues(groupByOptions)}
              placeholder='Select Metric'
              value={groupByProperty}
            />
          </MenuItemControl>
        </div>
      </div>
    </div>
  )
}
