import saveAs from 'file-saver'
import { nanoid } from 'nanoid'

import { isTableStringEmpty, tableStringToCsv } from '@/utils'

import { OhmOutputContainer } from './OhmOutputContainer'

type OhmTableProps = {
  htmlData: string
  descriptor: string
  fileName?: string
}
export const OhmTable = (props: OhmTableProps) => {
  const { htmlData, descriptor, fileName } = props

  const tableId = `table-${nanoid()}`
  const isHtmlTable = htmlData.includes('<table')
  const isTableEmpty = isTableStringEmpty(htmlData)
  /**
   * Sanitizes the file name by removing any non-alphanumeric characters and spaces.
   * If the file name is not provided, it generates a default name using the descriptor.
   */
  const sanitizedFileName =
    fileName ?? `${descriptor.replace(/[^a-zA-Z0-9 ]/g, '')}.csv`

  const downloadOutput = () => {
    saveAs(
      new Blob([tableStringToCsv(htmlData)], {
        type: 'text/csv;charset=utf-8'
      }),
      sanitizedFileName
    )
  }

  return (
    <div className='flex flex-1 items-stretch justify-between'>
      <OhmOutputContainer
        modalProps={{
          children: (
            <div
              className='flex flex-1 p-2 [&_table]:relative [&_thead]:sticky [&_thead]:top-0 [&_thead]:bg-white'
              dangerouslySetInnerHTML={{ __html: htmlData }}
              id={tableId}
            />
          ),
          title: descriptor
        }}
        onDownload={downloadOutput}
        outputContainerClassName={{ 'p-0': !isTableEmpty }}
        showActions={!isTableEmpty && isHtmlTable}
      >
        {isTableEmpty ? (
          <pre className='p-4 italic'>Empty table returned</pre>
        ) : (
          <div
            className='flex flex-1 p-2'
            dangerouslySetInnerHTML={{ __html: htmlData }}
            id={tableId}
          />
        )}
      </OhmOutputContainer>
    </div>
  )
}
