import { useQuery } from '@apollo/client'
import {
  Grid2,
  Breadcrumbs as MuiBreadcrumbs,
  Skeleton,
  styled
} from '@mui/material'
import { useAtomValue } from 'jotai'
import { startCase } from 'lodash-es'
import { useLocation, useNavigate, useParams } from 'react-router'

import { WORKSPACE_ROLES_ALL, WorkspaceRoles } from '@/constants'
import { reportAtomFamily } from '@/insights/reports/store/report.molecule'
import { getOrgWorkspaces } from '@/utils/org'

import { Workspace } from '../../gql_generated/graphql'
import { GET_ORGANIZATIONS } from '../../user/queries/get_organizations'
import { WorkspaceFeatureFlags, WorkspaceKey } from '../FeatureFlags'
import { Breadcrumb_With_Menu } from './Breadcrumb_With_Menu'

const pageOptions = [
  {
    label: 'Playground',
    roles: WORKSPACE_ROLES_ALL,
    value: 'home'
  },
  {
    label: 'Reports',
    roles: WORKSPACE_ROLES_ALL,
    value: 'reports'
  },
  {
    label: 'Alerts',
    roles: WORKSPACE_ROLES_ALL,
    value: 'alerts'
  },
  {
    label: 'Upload Datasets',
    roles: WORKSPACE_ROLES_ALL,
    value: 'upload-datasets'
  },
  {
    label: 'Cell Specifications',
    roles: WORKSPACE_ROLES_ALL,
    value: 'cell-specifications'
  },
  {
    label: 'Template Library',
    roles: WORKSPACE_ROLES_ALL,
    value: 'template-library'
  },
  {
    label: 'Notebooks',
    roles: WORKSPACE_ROLES_ALL,
    value: 'notebooks'
  },
  {
    label: 'Byterat AI',
    roles: WORKSPACE_ROLES_ALL,
    value: 'chat'
  },
  {
    label: 'Workspace Settings',
    roles: [WorkspaceRoles.Admin],
    value: 'account-settings'
  }
]

export function Breadcrumbs() {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    organization_key: organizationKey,
    workspace_key: workspaceKey,
    reportId
  } = useParams()

  const report = useAtomValue(reportAtomFamily({ id: reportId }))

  const { data, error, loading } = useQuery(GET_ORGANIZATIONS, {
    onCompleted: ({ get_current_user_organizations }) => {
      let organizationKeyParam = organizationKey || ''
      let workspaceKeyParam = workspaceKey || ''

      // If no organizationKey or workspaceKey is provided, or organizationKey is not included in the user's organizations,
      // redirect to the first organization and workspace
      if (
        organizationKey == null ||
        !get_current_user_organizations
          .map(org => org.key)
          .includes(organizationKey)
      ) {
        organizationKeyParam = get_current_user_organizations[0].key
      }

      const workspaces = getOrgWorkspaces(
        get_current_user_organizations,
        organizationKeyParam
      )
      const validWorkspaceKeys = workspaces.map(workspace => workspace.key)

      // If no workspaceKey is provided, or workspaceKey does not belong to the provided organization,
      // redirect to the first workspace of the provided organization
      if (!workspaceKey || !validWorkspaceKeys.includes(workspaceKey)) {
        workspaceKeyParam = validWorkspaceKeys[0]
      }
      const rootRoute = `/${organizationKeyParam}/${workspaceKeyParam}`
      if (!location.pathname.startsWith(rootRoute)) {
        navigate(rootRoute)
      }
    }
  })

  const organizations = data?.get_current_user_organizations || []
  const organizationKeys = organizations.map(org => org.key)
  const validOrganizationKey =
    organizationKey && organizationKeys.includes(organizationKey)

  let workspaces: Workspace[] = []
  if (validOrganizationKey) {
    workspaces = getOrgWorkspaces(organizations, organizationKey)
  }
  const workspaceKeys = workspaces.map(workspace => workspace.key)
  const validWorkspaceKey = workspaceKey && workspaceKeys.includes(workspaceKey)

  if (error || loading || !validOrganizationKey || !validWorkspaceKey) {
    return (
      <BreadcrumbContainer container className='px-5'>
        <Grid2 size={12}>
          <MuiBreadcrumbs aria-label='breadcrumb'>
            <StyledChipSkeleton />
            <StyledChipSkeleton />
          </MuiBreadcrumbs>
        </Grid2>
      </BreadcrumbContainer>
    )
  }

  const organizationMenuOptions = organizations.map(org => ({
    label: org.name || '',
    value: org.key
  }))

  const workspaceMenuOptions = workspaces.map(workspace => ({
    label: workspace.name,
    value: workspace.key
  }))

  const selectedRootPage = location.pathname.split('/')[3] || ''
  const selectedWorkspacePage =
    pageOptions.find(option => selectedRootPage === option.value)?.value || ''

  let leafPage = null
  const isTemplateLibraryPage = location.pathname.includes('/template-library/')
  if (isTemplateLibraryPage) {
    leafPage = startCase(
      location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    )
  }
  const isReportsPage =
    !isTemplateLibraryPage && location.pathname.includes('/reports/')
  if (isReportsPage) {
    leafPage = location.pathname.includes('item')
      ? report.title
      : 'Create Report'
  }

  const handleOrganizationSelected = (organizationKey: string) => {
    const defaultWorkspaceKey = getOrgWorkspaces(
      organizations,
      organizationKey
    )[0].key
    navigate(`/${organizationKey}/${defaultWorkspaceKey}`)
  }

  const handleWorkspaceSelected = (workspaceKey: string) => {
    navigate(`/${organizationKey}/${workspaceKey}`)
  }

  const handlePageSelected = (page: string) => {
    navigate(`/${organizationKey}/${workspaceKey}/${page}`)
  }

  const workspace_role =
    workspaces.find(workspace => workspace.key === workspaceKey)?.role ||
    WorkspaceRoles.Member

  const filteredPageOptions = pageOptions
    .filter(option => option.roles.includes(workspace_role))
    .filter(option => {
      const enabledWorkspaces = WorkspaceFeatureFlags[option.value]

      if (enabledWorkspaces == null) return true
      return enabledWorkspaces.includes(
        workspaceKey.toLowerCase() as WorkspaceKey
      )
    })

  return (
    <BreadcrumbContainer container className='px-5'>
      <Grid2 size={12}>
        <MuiBreadcrumbs aria-label='breadcrumb'>
          <Breadcrumb_With_Menu
            menu_options={organizationMenuOptions}
            on_option_selected={handleOrganizationSelected}
            selected_option_value={organizationKey}
          />
          <Breadcrumb_With_Menu
            menu_options={workspaceMenuOptions}
            on_option_selected={handleWorkspaceSelected}
            selected_option_value={workspaceKey}
          />
          <Breadcrumb_With_Menu
            menu_options={filteredPageOptions}
            on_option_selected={handlePageSelected}
            selected_option_value={selectedWorkspacePage}
          />
          {leafPage && (
            <div className='flex h-6 items-center p-0.5 pb-0 text-[0.8125rem]'>
              {leafPage}
            </div>
          )}
        </MuiBreadcrumbs>
      </Grid2>
    </BreadcrumbContainer>
  )
}

const BreadcrumbContainer = styled(Grid2)`
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
  .MuiBreadcrumbs-ol {
    flex-wrap: nowrap;
  }
`

const StyledChipSkeleton = styled(Skeleton)(({ theme }) => {
  return {
    borderRadius: theme.spacing(8),
    height: theme.spacing(3),
    // Use border radius larger than height to get a pill shape.
    transform: 'none',
    width: theme.spacing(7) // Override default transform added by Mui.
  }
})
