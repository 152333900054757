import { ChevronRight } from '@carbon/icons-react'
import { Collapse as AntCollapse, CollapseProps } from 'antd'
import { FC } from 'react'

import { cn } from '@/utils'

export const Collapse: FC<CollapseProps> = props => {
  return (
    <AntCollapse
      expandIcon={({ isActive }) => (
        <ChevronRight
          className={cn({
            'transform rotate-90': isActive,
            'transform rotate-0': !isActive
          })}
        />
      )}
      expandIconPosition='end'
      {...props}
    />
  )
}
