import {
  Filter,
  SettingsAdjust,
  WatsonHealthCdArchive,
  XAxis,
  YAxis
} from '@carbon/icons-react'
import { Box, Divider, MenuList } from '@mui/material'
import { useAtom } from 'jotai'
import { RefObject } from 'react'
import ReactApexChart from 'react-apexcharts'

import { chartStatesFamily } from '@/insights/jotai/charts.atoms'

import { InsightsChartTypes } from '../charts/types'
import { ChartExportSelect } from './filters/ChartExportSelect'
import { ChartTypeSelect } from './filters/ChartTypeSelect'
import { Chart_Metric_Type_Select } from './filters/Chart_Metric_Type_Select'
import { Menu_Header, use_menu_nav } from './menus'
import { Menu_Panel_Id } from './menus/constants'
import {
  Menu_Item_Label,
  Menu_Item_Right,
  Section,
  Styled_ChevronRight,
  Styled_Menu_Item
} from './menus/shared_styled_components'
import { With_Chart_Title } from './menus/types'

type InsightsChartFilterPanelRootProps = With_Chart_Title & {
  chartId: string
  chartRef: RefObject<ReactApexChart>
}
export function InsightsChartFilterPanelRoot(
  props: InsightsChartFilterPanelRootProps
) {
  const { create_set_selected_panel_id } = use_menu_nav()
  const { chartTitle, chartId, chartRef } = props

  // Jotai chart state
  const [chartState] = useAtom(chartStatesFamily({ id: chartId }))
  const {
    cycleFilters,
    metricType,
    propertyFilters,
    xAxisProperty,
    yAxisProperties
  } = chartState

  const cycleFilterCount = Object.keys(cycleFilters).length
  const propertyFilterCount = Object.keys(propertyFilters).length

  return (
    <Box width={400}>
      <MenuList>
        <Menu_Header title={chartTitle} />
        <Chart_Metric_Type_Select chartId={chartId} />

        <Divider sx={{ marginBlock: 1 }} />
        {metricType !== InsightsChartTypes.CELL_METRICS && (
          <Box>
            {metricType === InsightsChartTypes.IN_CYCLE_METRICS && (
              <Styled_Menu_Item
                onClick={create_set_selected_panel_id(
                  Menu_Panel_Id.CYCLE_FILTERS
                )}
              >
                <Menu_Item_Label>
                  <Filter height={18} width={18} /> Select Cycles
                </Menu_Item_Label>

                <Menu_Item_Right>
                  {cycleFilterCount !== 0 &&
                    `${cycleFilterCount} Item${
                      cycleFilterCount !== 1 ? 's' : ''
                    }`}
                  <Styled_ChevronRight />
                </Menu_Item_Right>
              </Styled_Menu_Item>
            )}

            <Styled_Menu_Item
              onClick={create_set_selected_panel_id(
                Menu_Panel_Id.IN_CYCLE_FILTERS
              )}
            >
              <Menu_Item_Label>
                <Filter height={18} width={18} /> Filters
              </Menu_Item_Label>

              <Menu_Item_Right>
                {propertyFilterCount > 0 &&
                  `${propertyFilterCount} Item${
                    propertyFilterCount !== 1 ? 's' : ''
                  }`}
                <Styled_ChevronRight />
              </Menu_Item_Right>
            </Styled_Menu_Item>

            <Divider />
          </Box>
        )}

        <Styled_Menu_Item
          onClick={create_set_selected_panel_id(Menu_Panel_Id.X_AXIS)}
        >
          <Menu_Item_Label>
            <XAxis height={18} width={18} /> X-Axis
          </Menu_Item_Label>
          <Menu_Item_Right>
            {xAxisProperty?.label}
            <Styled_ChevronRight />
          </Menu_Item_Right>
        </Styled_Menu_Item>

        <Section>
          <Styled_Menu_Item
            onClick={create_set_selected_panel_id(Menu_Panel_Id.Y_AXIS)}
          >
            <Menu_Item_Label>
              <YAxis height={18} width={18} /> Y-Axis
            </Menu_Item_Label>

            <Menu_Item_Right>
              {yAxisProperties?.length > 1
                ? `${yAxisProperties.length} Item${
                    yAxisProperties.length !== 1 ? 's' : ''
                  }`
                : yAxisProperties[0]?.label}
              <Styled_ChevronRight />
            </Menu_Item_Right>
          </Styled_Menu_Item>

          <Styled_Menu_Item
            onClick={create_set_selected_panel_id(Menu_Panel_Id.GROUP_BY)}
          >
            <Menu_Item_Label>
              <SettingsAdjust height={18} width={18} /> Group By
            </Menu_Item_Label>

            <Menu_Item_Right>
              <Styled_ChevronRight />
            </Menu_Item_Right>
          </Styled_Menu_Item>

          {metricType !== InsightsChartTypes.CELL_METRICS && (
            <ChartTypeSelect chartId={chartId} />
          )}

          <Divider sx={{ marginBlock: 0.5 }} />

          {metricType === InsightsChartTypes.CELL_METRICS && (
            <>
              <Styled_Menu_Item
                onClick={create_set_selected_panel_id(Menu_Panel_Id.TARGET)}
              >
                <Menu_Item_Label>
                  <WatsonHealthCdArchive height={18} width={18} /> Target
                </Menu_Item_Label>

                <Menu_Item_Right>
                  Upper, Lower
                  <Styled_ChevronRight />
                </Menu_Item_Right>
              </Styled_Menu_Item>
              <Divider />
            </>
          )}

          <ChartExportSelect chartId={props.chartId} chartRef={chartRef} />
        </Section>
      </MenuList>
    </Box>
  )
}
