import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { type Contents } from '@jupyterlab/services'
import { isEmpty } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import { BaseLayout, Modal, Spin, Typography, notification } from '@/components'
import { NotebookInfo, useSession, useWindowState } from '@/hooks'
import { Pagination } from '@/types'
import { GET_WORKSPACE_API_KEY } from '@/user/queries/get_workspace_api_key'

import useLogger from '../hooks/useLogger'
import {
  ConfirmOverwriteSharedNotebooksConfig,
  ConfirmOverwriteSharedNotebooksProvider,
  NotebookMatches
} from './components'
import {
  JupyterBridgeId,
  JupyterBridgeMessageEvent,
  JupyterBridgeMessageType
} from './constants'
import {
  GET_SHARED_NOTEBOOKS,
  LOAD_SHARED_NOTEBOOK_BY_FILE_NAME,
  SAVE_SHARED_NOTEBOOK
} from './queries/notebooks'

const { Title } = Typography

const passthroughParams = ['debug', 'verbose', 'transient']
const DEFAULT_NOTEBOOK_PATH = 'byterat/Welcome.ipynb'

export const JupyterLite = () => {
  const [modal, modalContextHolder] = Modal.useModal()
  const [notificationApi, notificationContextHolder] =
    notification.useNotification()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const logger = useLogger()

  const { wasEverMainWindow, isMainWindow } = useWindowState()

  const {
    workspaceId,
    workspaceKey,
    organizationId,
    organizationKey,
    updateSessionInfo,
    sessionInfo
  } = useSession()

  const workspaceIds = useMemo(() => {
    if (workspaceId == null) return []
    return [workspaceId]
  }, [workspaceId])

  const [searchParams] = useSearchParams()
  const passThroughParams = useMemo(() => {
    const params = new URLSearchParams()
    passthroughParams.forEach(param => {
      if (searchParams.has(param)) {
        params.append(param, searchParams.get(param)!)
      }
    })
    return params.toString()
  }, [searchParams])

  const lastOpenedPath = sessionInfo.notebooks?.lastOpened?.path
  const lastOpenedOwner = sessionInfo.notebooks?.lastOpened?.metadata?.$owner
  const isWorkspaceOwnerOfLastOpenedPath =
    lastOpenedOwner?.toLowerCase() === workspaceKey?.toLowerCase()

  const jupyterPath = useRef(
    searchParams.get('path') ??
      (isWorkspaceOwnerOfLastOpenedPath ? lastOpenedPath : null) ??
      DEFAULT_NOTEBOOK_PATH
  ).current

  const [notebookMatches, setNotebookMatches] = useState<NotebookMatches>({})
  const [, setLoadedOnce] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [, setIsReady] = useState(false)
  const [paginationModel] = useState<Pagination>({
    page: 0,
    pageSize: 100
  })

  const queryVariables = useMemo(
    () => ({
      organization_id: organizationId as string,
      page: paginationModel.page + 1,
      // The backend indexes from 1
      page_size: paginationModel.pageSize,
      workspace_ids: workspaceIds
    }),
    [organizationId, workspaceIds, paginationModel]
  )

  const { data: notebooksData } = useQuery(GET_SHARED_NOTEBOOKS, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoadedOnce(true)
    },
    skip: !organizationId || !workspaceId || isEmpty(workspaceIds),
    variables: queryVariables
  })

  const { data: apiKeyData } = useQuery(GET_WORKSPACE_API_KEY, {
    onCompleted: () => {
      setLoadedOnce(true)
    },
    skip: !organizationId || !workspaceId || isEmpty(workspaceIds),
    variables: queryVariables
  })
  const workspaceApiKey = apiKeyData?.get_workspace_api_key

  const [loadSharedNotebookByFilename] = useLazyQuery(
    LOAD_SHARED_NOTEBOOK_BY_FILE_NAME,
    { fetchPolicy: 'network-only' }
  )

  const [sharedNotebooks, setSharedNotebooks] = useState<
    Record<string, Contents.IModel>
  >({})

  useEffect(() => {
    if (organizationKey == null) return
    const sharedNotebooks = notebooksData?.get_shared_notebooks?.data
    if (sharedNotebooks != null) {
      setSharedNotebooks(prev => {
        const newSharedNotebooks = sharedNotebooks.reduce(
          (acc, item) => {
            const notebook = JSON.parse(item.ipynb_json)
            notebook.content.metadata = {
              ...notebook.content.metadata,
              $owner: organizationKey.toLowerCase()
            }
            acc[item.id] = notebook
            return acc
          },
          {} as Record<string, Contents.IModel>
        )
        return { ...prev, ...newSharedNotebooks }
      })
    }
  }, [notebooksData, organizationKey])

  const [saveMutation] = useMutation(SAVE_SHARED_NOTEBOOK, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_SHARED_NOTEBOOKS, variables: queryVariables }]
  })

  const sendMessageToIframe = useCallback(
    (type: JupyterBridgeMessageType, payload?: any) => {
      iframeRef.current?.contentWindow?.postMessage(
        {
          payload,
          source: JupyterBridgeId,
          type
        },
        '*'
      )
    },
    []
  )

  const saveSharedNotebooks = useCallback(
    async (payload: Contents.IModel[]) => {
      if (organizationId == null || workspaceIds == null) return
      // Check if the filename already exists in the database as a shared file
      const storedMatches = await Promise.all(
        payload.map(item =>
          loadSharedNotebookByFilename({
            variables: {
              file_name: item.name,
              workspace_ids: workspaceIds
            }
          })
        )
      )

      // Assume confirmed by default, because if there are no existing notebooks, we don't need to ask anything
      let confirmed = true
      // If there are any existing notebooks with the same filename, ask the user if they want to overwrite
      if (storedMatches.some(item => item.data)) {
        const existingNotebooks = storedMatches
          .filter(item => item.data)
          .map(item => item.data?.load_shared_notebook_by_file_name)

        setNotebookMatches(
          existingNotebooks.reduce((acc, existingNotebook) => {
            if (existingNotebook == null) return acc
            const { file_name } = existingNotebook

            const newNotebook = payload.find(
              newNotebook => newNotebook.name === file_name
            )
            if (newNotebook == null) return acc

            acc[file_name] = {
              existingNotebook,
              newNotebook
            }

            return acc
          }, {} as NotebookMatches)
        )
        confirmed = await new Promise<boolean>(resolve => {
          modal.confirm({
            ...ConfirmOverwriteSharedNotebooksConfig,
            okText: 'Overwrite',
            onCancel: () => resolve(false),
            onOk: () => resolve(true)
          })
        })
      }

      // If the user doesn't want to overwrite, return
      if (!confirmed) return

      const existingNotebooks = storedMatches
        .filter(item => item.data)
        .map(item => item.data?.load_shared_notebook_by_file_name)

      const savePromises = payload.map(item => {
        const existingNotebook = existingNotebooks.find(
          existingNotebook => existingNotebook?.file_name === item.name
        )

        return saveMutation({
          variables: {
            file_name: item.name,
            id: existingNotebook?.id ?? uuidv4(),
            ipynb_json: JSON.stringify(item),
            organization_id: organizationId,
            workspace_ids: workspaceIds
          }
        })
      })
      await Promise.all(savePromises)

      notificationApi.success({
        description: (
          <div>
            This notebook will be shared with all users who have access to this
            workspace, and will be available in the{' '}
            <span className='font-mono'>shared</span> directory.
          </div>
        ),
        message: (
          <div>
            Notebooks saved successfully:
            {payload.map((item, idx) => (
              <div key={idx} className='font-mono'>
                {item.name}
              </div>
            ))}
          </div>
        )
      })
    },
    [
      loadSharedNotebookByFilename,
      modal,
      notificationApi,
      organizationId,
      saveMutation,
      workspaceIds
    ]
  )

  const renameToSharedExtensionBlocked = useCallback(
    (payload: string) => {
      notificationApi.warning({
        description: (
          <div>
            The extension <span className='font-mono'>.shared.ipynb</span> is
            reserved for shared notebooks. This file has been renamed to{' '}
            <span className='font-mono'>{payload}</span>.
          </div>
        ),
        message: 'File extension reserved'
      })
    },
    [notificationApi]
  )

  const onMessageReceived = useCallback(
    (event: JupyterBridgeMessageEvent) => {
      const { source, type, payload } = event.data
      if (source !== JupyterBridgeId) return

      if (type !== JupyterBridgeMessageType.Ack) {
        logger.verbose('Message received in the parent:', event.data)
      }

      switch (type) {
        case JupyterBridgeMessageType.Error:
          logger.error('JupyterLite has an error:', payload)
          break
        case JupyterBridgeMessageType.Initialized:
          logger.verbose('JupyterLite is initialized. Setting flag.')
          setIsInitialized(true)
          break
        case JupyterBridgeMessageType.Ready:
          logger.verbose('JupyterLite is ready. Setting flag.')
          setIsReady(true)
          break
        case JupyterBridgeMessageType.Operation:
          logger.verbose('Operation message received:', payload)
          break
        case JupyterBridgeMessageType.SharedExtensionBlocked:
          if (payload != null && typeof payload === 'string') {
            renameToSharedExtensionBlocked(payload)
          }
          break
        case JupyterBridgeMessageType.CopyToClipboard:
          if (payload != null && typeof payload === 'string') {
            // Create a new URL object from the current location
            const currentUrl = new URL(window.location.href)
            // Parse the payload as search params
            const newParams = new URLSearchParams(payload)
            // For each new param, set or update it in the current URL
            for (const [key, value] of Array.from(newParams.entries())) {
              currentUrl.searchParams.set(key, value)
            }
            // Copy the final URL to clipboard
            navigator.clipboard.writeText(currentUrl.toString())
          }
          break
        case JupyterBridgeMessageType.SaveSharedNotebook:
          if (payload != null && typeof payload !== 'string') {
            saveSharedNotebooks(payload as Contents.IModel[])
          }
          break
        case JupyterBridgeMessageType.NotebookOpened: {
          const lastOpened = payload as NotebookInfo
          updateSessionInfo({ notebooks: { lastOpened } })
          logger.verbose('Notebook opened:', lastOpened)
          break
        }
        case JupyterBridgeMessageType.NotebookClosed:
          const notebookPath = payload as string
          if (notebookPath === jupyterPath) {
            updateSessionInfo({ notebooks: { lastOpened: undefined } })
          }
          break
        case JupyterBridgeMessageType.Ack:
          break
        default:
          logger.warn('Unknown message type:', type)
      }

      if (type !== JupyterBridgeMessageType.Ack) {
        sendMessageToIframe(JupyterBridgeMessageType.Ack)
      }
    },
    [
      jupyterPath,
      logger,
      renameToSharedExtensionBlocked,
      saveSharedNotebooks,
      sendMessageToIframe,
      updateSessionInfo
    ]
  )

  useEffect(() => {
    window.addEventListener('message', onMessageReceived)
    return () => {
      window.removeEventListener('message', onMessageReceived)
    }
  }, [onMessageReceived])

  useEffect(() => {
    if (!isInitialized) return

    sendMessageToIframe(JupyterBridgeMessageType.Identify, {
      apiKey: workspaceApiKey,
      organizationKey,
      workspaceKey
    })
  }, [
    isInitialized,
    organizationKey,
    sendMessageToIframe,
    workspaceApiKey,
    workspaceKey
  ])

  useEffect(() => {
    if (!isInitialized) return

    if (!isEmpty(sharedNotebooks)) {
      logger.log(
        'JupyterLite is ready, sending shared notebooks',
        sharedNotebooks
      )
      sendMessageToIframe(
        JupyterBridgeMessageType.LoadSharedNotebook,
        Object.values(sharedNotebooks)
      )
    }
  }, [isInitialized, logger, sendMessageToIframe, sharedNotebooks])

  useEffect(() => {
    if (!isInitialized) return

    // If we know the last opened path, and that it the file belongs to this workspace, open it
    if (jupyterPath) {
      sendMessageToIframe(JupyterBridgeMessageType.OpenPath, {
        path: jupyterPath
      })
    }
  }, [isInitialized, jupyterPath, sendMessageToIframe])

  if (workspaceApiKey == null) return <Spin />

  return (
    <BaseLayout className='flex flex-1 flex-col gap-y-4 px-0 pt-4'>
      <ConfirmOverwriteSharedNotebooksProvider
        value={{ matches: notebookMatches }}
      >
        <Title className='!mb-0 px-4' level={3}>
          Notebooks
        </Title>
        {modalContextHolder}
        {notificationContextHolder}
        {isMainWindow || wasEverMainWindow ? (
          <iframe
            ref={iframeRef}
            className='flex-1'
            name='redspot'
            src={`//byterat-redspot.vercel.app/lab${passThroughParams ? `?${passThroughParams}` : ''}`}
            title='byterat jupyter notebook'
          />
        ) : (
          <div className='flex flex-1 flex-col items-center justify-center gap-y-4'>
            <div className='text-lg font-semibold'>
              Notebooks are not available in this window
            </div>
            <div className='flex flex-col items-center justify-center'>
              To interact with Notebooks, please open this page in the main
              window.
            </div>
          </div>
        )}
      </ConfirmOverwriteSharedNotebooksProvider>
    </BaseLayout>
  )
}
