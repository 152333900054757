import { Children, PropsWithChildren, ReactNode } from 'react'

import { Skeleton } from '../../../../../components'
import { MenuItemInputLabel } from './MenuItemInputLabel'

type MenuItemControlProps = PropsWithChildren<{
  inputId: string
  label: ReactNode
  loading?: boolean
  emphasize?: boolean
}>

export function MenuItemControl(props: MenuItemControlProps) {
  const { emphasize, inputId, label, loading, children } = props

  return (
    <div className='flex flex-1 flex-row gap-x-2 items-center'>
      <div className='w-5/12'>
        <MenuItemInputLabel emphasize={emphasize} htmlFor={inputId}>
          {label}
        </MenuItemInputLabel>
      </div>
      {loading ? (
        Children.map(children, child => (
          <Skeleton.Input active className='!flex flex-1' />
        ))
      ) : (
        <div className='flex gap-x-2 w-7/12 overflow-hidden'>{children}</div>
      )}
    </div>
  )
}
