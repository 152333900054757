import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { castArray } from 'lodash-es'
import { nanoid } from 'nanoid'

import { idEquality } from '@/utils/idEquality'

import { OhmAiMessage } from '../types'

type MaybeArray<T> = T | T[]

/**
 * Configuration for a conversation
 */
type ConversationConfig = {
  /** ID of the conversation */
  id: string
  /** Datasets that are applied to the conversation */
  datasetIds: string[]
  /** Datasets that are selected but not yet applied */
  transientDatasetIds: string[]
  /** Description of the conversation */
  description?: string
  /** Messages in the conversation */
  messages: OhmAiMessage[]
  /** Title of the conversation */
  title?: string
}

export const conversationAtomFamily = atomFamily(
  ({ id, ...rest }: Partial<ConversationConfig>) =>
    atom({
      id: id ?? nanoid(),
      datasetIds: [],
      transientDatasetIds: [],
      messages: [],
      title: 'Untitled Conversation',
      ...rest
    } as ConversationConfig),
  idEquality
)

export const conversationMessageAtom = atomFamily((conversationId: string) =>
  atom(
    get => {
      const conversation = get(conversationAtomFamily({ id: conversationId }))
      return conversation?.messages ?? []
    },
    (get, set, messages: MaybeArray<OhmAiMessage>) => {
      const conversation = get(conversationAtomFamily({ id: conversationId }))
      if (conversation == null) return []

      set(conversationAtomFamily({ id: conversationId }), {
        ...conversation,
        messages: [...(conversation.messages ?? []), ...castArray(messages)]
      })
    }
  )
)
