import { useQuery } from '@apollo/client'
import { Contents } from '@jupyterlab/services'
import { ListProps } from 'antd'
import { isEmpty, sortBy } from 'lodash-es'
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { SpotlightSearch } from '@/components'
import { useSession } from '@/hooks'
import { Pagination } from '@/types'

import {
  JupyterBridgeId,
  JupyterBridgeMessageEvent,
  JupyterBridgeMessageType,
  ListNotebooksInPathPayload,
  OHM_GENERATED_NOTEBOOK_PATH
} from '../constants'
import { useMessageBridge } from '../hooks/useMessageBridge'
import { useTranslator } from '../i18n'
import { GET_SHARED_NOTEBOOKS } from '../queries/notebooks'
import { OhmSpotlightSearchItem } from './OhmSpotlightSearchItem'

type OhmNotebookSpotlightProps = Omit<
  ComponentProps<typeof SpotlightSearch>,
  'onSelectItem'
> & {
  onSelectItem: (item: Contents.IModel) => void
  iframeRef: React.RefObject<HTMLIFrameElement>
  isInitialized: boolean
}
export function OhmNotebookSpotlight(props: OhmNotebookSpotlightProps) {
  const { onSelectItem, iframeRef, isInitialized, isOpen, ...rest } = props

  const { translate } = useTranslator()
  const sessionIdentity = useSession()
  const { postListNotebooksInPath } = useMessageBridge(iframeRef)
  const { workspaceId, organizationId } = sessionIdentity

  const workspaceIds = useMemo(() => {
    if (workspaceId == null) return []
    return [workspaceId]
  }, [workspaceId])

  const [ohmSavedNotebooks, setOhmSavedNotebooks] = useState<Contents.IModel[]>(
    []
  )
  const [paginationModel] = useState<Pagination>({ page: 0, pageSize: 200 })

  const queryVariables = useMemo(
    () => ({
      organization_id: organizationId as string,
      page: paginationModel.page + 1,
      // The backend indexes from 1
      page_size: paginationModel.pageSize,
      workspace_ids: workspaceIds
    }),
    [organizationId, workspaceIds, paginationModel]
  )
  const { data: notebooksData } = useQuery(GET_SHARED_NOTEBOOKS, {
    fetchPolicy: 'network-only',
    skip: !organizationId || !workspaceId || isEmpty(workspaceIds),
    variables: queryVariables
  })

  const onMessageReceived = useCallback(
    (event: JupyterBridgeMessageEvent) => {
      const { source, type, payload } = event.data
      if (source !== JupyterBridgeId) return

      if (type === JupyterBridgeMessageType.Initialized) {
        postListNotebooksInPath(OHM_GENERATED_NOTEBOOK_PATH)
      }

      if (type === JupyterBridgeMessageType.ListNotebooksInPath) {
        const { notebooks } = payload as ListNotebooksInPathPayload
        setOhmSavedNotebooks(notebooks ?? [])
      }
    },
    [postListNotebooksInPath]
  )

  const sharedNotebooks = useMemo(() => {
    const gqlNotebookData = notebooksData?.get_shared_notebooks.data ?? []
    return gqlNotebookData.map(
      notebook => JSON.parse(notebook.ipynb_json) as Contents.IModel
    )
  }, [notebooksData?.get_shared_notebooks.data])

  useEffect(() => {
    if (!isInitialized || !isOpen) return
    postListNotebooksInPath(OHM_GENERATED_NOTEBOOK_PATH)
  }, [postListNotebooksInPath, isInitialized, isOpen])

  useEffect(() => {
    window.addEventListener('message', onMessageReceived)
    return () => {
      window.removeEventListener('message', onMessageReceived)
    }
  }, [onMessageReceived])

  const totalNotebooks = sharedNotebooks.length + ohmSavedNotebooks.length

  const notebooks = useMemo(
    () => sortBy(sharedNotebooks.concat(ohmSavedNotebooks), 'name'),
    [sharedNotebooks, ohmSavedNotebooks]
  )

  return (
    <SpotlightSearch
      {...rest}
      inputPlaceholder={translate('spotlightSearch.inputPlaceholder')}
      isOpen={isOpen}
      listProps={
        {
          className: 'border-none',
          renderItem: (item, index) => (
            <OhmSpotlightSearchItem
              isLastItem={index === totalNotebooks - 1}
              item={item}
              onSelectItem={() => onSelectItem(item)}
            />
          )
        } as ListProps<Contents.IModel>
      }
      loading={!isInitialized}
      resultsSorter={'file_name'}
      searchItems={notebooks}
      searchOptions={{
        includeScore: true,
        keys: ['name', 'content.metadata']
      }}
    />
  )
}
