import {
  IDisplayDataMsg,
  IErrorMsg,
  IExecuteReplyMsg,
  IExecuteResultMsg,
  IStatusMsg,
  IStreamMsg
} from '@jupyterlab/services/lib/kernel/messages'

// Define the native enums
export enum ExecutionStateEnum {
  Busy = 'busy',
  Idle = 'idle',
  Starting = 'starting'
}

export const IdleMessage: Partial<IStatusMsg> = {
  content: { execution_state: ExecutionStateEnum.Idle }
}

export const StartingMessage: Partial<IStatusMsg> = {
  content: { execution_state: ExecutionStateEnum.Starting }
}

export const BusyMessage: Partial<IStatusMsg> = {
  content: { execution_state: ExecutionStateEnum.Busy }
}

export const SimpleJupyterStatusMessage = {
  busy: BusyMessage,
  idle: IdleMessage,
  starting: StartingMessage
}

export type JupyterMessage =
  | IExecuteReplyMsg
  | IDisplayDataMsg
  | IErrorMsg
  | IExecuteResultMsg
  | IStreamMsg[]
