import { useQuery } from '@apollo/client'
import { ReactNode, useMemo } from 'react'

import {
  GET_COLUMNS_FOR_INSIGHTS_DASHBOARD,
  GET_DATASETS_FOR_INSIGHTS_DASHBOARD
} from '@/datasets/queries/get_datasets_for_insights_dashboard'
import { Filter_Logic_Operator, Filter_Type } from '@/gql_generated/graphql'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'

import { ChartConfig } from '../reports/types'
import InsightsChart from './InsightsChart'

type InsightsChartStatelessProps = {
  chartId?: string
  workspaceId?: string
  organizationId?: string
  datasetIds: string[]
  config: ChartConfig
  title?: ReactNode
  subTitle?: ReactNode
  showFilterControls?: boolean
}
export function InsightsChartStateless(props: InsightsChartStatelessProps) {
  const {
    chartId,
    datasetIds,
    config,
    title,
    subTitle,
    showFilterControls = true
  } = props

  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const { data: datasetData } = useQuery(GET_DATASETS_FOR_INSIGHTS_DASHBOARD, {
    variables: {
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string],
      page_size: 100,
      page_from: 0,
      properties_sort: [],
      properties_filter: {
        logic_operator: Filter_Logic_Operator.Or,
        filters:
          datasetIds?.map(id => ({
            key: 'experiment_id',
            value: id,
            filter_type: Filter_Type.StringEquals
          })) ?? []
      }
    },
    skip: !organization_id || !workspace_id || !datasetIds
  })

  const { data: columnData } = useQuery(GET_COLUMNS_FOR_INSIGHTS_DASHBOARD, {
    variables: {
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string]
    },
    skip: !organization_id || !workspace_id
  })

  const validDatasets = useMemo(
    () =>
      (datasetData?.get_datasets_list.rows ?? []).filter(
        row => typeof row.id === 'string'
      ) || [],
    [datasetData]
  )
  const datasetColumns = columnData?.get_datasets_list.columns ?? []

  if (config == null) return null
  return (
    <InsightsChart
      reportMode
      chartId={chartId}
      datasetColumns={datasetColumns}
      datasetIds={datasetIds}
      datasets={validDatasets}
      showFilterControls={showFilterControls}
      state={config}
      subTitle={subTitle}
      title={title}
    />
  )
}
