import { ButtonProps, Tooltip, TooltipProps } from 'antd'
import { useMemo } from 'react'

import { Button } from '@/components'
import { cn } from '@/utils'

type OhmButtonProps = {
  buttonProps: ButtonProps
  tooltipProps: TooltipProps
}
export const OhmButton = (props: OhmButtonProps) => {
  const { buttonProps, tooltipProps } = props

  const renderedButton = useMemo(() => {
    const { className, ...btnProps } = buttonProps
    return (
      <Button
        className={cn(
          'border-blue-300 text-blue-500 hover:text-blue-400',
          className
        )}
        color='primary'
        shape='circle'
        size='small'
        variant='filled'
        {...btnProps}
      />
    )
  }, [buttonProps])

  if (tooltipProps != null) {
    return <Tooltip {...tooltipProps}>{renderedButton}</Tooltip>
  }

  return renderedButton
}
