import { Checkmark, TextClearFormat, TrashCan, Undo } from '@carbon/icons-react'
import { useAtom } from 'jotai'
import { isEmpty, partition } from 'lodash-es'

import { DatasetTag } from './components/DatasetTag'
import { OhmButton } from './components/OhmButton'
import { useTranslator } from './i18n'
import { conversationAtomFamily } from './molecule/ohm.molecule'

type SelectedDatasetsProps = {
  conversationId: string
  onClearDataset: (datasetId: string) => void
  onClearAllDatasets: () => void
  onApplyDatasets: (datasetIds: string[]) => void
}
export const SelectedDatasets = (props: SelectedDatasetsProps) => {
  const {
    conversationId,
    onClearDataset,
    onClearAllDatasets,
    onApplyDatasets
  } = props

  const { translate } = useTranslator()

  const [conversation, setConversation] = useAtom(
    conversationAtomFamily({ id: conversationId })
  )
  const { datasetIds, transientDatasetIds = [] } = conversation

  // Unapplied will be the datasets that are selected but not yet applied (not in datasetIds)
  const [unappliedDatasetIds, appliedDatasetIds] = partition(
    transientDatasetIds,
    datasetId => !datasetIds.includes(datasetId)
  )
  // Removed will be the datasets that were applied but are no longer selected in the transient state
  const removedDatasetIds = datasetIds.filter(
    datasetId => !transientDatasetIds.includes(datasetId)
  )

  const clearAllDatasets = () => {
    setConversation(prev => ({
      ...prev,
      datasetIds: [],
      transientDatasetIds: []
    }))
    onClearAllDatasets()
  }

  const clearAppliedDatasets = () => {
    setConversation(prev => ({
      ...prev,
      datasetIds: [],
      transientDatasetIds: unappliedDatasetIds
    }))
    onClearAllDatasets()
  }

  const makeOnRestoreDataset = (datasetId: string) => () => {
    setConversation(prev => ({
      ...prev,
      transientDatasetIds: [...prev.transientDatasetIds, datasetId]
    }))
  }

  const makeOnClearDataset = (datasetId: string) => () => {
    setConversation(prev => ({
      ...prev,
      datasetIds: prev.datasetIds.filter(id => id !== datasetId),
      transientDatasetIds: prev.transientDatasetIds.filter(
        id => id !== datasetId
      )
    }))
    onClearDataset(datasetId)
  }

  const applyDatasets = () => {
    setConversation(prev => ({
      ...prev,
      datasetIds: prev.transientDatasetIds
    }))
    onApplyDatasets(transientDatasetIds)
  }

  return (
    <div className='flex flex-1 flex-row gap-x-2 overflow-hidden'>
      <div className='self-center whitespace-nowrap'>
        {!isEmpty(appliedDatasetIds) && (
          <div className='text-xxs font-semibold leading-3'>
            {translate('nAppliedDatasets', { count: appliedDatasetIds.length })}
          </div>
        )}
        {!isEmpty(unappliedDatasetIds) && (
          <div className='text-xxs leading-none'>
            <mark>
              {translate('nUnappliedDatasets', {
                count: unappliedDatasetIds.length
              })}
            </mark>
          </div>
        )}
      </div>
      <div className='flex gap-1 overflow-x-auto pb-0.5 scrollbar-thin scrollbar-thumb-gray-300'>
        {appliedDatasetIds.map(datasetId => (
          <DatasetTag
            key={datasetId}
            closeIcon
            onClose={makeOnClearDataset(datasetId)}
            text={datasetId}
          />
        ))}
        {removedDatasetIds.map(datasetId => (
          <DatasetTag
            key={datasetId}
            className='border-dashed border-red-300 bg-transparent text-red-400'
            closeIcon={<Undo className='!text-red-400' size={14} />}
            onClose={makeOnRestoreDataset(datasetId)}
            text={datasetId}
            tooltipText={translate('xRemoved', { x: datasetId })}
          />
        ))}
        {unappliedDatasetIds.map(datasetId => (
          <DatasetTag
            key={datasetId}
            className='border-dashed border-gray-400 bg-transparent text-gray-500'
            text={datasetId}
            tooltipText={translate('xUnapplied', { x: datasetId })}
          />
        ))}
      </div>
      <div className='flex gap-1'>
        {appliedDatasetIds.length > 0 && unappliedDatasetIds.length > 0 && (
          <OhmButton
            buttonProps={{
              className:
                'text-gray-600 !bg-white border-gray-300 hover:!bg-gray-100 hover:text-gray-700',
              icon: <TextClearFormat size={14} />,
              onClick: clearAppliedDatasets,
              shape: 'circle',
              size: 'small'
            }}
            tooltipProps={{
              title: translate('clearAppliedDatasets', {
                count: appliedDatasetIds.length
              })
            }}
          />
        )}
        {transientDatasetIds.length > 0 && (
          <OhmButton
            buttonProps={{
              className:
                'hover:!text-red-600 text-red-500 !bg-red-100 border-red-300 hover:!bg-red-200',
              icon: <TrashCan size={14} />,
              onClick: clearAllDatasets,
              shape: 'circle',
              size: 'small'
            }}
            tooltipProps={{
              title: translate('clearAllDatasets', {
                suffix:
                  unappliedDatasetIds.length > 0
                    ? translate('clearAllDatasetsSuffix')
                    : ''
              })
            }}
          />
        )}
        {(unappliedDatasetIds.length > 0 || removedDatasetIds.length > 0) && (
          <OhmButton
            buttonProps={{
              icon: <Checkmark size={14} />,
              onClick: applyDatasets,
              shape: 'circle',
              size: 'small'
            }}
            tooltipProps={{
              title: translate('applyDatasets', {
                count: unappliedDatasetIds.length
              })
            }}
          />
        )}
      </div>
    </div>
  )
}
