import { Navigate } from 'react-router-dom'

import { use_workspace_and_org_ids } from '../hooks/use_workspace_and_org_ids'

interface RoleGuardProps {
  children: React.ReactNode
  permittedRoles: string[]
  redirectTo?: string
}

export const RoleGuard = ({
  children,
  permittedRoles,
  redirectTo = '/'
}: RoleGuardProps) => {
  const { workspace_role } = use_workspace_and_org_ids()

  if (!permittedRoles.includes(workspace_role)) {
    return <Navigate replace to={redirectTo} />
  }

  return <>{children}</>
}
