import { useMutation } from '@apollo/client'
import {
  ArrowShiftDown,
  ArrowUp,
  Fire,
  NotebookReference,
  TableShortcut
} from '@carbon/icons-react'
import { INotebookContent, isCode } from '@jupyterlab/nbformat'
import { Contents } from '@jupyterlab/services'
import {
  IErrorMsg,
  IStatusMsg,
  IStreamMsg,
  isDisplayDataMsg,
  isErrorMsg,
  isExecuteInputMsg,
  isExecuteReplyMsg,
  isExecuteResultMsg,
  isStatusMsg,
  isStreamMsg
} from '@jupyterlab/services/lib/kernel/messages'
import { useAtom } from 'jotai'
import 'keyboard-css'
import { cloneDeep, debounce, groupBy, isEmpty, isEqual, size } from 'lodash-es'
import { nanoid } from 'nanoid'
import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router'
import scrollIntoView from 'scroll-into-view-if-needed'
import { useBoolean } from 'usehooks-ts'
import { v4 as uuidv4 } from 'uuid'

import {
  BaseLayout,
  Button,
  Drawer,
  FloatButton,
  Input,
  Popover,
  Spin,
  Tooltip,
  notification
} from '@/components'
import { useSession, useWindowState } from '@/hooks'
import { select_access_token } from '@/user/user_slice'
import { cn } from '@/utils'

import useLogger from '../hooks/useLogger'
import { SimpleJupyterStatusMessage } from './MessageParser'
import { SaveAsNotebook } from './SaveAsNotebook'
import { SelectedDatasets } from './SelectedDatasets'
import {
  DatasetTag,
  OhmHotkeys,
  OhmNotebookSpotlight,
  OhmPromptFeed,
  OhmQuickPrompts,
  OhmStatus,
  OhmThinking,
  OhmWelcome
} from './components'
import {
  JupyterBridgeId,
  JupyterBridgeMessageEvent,
  JupyterBridgeMessageType,
  JupyterBridgeOperationState,
  OHM_GENERATED_NOTEBOOK_PATH,
  SaveNotebookResultPayload,
  getPythonCodeBlock
} from './constants'
import { useMessageBridge, useMessageFoundry } from './hooks'
import { useTranslator } from './i18n'
import {
  conversationAtomFamily,
  conversationMessageAtom
} from './molecule/ohm.molecule'
import { OHM_AI_CHAT_COMPLETION_V2 } from './queries/ohmAi'
import { OhmAiMessage, OhmAiMessageType, OhmAiResponseType } from './types'
import { CodeFoundry } from './utils'

const LazyTable = lazy(() =>
  import('./components/DatasetTable').then(({ DatasetTable }) => ({
    default: DatasetTable
  }))
)

const _passthroughParams = ['debug', 'verbose']
let _stream_messages: { [promptId: string]: IStreamMsg[] } = {}

export const OhmAI = () => {
  const [conversationId] = useState(uuidv4())
  const messageFoundry = useMessageFoundry()

  const [notificationApi, notificationContextHolder] =
    notification.useNotification()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const anchorRef = useRef<HTMLDivElement>(null)
  const accessToken = useSelector(select_access_token)
  const logger = useLogger()
  const messageBridge = useMessageBridge(iframeRef, conversationId)

  const { translate } = useTranslator()

  const { isMainWindow, wasEverMainWindow } = useWindowState()
  const isEnabled = isMainWindow || wasEverMainWindow

  const [jupyterStatus, setJupyterStatus] = useState<Partial<IStatusMsg>>(
    SimpleJupyterStatusMessage.starting
  )
  const [selectedNotebook, setSelectedNotebook] = useState<Contents.IModel>()
  const [activePrompts, setActivePrompts] = useState<Record<string, boolean>>(
    {}
  )
  const {
    value: notebookDrawerOpen,
    setTrue: showNotebookDrawer,
    setFalse: hideNotebookDrawer
  } = useBoolean(false)

  const {
    value: spotlightOpen,
    setTrue: showSpotlight,
    setFalse: hideSpotlight,
    setValue: setSpotlightOpen
  } = useBoolean(false)
  const {
    value: datasetDrawerOpen,
    setTrue: showDatasetsDrawer,
    // setFalse: hideDatasetsDrawer,
    toggle: toggleDatasetsDrawer
  } = useBoolean(false)
  const {
    value: jumpToBottomVisible,
    setTrue: showJumpToBottom,
    setFalse: hideJumpToBottom
  } = useBoolean(false)

  const { value: hotkeyHelperVisible, toggle: toggleHotkeysHelper } =
    useBoolean(false)

  const promptId = useRef(nanoid())
  const sessionIdentity = useSession()
  const { organizationId, workspaceId, workspaceApiKey } = sessionIdentity

  const [conversation, setConversation] = useAtom(
    conversationAtomFamily({
      id: conversationId,
      messages: [
        {
          content: <OhmWelcome />,
          promptId: nanoid(),
          title: '',
          type: OhmAiMessageType.OhmTextResponse
        }
      ]
    })
  )
  const [ohmAiMessages, pushOhmAiMessages] = useAtom(
    conversationMessageAtom(conversationId)
  )

  const { datasetIds } = conversation
  const prevDatasetIds = useRef(datasetIds)

  const selectedDatasetCount = datasetIds.length

  const ohmAiMessagesContainerRef = useRef<HTMLDivElement>(null)

  const [searchParams] = useSearchParams()
  const passThroughParams = useMemo(() => {
    const params = new URLSearchParams()
    _passthroughParams.forEach(param => {
      if (searchParams.has(param)) {
        params.append(param, searchParams.get(param)!)
      }
    })
    return params.toString()
  }, [searchParams])

  const [isInitialized, setIsInitialized] = useState(false)
  const [prompt, setPrompt] = useState('')

  const [chatCompletionV2Mutation] = useMutation(OHM_AI_CHAT_COMPLETION_V2)

  const flushStreamMessages = useCallback(
    (promptId: string) => {
      const messages = cloneDeep(_stream_messages)
      const messagesForPrompt = messages[promptId] ?? []
      logger.verbose(
        'flushing stream messages for promptId:',
        promptId,
        messagesForPrompt
      )

      if (messagesForPrompt.length > 0) {
        pushOhmAiMessages({
          content: messagesForPrompt,
          promptId,
          type: OhmAiMessageType.JupyterStdOut
        })
      }
      _stream_messages = {
        ...messages,
        [promptId]: []
      }
    },
    [logger, pushOhmAiMessages]
  )

  const setPromptStatus = useCallback(
    ({ promptId, loading }: { promptId?: string; loading: boolean }) => {
      setActivePrompts(prev => {
        if (promptId == null) return {}

        return { ...prev, [promptId]: loading }
      })
    },
    []
  )

  const handleIOMessage = useCallback(
    (payload: any, operationId?: string) => {
      logger.debug('handling io message', payload, operationId)
      const assumedPromptId = operationId ?? promptId.current

      if (
        !isStreamMsg(payload) &&
        !isStatusMsg(payload) &&
        !isExecuteInputMsg(payload)
      ) {
        logger.verbose('Not a stream message:', payload)
        flushStreamMessages(assumedPromptId)
      }

      if (isExecuteInputMsg(payload)) {
        // We will clear the latest stream messages when we receive an execute input message,
        // as this is the start of a new code execution.
        _stream_messages = {
          ..._stream_messages,
          [assumedPromptId]: []
        }
        return
      }
      if (isErrorMsg(payload)) {
        // Clear active prompts
        setPromptStatus({ loading: false, promptId: assumedPromptId })
        pushOhmAiMessages({
          content: payload,
          promptId: assumedPromptId,
          type: OhmAiMessageType.JupyterError
        })
        return
      } else if (isStatusMsg(payload)) {
        setJupyterStatus(payload)
        return
      } else if (isDisplayDataMsg(payload)) {
        logger.verbose('Display data message:', payload)
        pushOhmAiMessages({
          content: payload,
          promptId: assumedPromptId,
          type: OhmAiMessageType.JupyterVisualization
        })
      }
      // In the case of a stream message, we will store the latest stream messages,
      // and flush them when we receive an execute result message.
      else if (isStreamMsg(payload)) {
        logger.verbose('handling stream message', payload)
        if (payload.content.name === 'stdout') {
          const messagesForPrompt = _stream_messages[assumedPromptId] ?? []
          _stream_messages = {
            ..._stream_messages,
            [assumedPromptId]: [...messagesForPrompt, payload]
          }
        }
      } else if (
        isExecuteReplyMsg(payload) &&
        payload.content.status === 'ok'
      ) {
        logger.verbose('handling execute reply message', payload)

        setPromptStatus({ loading: false, promptId: assumedPromptId })
      } else if (isExecuteResultMsg(payload)) {
        pushOhmAiMessages({
          content: payload,
          promptId: assumedPromptId,
          type: OhmAiMessageType.JupyterResult
        })
        setPromptStatus({ loading: false, promptId: assumedPromptId })
      } else {
        logger.verbose('unknown message', payload)
      }
    },
    [flushStreamMessages, logger, setPromptStatus, pushOhmAiMessages]
  )

  const onMessageReceived = useCallback(
    (event: JupyterBridgeMessageEvent) => {
      const { source, type, payload, operationId } = event.data
      if (source !== JupyterBridgeId) return

      if (type !== JupyterBridgeMessageType.Ack) {
        logger.debug('Message received in the parent:', event.data)
      }

      switch (type) {
        case JupyterBridgeMessageType.Error:
          logger.error('JupyterLite has an error:', payload)
          notificationApi.error({
            description: payload as string,
            message: translate('jupyterLiteError')
          })
          break
        case JupyterBridgeMessageType.IOMessage:
          handleIOMessage(payload, operationId)
          break
        case JupyterBridgeMessageType.Initialized:
          logger.verbose('JupyterLite is initialized. Setting flag.')
          setIsInitialized(true)
          break
        case JupyterBridgeMessageType.Ready:
          logger.verbose('JupyterLite is ready. Setting flag.')
          setJupyterStatus(SimpleJupyterStatusMessage.idle)
          break
        case JupyterBridgeMessageType.Operation:
          logger.verbose('Operation message received:', payload)
          if (payload === JupyterBridgeOperationState.Complete) {
            setPromptStatus({ loading: false, promptId: operationId })
          }
          break
        case JupyterBridgeMessageType.SaveNotebookToPath:
          const typedPayload = payload as SaveNotebookResultPayload

          if (typedPayload.success) {
            notificationApi.success({
              description: translate('saveAsNotebook.savedToPath', {
                path: typedPayload.finalPath
              }),
              message: translate('saveAsNotebook.saveSuccessful')
            })
          } else {
            notificationApi.error({
              description: typedPayload.message,
              message: translate('saveAsNotebook.saveFailure')
            })
          }
          break
        case JupyterBridgeMessageType.ListNotebooksInPath:
        case JupyterBridgeMessageType.NotebookOpened:
        case JupyterBridgeMessageType.NotebookClosed:
        case JupyterBridgeMessageType.Ack:
          break
        default:
          logger.warn('Unknown message type:', type)
      }

      if (type !== JupyterBridgeMessageType.Ack) {
        messageBridge.postAck()
      }
    },
    [
      logger,
      notificationApi,
      translate,
      handleIOMessage,
      setPromptStatus,
      messageBridge
    ]
  )

  useEffect(() => {
    window.addEventListener('message', onMessageReceived)
    return () => {
      window.removeEventListener('message', onMessageReceived)
    }
  }, [onMessageReceived])

  useEffect(() => {
    if (!isInitialized) return

    messageBridge.postIdentify()
    const intervalId = setInterval(() => {
      messageBridge.postIdentify()
    }, 5000)

    return () => clearInterval(intervalId)
  }, [isInitialized, messageBridge])

  useLayoutEffect(() => {
    setTimeout(() => {
      if (anchorRef.current) {
        scrollIntoView(anchorRef.current, {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
      }
    }, 0)
  }, [ohmAiMessages.length])

  const extractChatHistory = useCallback(() => {
    return ohmAiMessages
      .filter(
        msg =>
          ![
            OhmAiMessageType.JupyterVisualization,
            OhmAiMessageType.OhmThinking
          ].includes(msg.type)
      )
      .map(msg => {
        switch (msg.type) {
          case OhmAiMessageType.JupyterStdOut:
            const stdOut = msg.content as IStreamMsg[]
            return {
              message_contents: stdOut
                .map(stream => stream.content.text)
                .join(''),
              message_type: msg.type
            }
          case OhmAiMessageType.JupyterError:
            const errMsg = msg.content as IErrorMsg
            const stdErr = errMsg.content
            return {
              message_contents: stdErr.evalue,
              message_type: msg.type
            }
          case OhmAiMessageType.OhmTextResponse:
          case OhmAiMessageType.OhmPythonCode:
          case OhmAiMessageType.OhmJupyterCell:
            return {
              message_contents: msg.content?.toString() ?? '',
              message_type: msg.type
            }

          default:
            return {
              message_contents: msg.toString() ?? '',
              message_type: msg.type
            }
        }
      })
  }, [ohmAiMessages])

  const handleAskQuestion = async () => {
    const newPromptId = nanoid()
    promptId.current = newPromptId

    setPrompt('')
    setPromptStatus({ loading: true, promptId: newPromptId })
    pushOhmAiMessages([
      {
        content: prompt,
        promptId: newPromptId,
        toString: () => prompt,
        type: OhmAiMessageType.UserMessage
      },
      {
        content: <OhmThinking />,
        processing: true,
        promptId: newPromptId,
        type: OhmAiMessageType.OhmThinking
      }
    ])

    const chatHistory = extractChatHistory()

    const data = await chatCompletionV2Mutation({
      variables: {
        chat_history: chatHistory,
        conversation_id: conversationId,
        dataset_keys: datasetIds,
        organization_id: organizationId as string,
        workspace_ids: [workspaceId as string],
        prompt
      }
    })

    const chatCompletionReponse = data.data?.chat_completion_v2
    if (chatCompletionReponse == null) return

    // Extract the code blocks from the chat completion response
    const codeBlocks = chatCompletionReponse
      .filter(
        item =>
          item.type === OhmAiResponseType.PythonCode ||
          item.type === OhmAiResponseType.JupyterCell
      )
      .map(item => item.response)
      .join('\n')

    // Create new messages for each response item
    const newMessages: OhmAiMessage[] = chatCompletionReponse.map(
      ({ type, response }) => {
        if (type === OhmAiResponseType.Text) {
          return {
            content: response,
            promptId: newPromptId,
            title: '',
            type: OhmAiMessageType.OhmTextResponse
          }
        } else if (type === OhmAiResponseType.PythonCode) {
          return {
            content: response,
            promptId: newPromptId,
            type: OhmAiMessageType.OhmPythonCode
          }
        } else if (type === OhmAiResponseType.JupyterCell) {
          return {
            content: response,
            promptId: newPromptId,
            type: OhmAiMessageType.OhmJupyterCell
          }
        } else {
          // Handle any other response types
          return {
            content: (
              <pre className='whitespace-pre-wrap font-sans'>{response}</pre>
            ),
            promptId: newPromptId,
            title: '',
            type: OhmAiMessageType.OhmTextResponse
          }
        }
      }
    )

    setConversation(prev => {
      const updatedMessages = prev.messages.map(msg =>
        msg.type === OhmAiMessageType.OhmThinking &&
        msg.promptId === newPromptId
          ? newMessages[0] // Replace the thinking message with the first new message
          : msg
      )

      // Add the rest of the new messages (if any)
      if (newMessages.length > 1) {
        updatedMessages.push(...newMessages.slice(1))
      }

      return {
        ...prev,
        messages: updatedMessages
      }
    })

    // Set prompt status to not loading if there's no code to execute
    if (codeBlocks.length === 0) {
      setPromptStatus({ loading: false, promptId: newPromptId })
    }

    // Execute code blocks if there are any
    if (codeBlocks.length > 0) {
      messageBridge.postExecuteTransientCode(
        getPythonCodeBlock(workspaceApiKey ?? '', codeBlocks),
        newPromptId
      )
    }
  }

  const messagesByPrompt = useMemo(
    () => groupBy(ohmAiMessages, 'promptId'),
    [ohmAiMessages]
  )

  const onSelectNotebook = (notebook: Contents.IModel) => {
    const newPromptId = nanoid()
    promptId.current = newPromptId

    const notebookContent = notebook.content as INotebookContent
    setSelectedNotebook(notebook)
    pushOhmAiMessages(
      messageFoundry.makeSelectNotebookMessage({
        cells: notebookContent.cells,
        fileName: notebook.name,
        promptId: newPromptId
      })
    )
    hideSpotlight()

    if (selectedDatasetCount < 1) {
      showDatasetsDrawer()
      notificationApi.info({
        description: translate('pleaseSelectDatasets'),
        message: translate('noDatasetsSelected')
      })
    } else {
      setPromptStatus({ loading: true, promptId: newPromptId })
    }
  }

  const executeDatasetChange = (newDatasetIds: string[], promptId: string) => {
    // If the datasets haven't changed, or if the datasets are empty, return
    if (
      (isEmpty(prevDatasetIds.current) && isEmpty(newDatasetIds)) ||
      isEqual(prevDatasetIds.current, newDatasetIds)
    ) {
      return
    }
    messageBridge.postExecuteTransientCode(
      CodeFoundry.makeSelectDatasets(newDatasetIds),
      promptId
    )

    prevDatasetIds.current = newDatasetIds
  }

  const onApplyDatasets = (newDatasetIds: string[]) => {
    // hideDatasetsDrawer()
    if (isEqual(newDatasetIds, prevDatasetIds.current)) return
    const newPromptId = nanoid()
    promptId.current = newPromptId

    setPromptStatus({ loading: true, promptId: newPromptId })
    executeDatasetChange(newDatasetIds, newPromptId)
    pushOhmAiMessages(
      messageFoundry.makeSelectDatasetMessage({
        content: isEmpty(newDatasetIds)
          ? translate('noDatasetsSelected')
          : undefined,
        datasetIds: newDatasetIds,
        promptId: newPromptId
      })
    )
  }

  const onClearDataset = (datasetId: string) => {
    const newPromptId = nanoid()
    promptId.current = newPromptId
    const newDatasetIds = datasetIds.filter(id => id !== datasetId)

    setPromptStatus({ loading: true, promptId: newPromptId })
    executeDatasetChange(newDatasetIds, newPromptId)
    pushOhmAiMessages(
      messageFoundry.makeSelectDatasetMessage({
        content: (
          <>
            {translate('clearDatasets', { count: 1 })}:{' '}
            <DatasetTag text={datasetId} />
          </>
        ),
        datasetIds: newDatasetIds,
        promptId: newPromptId
      })
    )
  }

  const clearAllDatasets = () => {
    const newPromptId = nanoid()
    promptId.current = newPromptId

    const newDatasetIds: string[] = []
    setPromptStatus({ loading: true, promptId: newPromptId })
    executeDatasetChange(newDatasetIds, newPromptId)
    pushOhmAiMessages(
      messageFoundry.makeSelectDatasetMessage({
        content: translate('deselectAllDatasets'),
        datasetIds: newDatasetIds,
        promptId: newPromptId
      })
    )
  }

  /**
   * Reset all state and clear the conversation.
   * We keep the datasets selected, but clear the conversation messages.
   * (This is intentional, as it's likely a user wants a new analysis of the same data)
   */
  const resetAll = () => {
    setActivePrompts({})
    setSelectedNotebook(undefined)
    setConversation(prev => ({
      ...prev,
      messages: []
    }))
  }

  // If the user has selected a notebook and at least one dataset, send the code to the iframe
  // but only if the selected notebook has changed
  useEffect(() => {
    if (!isEnabled) return
    if (selectedNotebook == null || selectedDatasetCount === 0) return

    const selectedNotebookName = selectedNotebook?.name.replace(
      '.shared.ipynb',
      ''
    )

    const content = selectedNotebook.content as INotebookContent
    const codeCells = content.cells.filter(
      cell => isCode(cell) && cell.source.toString().trim().length > 0
    )

    messageBridge.postExecuteTransientCode(
      `${
        selectedDatasetCount > 0
          ? `print("${translate('selectedNotebookExecuting')}: ${selectedNotebookName}")\n`
          : `print("${translate('selectedNotebookNotExecutingNoDatasets', {
              selectedNotebookName
            })}")\n`
      }${codeCells.map(cell => cell.source.toString()).join('\n')}
      `,
      promptId.current
    )

    setSelectedNotebook(undefined)
  }, [
    isEnabled,
    selectedDatasetCount,
    selectedNotebook,
    messageBridge,
    translate
  ])

  useHotkeys('meta+Backspace, ctrl+Backspace', resetAll, {
    enableOnFormTags: true,
    enabled: isEnabled,
    preventDefault: true
  })
  useHotkeys('meta+b, ctrl+b', toggleDatasetsDrawer, {
    enableOnFormTags: true,
    enabled: isEnabled,
    preventDefault: true
  })
  useHotkeys('meta+shift+k, ctrl+shift+k', toggleHotkeysHelper, {
    enableOnFormTags: true,
    preventDefault: true
  })
  useHotkeys('meta+/, ctrl+/', showNotebookDrawer, {
    enableOnFormTags: true,
    enabled: isEnabled,
    preventDefault: true
  })

  // Add button for scrolling to bottom when the user scrolls up past
  // the top of the last message in the feed
  useEffect(() => {
    const container = ohmAiMessagesContainerRef.current
    if (container == null) return
    const handleScroll = debounce(
      () => {
        if (container == null) return
        const lastChild = Array.from(
          container.querySelectorAll('[data-prompt-container]')
        ).pop()
        if (lastChild == null) return

        const lastChildTop = lastChild.getBoundingClientRect().top
        const containerBottom = container.getBoundingClientRect().bottom

        if (lastChildTop > containerBottom) {
          showJumpToBottom()
        } else {
          hideJumpToBottom()
        }
      },
      150,
      { maxWait: 500 }
    )

    container.addEventListener('scroll', handleScroll)

    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [showJumpToBottom, hideJumpToBottom])

  if (accessToken == null) return <Spin />
  return (
    <>
      {notificationContextHolder}
      <BaseLayout className='relative top-0 flex flex-1 flex-col overflow-hidden px-0 pt-2.5'>
        <div
          ref={ohmAiMessagesContainerRef}
          className={cn(
            'scrollbar-thumb-rounded-md scrollbar-thin scrollbar-thumb-gray-400',
            'relative flex flex-1 flex-col gap-y-4 overflow-y-auto p-4 !pb-8',
            'box-content' // This is needed to compute the correct heights in OhmPromptFeed
          )}
          id={conversationId}
        >
          {Object.entries(messagesByPrompt).map(
            ([_promptId, messages], idx) => (
              <OhmPromptFeed
                key={_promptId}
                active={activePrompts[_promptId]}
                anchorRef={anchorRef}
                isLastPrompt={idx === size(messagesByPrompt) - 1}
                messages={messages}
                parentContainerRef={ohmAiMessagesContainerRef}
                promptId={_promptId}
              />
            )
          )}
          <div ref={anchorRef} className='h-[1px]' id='anchor'>
            &nbsp;
          </div>
        </div>
        <div
          className={cn(
            'relative -mb-[27rem] min-h-[500px]',
            'flex grow-0 justify-center',
            { 'mb-0': datasetDrawerOpen }
          )}
        >
          {isEnabled && (
            <>
              {jumpToBottomVisible && (
                <Tooltip placement='left' title='Jump to bottom'>
                  <FloatButton
                    className={cn(
                      'group absolute -top-12 right-1/2 translate-x-1/2 transition-[width] [&_.ant-float-btn-content]:!flex-row',
                      'hover:rounded-[40px] hover:bg-blue-300 hover:[&_.ant-float-btn-body]:rounded-[40px]',
                      'shadow-md shadow-slate-500'
                    )}
                    icon={<ArrowShiftDown />}
                    onClick={() => {
                      if (anchorRef.current == null) return
                      scrollIntoView(anchorRef.current, {
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'end'
                      })
                    }}
                  />
                </Tooltip>
              )}
              <Popover
                arrow={{ pointAtCenter: true }}
                content={<OhmHotkeys />}
                open={hotkeyHelperVisible}
                placement='leftBottom'
                title={translate('hotkeys')}
                trigger='click'
              >
                <FloatButton
                  className={cn(
                    'group absolute -top-12 right-3 transition-[width] [&_.ant-float-btn-content]:!flex-row',
                    'h-[40px] rounded-full [&_.ant-float-btn-body]:rounded-full',
                    'hover:w-24 hover:bg-blue-300'
                  )}
                  description={
                    <div className='hidden group-hover:block'>
                      {translate('hotkeys')}
                    </div>
                  }
                  icon={<Fire />}
                  onClick={toggleHotkeysHelper}
                  shape='square'
                />
              </Popover>
            </>
          )}
          <div
            className={cn(
              'flex flex-col gap-y-2 border-t border-gray-200 bg-white p-3',
              'w-[calc(100%-1.5rem)] rounded-t-xl p-3'
            )}
            style={{ boxShadow: '-1px -1px 4px 1px rgba(0, 0, 0, 0.2)' }}
          >
            <div className='flex flex-row flex-nowrap items-center justify-center gap-x-2 text-xs'>
              <div className='whitespace-nowrap'>
                {translate('quickPrompts.title')}:
              </div>
              <div className='flex flex-row flex-nowrap items-center justify-center gap-x-2 overflow-x-auto'>
                <OhmQuickPrompts
                  onPromptClick={(prompt: string) => {
                    setPrompt(prompt)
                    inputRef.current?.focus()
                  }}
                />
              </div>
            </div>
            <div className='flex flex-row flex-nowrap items-start gap-x-2'>
              <Tooltip
                arrow={{ pointAtCenter: true }}
                placement='topLeft'
                title={translate('toggleDatasetFinder')}
              >
                <Button
                  className='border text-xs'
                  color='default'
                  disabled={!isEnabled}
                  onClick={toggleDatasetsDrawer}
                  shape='circle'
                  variant='filled'
                >
                  <TableShortcut />
                </Button>
              </Tooltip>
              <Tooltip
                arrow={{ pointAtCenter: true }}
                placement='topLeft'
                title={translate('selectNotebook')}
              >
                <Button
                  className='border text-xs'
                  color='default'
                  disabled={!isEnabled}
                  onClick={showSpotlight}
                  shape='circle'
                  variant='filled'
                >
                  <NotebookReference />
                </Button>
              </Tooltip>
              <div className='flex flex-1 flex-row justify-stretch gap-x-2'>
                <div className='relative flex flex-1'>
                  <Input.TextArea
                    key='input'
                    ref={inputRef}
                    autoSize={{ maxRows: 3, minRows: 1 }}
                    className='min-h-10 flex-1 py-0 pr-8'
                    classNames={{
                      textarea: 'py-1',
                      wrapper: 'py-0.5'
                    }}
                    disabled={!isEnabled}
                    onChange={e => setPrompt(e.target.value)}
                    onPressEnter={e => {
                      e.preventDefault()
                      handleAskQuestion()
                      inputRef.current?.focus()
                    }}
                    placeholder={translate('ohmAIPromptPlaceholder')}
                    value={prompt}
                  />
                  <Button
                    className='absolute right-1 top-1'
                    icon={<ArrowUp size={14} />}
                    onClick={handleAskQuestion}
                    shape='circle'
                    size='small'
                  />
                </div>
                <div>
                  <SaveAsNotebook
                    conversationId={conversationId}
                    onSave={(model: Contents.IModel) => {
                      const $meta = model.content.metadata
                      const maybeDescription = $meta?.$conversationDescription

                      if (maybeDescription) {
                        const descriptionCell = {
                          cell_type: 'markdown',
                          metadata: {},
                          source: `**${maybeDescription}**`
                        }
                        model.content.cells.unshift(descriptionCell)
                      }
                      messageBridge.postSaveNotebookToPath(model)
                    }}
                    path={OHM_GENERATED_NOTEBOOK_PATH}
                  />
                </div>
                <div className='flex h-8 items-center gap-x-0.5 whitespace-nowrap text-sm font-medium'>
                  {isMainWindow && <OhmStatus jupyterStatus={jupyterStatus} />}
                </div>
              </div>
            </div>
            <div className='flex flex-row flex-nowrap items-end justify-between gap-x-2'>
              <SelectedDatasets
                conversationId={conversationId}
                onApplyDatasets={onApplyDatasets}
                onClearAllDatasets={clearAllDatasets}
                onClearDataset={onClearDataset}
              />
              <OhmNotebookSpotlight
                disabled={!isEnabled}
                iframeRef={iframeRef}
                isInitialized={isInitialized}
                isOpen={spotlightOpen}
                onOpenChange={setSpotlightOpen}
                onSelectItem={onSelectNotebook}
              />
            </div>
            <div className='flex h-[26rem] flex-col'>
              <Suspense fallback={<Spin />}>
                {datasetDrawerOpen && (
                  <LazyTable conversationId={conversationId} />
                )}
              </Suspense>
            </div>
          </div>
        </div>
        {isEnabled ? (
          <Drawer
            forceRender // This is necessary to prevent the iframe from being unmounted
            classNames={{
              body: '!p-0'
            }}
            onClose={hideNotebookDrawer}
            open={notebookDrawerOpen}
            placement='right'
            title={translate('notebooks')}
            width='90vw'
            zIndex={1500}
          >
            <div className='flex h-full flex-1 overflow-hidden'>
              <iframe
                ref={iframeRef}
                className='flex-1'
                name='redspot'
                src={`//byterat-redspot.vercel.app/lab/index.html?transient=true&${passThroughParams}`}
                title='byterat jupyter notebook'
              />
            </div>
          </Drawer>
        ) : (
          <div className='absolute inset-0 z-[1200] flex flex-col items-center justify-center bg-white/30 backdrop-blur-md'>
            <div className='flex flex-1 flex-col items-center justify-center gap-y-2 p-4'>
              <div className='text-lg font-semibold'>
                {translate('multiInstanceUnavailable.headline')}
              </div>
              <div className='flex flex-col items-center justify-center'>
                <pre className='whitespace-break-spaces px-8 text-center font-sans'>
                  {translate('multiInstanceUnavailable.body').replace(
                    /[^\S\r\n]+/g,
                    ' '
                  )}
                </pre>
              </div>
            </div>
          </div>
        )}
      </BaseLayout>
    </>
  )
}
