import { SxProps, TextField, styled } from '@mui/material'
import {
  CSSProperties,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useState
} from 'react'

export default function Numeric_Filter({
  value,
  disabled,
  onBlur,
  placeholder,
  containerSx,
  sx,
  style
}: {
  value?: number
  disabled?: boolean
  onBlur?: (event: FocusEvent<HTMLInputElement>, value: number) => void
  placeholder?: string
  containerSx?: SxProps
  sx?: SxProps
  style?: CSSProperties
}) {
  const preventLetterE = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault()
    }
  }

  const [inputValue, setInputValue] = useState<number | undefined>(value)
  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <Filter_Container sx={containerSx}>
      <TextField
        disabled={disabled}
        id='outlined-number'
        onBlur={(event: FocusEvent<HTMLInputElement>) => {
          onBlur?.(event, Number(event.target.value))
        }}
        onChange={(e: any) => {
          const newValue = e.target.value

          // Allow empty input, but only allow numbers
          // positive or negative
          // also allow decimals
          if (newValue === '' || /^-?\d*\.?\d*$/.test(newValue)) {
            setInputValue(newValue)
          }
        }}
        onKeyDown={preventLetterE}
        placeholder={placeholder || 'Enter a Number'}
        slotProps={{
          inputLabel: {
            shrink:
              inputValue !== undefined && inputValue !== null ? true : undefined
          }
        }}
        style={style}
        sx={sx}
        type='number'
        value={inputValue}
      />
    </Filter_Container>
  )
}

const Filter_Container = styled('div')(
  () => `
  .MuiTextField-root .MuiInputBase-root input.MuiInputBase-input {
    padding-top: 8px;
    padding-bottom: 8px;
    -moz-appearance: textfield;
  }
  .MuiTextField-root .MuiInputBase-root input.MuiInputBase-input::-webkit-inner-spin-button,
  .MuiTextField-root .MuiInputBase-root input.MuiInputBase-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .MuiTextField-root label.MuiFormLabel-root[data-shrink=false] {
    transform: translate(14px, 8px) scale(1)
  }
`
)
