import { useQuery } from '@apollo/client'
import { type INotebookContent } from '@jupyterlab/nbformat'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { useLocalStorage } from 'usehooks-ts'

import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { GET_WORKSPACE_API_KEY } from '@/user/queries/get_workspace_api_key'

export type NotebookInfo = {
  path: string
  metadata: INotebookContent & {
    $exception?: any
    $owner?: string
  }
}
type SessionInfo = {
  notebooks?: {
    lastOpened?: NotebookInfo
  }
}

export type SessionIdentity = {
  organizationId: string | undefined
  organizationKey: string | undefined
  workspaceApiKey: string | undefined
  workspaceId: string | undefined
  workspaceKey: string | undefined
}

export type UseSessionReturnType = SessionIdentity & {
  sessionInfo: SessionInfo
  updateSessionInfo: (value: SessionInfo) => void
}

export const useSession = (): UseSessionReturnType => {
  const { organization_key: organizationKey, workspace_key: workspaceKey } =
    useParams()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [sessionInfo, updateSessionInfo] = useLocalStorage<SessionInfo>(
    workspaceKey ?? '__unknown__',
    {}
  )

  const workspace_ids = useMemo(() => [workspace_id as string], [workspace_id])
  const { data } = useQuery(GET_WORKSPACE_API_KEY, {
    variables: { workspace_ids }
  })
  const workspaceApiKey = data?.get_workspace_api_key

  const sessionIdentity = useMemo(
    () => ({
      organizationId: organization_id,
      organizationKey,
      workspaceApiKey,
      workspaceId: workspace_id,
      workspaceKey,
      sessionInfo,
      updateSessionInfo
    }),
    [
      organization_id,
      organizationKey,
      workspaceApiKey,
      workspace_id,
      workspaceKey,
      sessionInfo,
      updateSessionInfo
    ]
  )

  return sessionIdentity
}
