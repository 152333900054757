import { ChevronDown } from '@carbon/icons-react'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import { Button, Popover, Tooltip } from '@/components'
import { Iris_Report_Template } from '@/gql_generated/graphql'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'

import {
  reportAtomFamily,
  visualizationAtomFamily
} from '../../store/report.molecule'
import { FullReportAtom, Visualization } from '../../types'
import { VirtualizedReportTemplateList } from './VirtualizedReportTemplateList'

export const CreateReportFromTemplate = () => {
  const navigate = useNavigate()
  const { organization_id: organizationId, workspace_id: workspaceId } =
    use_workspace_and_org_ids()

  const [popoverOpen, setPopoverOpen] = useState(false)

  const createReportFromTemplate = (item: Partial<Iris_Report_Template>) => {
    const reportId = uuidv4()

    const reportTemplate = JSON.parse(
      item.report_template ?? '{}'
    ) as FullReportAtom

    reportTemplate.visualizations.forEach((viz: Visualization) => {
      const vizId = nanoid()

      // Yes, these are mutations, but we're in a parsed report that has no ripple effects,
      // so it's safe to mutate the object with the new id
      viz.id = vizId
      viz.config.id = vizId

      visualizationAtomFamily(viz)
    })

    const newReport = {
      id: reportId,
      organizationId,
      workspaceId,
      visualizationIds: reportTemplate.visualizations.map(viz => viz.id),
      datasetIds: []
    }

    console.log('newReport', newReport)

    reportAtomFamily(newReport)

    navigate(`../reports/${reportId}/data`)
  }

  return (
    <Popover
      destroyTooltipOnHide
      arrow={false}
      content={
        <VirtualizedReportTemplateList
          onItemClick={item => {
            createReportFromTemplate(item)
            setPopoverOpen(false)
          }}
        />
      }
      onOpenChange={(nextState: boolean) => {
        setPopoverOpen(nextState)
      }}
      open={popoverOpen}
      overlayInnerStyle={{ padding: 8, width: '33vw', minWidth: '25rem' }}
      placement='bottomRight'
      trigger='click'
    >
      <Tooltip
        placement='bottomRight'
        title='Create report from a predefined template'
      >
        <Button
          className='px-2 w-auto !rounded-l-none !border-l !border-l-white/25 hover:!border-l-transparent'
          type='primary'
        >
          <ChevronDown />
        </Button>
      </Tooltip>
    </Popover>
  )
}
