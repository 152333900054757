import { Grid2, SxProps, Theme, styled } from '@mui/material'

import { Filter_Type } from '../../../gql_generated/graphql'
import {
  KEYWORD_PROPERTY_OPERATOR_OPTIONS,
  NUMERIC_PROPERTY_OPERATOR_OPTIONS
} from '../../charts'
import { SelectorOption } from '../../types'
import {
  AUTOCOMPLETE_CLASS_NAME,
  Autocomplete_Filter,
  Autocomplete_Filter_Props
} from './Autocomplete_Filter'
import Numeric_Filter from './Numeric_Filter'

interface Autocomplete_Filter_Pair_Props {
  left_filter: Autocomplete_Filter_Props<SelectorOption, false, false>
  center_operator?: {
    onChange?: (value: Filter_Type) => void
    sx?: SxProps<Theme>
  }
  right_filter: Autocomplete_Filter_Props<SelectorOption, true, false>
  filter_type: Filter_Type
  loading: boolean
}

export const Autocomplete_Filter_Pair = ({
  left_filter,
  center_operator,
  right_filter,
  filter_type,
  loading
}: Autocomplete_Filter_Pair_Props) => {
  const filter_is_numeric = NUMERIC_PROPERTY_OPERATOR_OPTIONS.find(
    operator => operator.key === filter_type
  )

  return (
    <Styled_Grid container size='grow'>
      <Autocomplete_Filter
        {...{ ...left_filter }}
        grid_item_size='grow'
        loading={loading}
      />

      <Autocomplete_Filter
        disableClearable
        disabled={!filter_is_numeric}
        grid_item_size={{ xs: 2 }}
        multiple={false}
        onChange={(_e, value) => {
          center_operator?.onChange?.(value.key)
        }}
        options={
          filter_is_numeric
            ? NUMERIC_PROPERTY_OPERATOR_OPTIONS
            : KEYWORD_PROPERTY_OPERATOR_OPTIONS
        }
        sx={center_operator?.sx}
        value={(filter_is_numeric
          ? NUMERIC_PROPERTY_OPERATOR_OPTIONS
          : KEYWORD_PROPERTY_OPERATOR_OPTIONS
        ).find(operator => operator.key === filter_type)}
      />

      {filter_is_numeric ? (
        <Numeric_Filter
          key={left_filter?.value?.key}
          // @ts-ignore
          onBlur={right_filter?.onChange}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: 0
            }
          }}
          // @ts-ignore
          value={right_filter?.value}
        />
      ) : (
        <Autocomplete_Filter
          {...{ ...right_filter }}
          grid_item_size='grow'
          loading={loading}
        />
      )}
    </Styled_Grid>
  )
}

export const Styled_Grid = styled(Grid2)(
  () => `
  flex-wrap: nowrap;
  align-items: stretch;
  
  .${AUTOCOMPLETE_CLASS_NAME}{
    height: auto;
  }

  .MuiInputBase-root{
    height: 100%;
  }

  .MuiFormControl-root {
    height: 100%;
  }

  .MuiAutocomplete-root{
    height: 100%;
  }
  
  fieldset {
    border-radius: 0;
    margin-left: -1px;
  }

  .${AUTOCOMPLETE_CLASS_NAME}:first-of-type {
    fieldset {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      margin-left: 0;
    }
  }

  .${AUTOCOMPLETE_CLASS_NAME}:last-of-type {
    fieldset {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }
`
)
