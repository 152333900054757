import { IStatusMsg } from '@jupyterlab/services/lib/kernel/messages'

import { ExecutionStateEnum } from '../MessageParser'
import { useTranslator } from '../i18n'
import { OhmLogo } from './OhmLogo'

type OhmStatusProps = {
  jupyterStatus: Partial<IStatusMsg>
}
export const OhmStatus = (props: OhmStatusProps) => {
  const { jupyterStatus } = props

  const { translate } = useTranslator()

  if (jupyterStatus.content == null) return null

  let isLoading = false
  let statusText = ''
  switch (jupyterStatus.content.execution_state) {
    case ExecutionStateEnum.Idle:
      isLoading = false
      statusText = translate('jupyterStatus.ready')
      break
    case ExecutionStateEnum.Busy:
      isLoading = true
      statusText = translate('jupyterStatus.busy')
      break
    case ExecutionStateEnum.Starting:
    default:
      isLoading = true
      statusText = translate('jupyterStatus.starting')
      break
  }

  return (
    <div className='flex items-center gap-x-2 self-stretch text-xs'>
      <OhmLogo loading={isLoading} />
      <div>{statusText}</div>
    </div>
  )
}
