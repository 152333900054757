import { useQuery } from '@apollo/client'
import { Add, Filter, TrashCan, WarningHexFilled } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'
import { isBoolean, isEmpty, isNumber } from 'lodash-es'
import { nanoid } from 'nanoid'

import { Button, Popconfirm } from '@/components'
import { Filter_Type, Property_Type } from '@/gql_generated/graphql'
import {
  KEYWORD_PROPERTY_OPERATOR_OPTIONS,
  NUMERIC_PROPERTY_OPERATOR_OPTIONS,
  Numeric_Property_Types
} from '@/insights/charts'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '@/insights/charts/cycle_summaries/queries/get_cycle_summary_properties'
import { format_property_label } from '@/insights/charts/utils'
import {
  VisualizationType,
  visualizationAtomFamily
} from '@/insights/reports/store/report.molecule'
import { PropertyKeyOption } from '@/insights/reports/types'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { mapListKeysToValues } from '@/utils'

import { FilterPair, MenuHeader } from '../../../controls'
import { useVisualizationItem } from '../../VisualizationContext'

export const PanelSelectCycleFilters = () => {
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()
  const { config, type } = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { data, previousData, loading } = useQuery(
    GET_CYCLE_SUMMARY_PROPERTIES,
    {
      variables: {
        organization_id: organization_id as string
      },
      skip: !organization_id || !workspace_id
    }
  )

  const dataToUse = loading ? previousData : data

  const availablePropertyKeyOptions = mapListKeysToValues(
    dataToUse?.get_cycle_summary_properties?.cycle_summary_properties.map(
      (option: any) => ({
        ...option,
        label: format_property_label(option.label, option.units)
      })
    ) || []
  )

  if (type !== VisualizationType.Chart) return null

  const { cycleFilters } = config

  const configCycleFilters = cycleFilters ?? {}
  const configPropertyFilterList = Object.entries(configCycleFilters)

  if (configPropertyFilterList.length === 0) {
    configPropertyFilterList.push([
      nanoid(),
      { property: null, values: [], filter_type: Filter_Type.IsAnyOf }
    ])
  }

  // Count the number of filters with selections
  const configCycleFiltersWithSelectionsCount = Object.values(
    configCycleFilters
  ).filter(
    ({ property, values }) =>
      property != null ||
      !isEmpty(values) ||
      (isNumber(values) && values > 0) ||
      (isBoolean(values) && values)
  ).length

  return (
    <div>
      <MenuHeader
        title={
          <div className='flex flex-1 flex-row gap-x-2 items-center'>
            <Filter />
            <div className='flex w-full flex-row justify-between items-baseline'>
              <div>Select Cycles</div>
              <div className='text-xxs text-red-500'>Required</div>
            </div>
          </div>
        }
      />
      <div className='p-2'>
        <div className='flex flex-col gap-y-2'>
          {configPropertyFilterList.map(([key, filter]) => {
            return (
              <FilterPair
                key={key}
                centerOperatorProps={{
                  onChange: filterType => {
                    updateVisualizationConfig({
                      cycleFilters: {
                        ...configCycleFilters,
                        [key]: {
                          ...filter,
                          filter_type: filterType
                        }
                      }
                    })
                  },
                  value: filter.filter_type
                }}
                filterType={filter.filter_type}
                leftInputProps={{
                  options: availablePropertyKeyOptions,
                  placeholder: 'Filter Property',
                  allowClear: false,
                  loading,
                  onChange: (value, option) => {
                    const typedOption = option as PropertyKeyOption
                    updateVisualizationConfig({
                      cycleFilters: {
                        ...configCycleFilters,
                        [key]: {
                          ...filter,
                          property: value,
                          filter_type: Numeric_Property_Types.includes(
                            typedOption.type as Property_Type
                          )
                            ? NUMERIC_PROPERTY_OPERATOR_OPTIONS[0].key
                            : KEYWORD_PROPERTY_OPERATOR_OPTIONS[0].key
                        }
                      }
                    })
                  },
                  value: filter.property
                }}
                onClickX={() => {
                  const { [key]: _, ...newCycleFilters } = configCycleFilters
                  updateVisualizationConfig({
                    cycleFilters: newCycleFilters
                  })
                }}
                rightInputProps={{
                  placeholder: 'Filter Value',
                  className: 'text-xs',
                  loading,
                  disabled: filter.property == null,
                  options: [],
                  onChange: values => {
                    updateVisualizationConfig({
                      cycleFilters: {
                        ...configCycleFilters,
                        [key]: {
                          ...filter,
                          values
                        }
                      }
                    })
                  },
                  value: filter.values
                }}
              />
            )
          })}
          <div className='flex flex-row gap-x-2 items-center justify-between'>
            <Button
              className='text-xs font-semibold self-start px-0.5'
              color='primary'
              onClick={() => {
                updateVisualizationConfig({
                  cycleFilters: {
                    ...configCycleFilters,
                    [nanoid()]: {
                      property: null,
                      values: [],
                      filter_type: Filter_Type.NumericEquals
                    }
                  }
                })
              }}
              size='small'
              variant='text'
            >
              <Add size={16} />
              Add Filter
            </Button>
            {configCycleFiltersWithSelectionsCount > 0 && (
              <Popconfirm
                key='delete'
                cancelText='No'
                icon={
                  <WarningHexFilled className='mr-1 text-amber-500' size={22} />
                }
                okText='Yes'
                onConfirm={() =>
                  updateVisualizationConfig({
                    cycleFilters: {}
                  })
                }
                placement='topRight'
                title={`Are you sure you want to delete all ${configCycleFiltersWithSelectionsCount} filters?`}
              >
                <Button
                  className='text-xs font-semibold self-end px-0.5'
                  color='primary'
                  size='small'
                  variant='text'
                >
                  <TrashCan />
                  Remove All
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
