import { LabelHTMLAttributes, PropsWithChildren } from 'react'

import { cn } from '../../../../../utils'

type MenuItemInputLabelProps = PropsWithChildren<{
  emphasize?: boolean
  htmlFor?: LabelHTMLAttributes<HTMLLabelElement>['htmlFor']
}>
export function MenuItemInputLabel(props: MenuItemInputLabelProps) {
  const { htmlFor, children, emphasize } = props
  return (
    <label
      className={cn('text-xs', {
        'font-bold': emphasize,
        'font-medium': !emphasize
      })}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  )
}
