import { ReactNode } from 'react'

import { JupyterMessage } from './MessageParser'

export enum OhmAiMessageType {
  UserMessage = 'user_message',
  OhmTextResponse = 'system_text_response',
  OhmPythonCode = 'system_python_code',
  OhmThinking = 'system_thinking',
  OhmJupyterCell = 'system_jupyter_cell',
  SystemMessage = 'system_message',
  JupyterVisualization = 'jupyter_visualization',
  JupyterStdOut = 'jupyter_stdout',
  JupyterError = 'jupyter_error',
  JupyterResult = 'jupyter_result'
}

export enum OhmAiResponseType {
  Text = 'text',
  PythonCode = 'python_code',
  JupyterCell = 'jupyter_cell'
}

type BaseOhmAiMessage = {
  type: OhmAiMessageType
  title?: ReactNode
  promptId?: string
  processing?: boolean
}

export type OhmUserInteractionMessage = BaseOhmAiMessage & {
  cells?: any
  content: ReactNode
  feedbackMessage?: ReactNode
  internal?: boolean
  toString: () => string
  type: OhmAiMessageType.UserMessage
}

export type OhmSystemMessage = BaseOhmAiMessage & {
  cells?: any
  content: ReactNode
  toString: () => string
  type:
    | OhmAiMessageType.OhmPythonCode
    | OhmAiMessageType.OhmTextResponse
    | OhmAiMessageType.OhmThinking
    | OhmAiMessageType.OhmJupyterCell
}

export type OhmJupyterResponseMessage = BaseOhmAiMessage & {
  type:
    | OhmAiMessageType.JupyterVisualization
    | OhmAiMessageType.JupyterStdOut
    | OhmAiMessageType.JupyterError
    | OhmAiMessageType.JupyterResult
  content: JupyterMessage
}

export type OhmAiMessage =
  | OhmSystemMessage
  | OhmJupyterResponseMessage
  | OhmUserInteractionMessage
