import { LicenseInfo } from '@mui/x-license'
import { H } from 'highlight.run'
import { createRoot } from 'react-dom/client'

import { Base } from './base/Base'
import './i18n'
import './index.css'
import reportWebVitals from './reportWebVitals'

LicenseInfo.setLicenseKey(
  'b1ffda0f6fbfba8c0481a3bbf4efb94fTz0xMDY3ODksRT0xNzY5NjQ0Nzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
)

if (process.env.NODE_ENV === 'production') {
  H.init('ng2kyyg1', {
    // Highlight project ID
    environment: 'production',
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: []
    },
    enableCanvasRecording: true,
    samplingStrategy: {
      canvas: 1 / 3,
      canvasMaxSnapshotDimension: 480
    },
    manualStart: true
  })
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<Base />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
