import { ChartDualYAxis, ChartLine } from '@carbon/icons-react'
import { shuffle } from 'lodash-es'
import { PropsWithChildren, ReactNode, useMemo } from 'react'

import { Button } from '@/components'

import { useTranslator } from '../i18n'

type OhmQuickPromptProps = PropsWithChildren<{
  quickPrompt: QuickPrompt
  onClick: (prompt: string) => void
}>

export const OhmQuickPrompt = (props: OhmQuickPromptProps) => {
  const { quickPrompt, onClick } = props
  const { translate } = useTranslator()

  const { icon, i18nKey } = quickPrompt

  const prompt = translate(i18nKey)

  const _onClick = () => onClick(prompt)

  return (
    <Button
      className='items-center text-xs [&_.ant-btn-icon]:leading-none'
      icon={icon}
      onClick={_onClick}
      size='small'
      title={prompt}
    >
      {prompt}
    </Button>
  )
}

type QuickPrompt = {
  i18nKey: string
  icon: ReactNode
}
const QuickPromptKeys = [
  {
    i18nKey: 'quickPrompts.voltageVCapacity',
    icon: <ChartLine size={16} />
  },
  {
    i18nKey: 'quickPrompts.voltageAndCurrentVTime',
    icon: <ChartDualYAxis size={16} />
  },
  {
    i18nKey: 'quickPrompts.dischargeVCycle',
    icon: <ChartLine size={16} />
  }
]

type OhmQuickPromptsProps = {
  onPromptClick: (prompt: string) => void
}

export const OhmQuickPrompts = (props: OhmQuickPromptsProps) => {
  const { onPromptClick } = props

  const randomQuickPrompts = useMemo(
    () => shuffle(QuickPromptKeys).slice(0, 3),
    []
  )

  return (
    <div className='flex gap-2'>
      {randomQuickPrompts.map(prompt => (
        <OhmQuickPrompt
          key={prompt.i18nKey}
          onClick={onPromptClick}
          quickPrompt={prompt}
        />
      ))}
    </div>
  )
}
