import { useQuery } from '@apollo/client'
import { ButtonProps } from 'antd'
import { useSetAtom } from 'jotai'
import { isEqual, uniqBy } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { Virtuoso } from 'react-virtuoso'

import {
  Button,
  Divider,
  Popconfirm,
  Popover,
  Spin,
  Tooltip
} from '@/components'
import { NoResults } from '@/components/NoResults'
import { Iris_Chart } from '@/gql_generated/graphql'
import { GET_CHARTS } from '@/insights/charts/queries/charts'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { Pagination } from '@/types'
import { cn } from '@/utils'

import { chartStatesFamily } from '../jotai/charts.atoms'
import { ChartConfig } from '../reports/types'

type VirtualizedChartTemplateListProps = {
  chartId: string
  onItemClick?: (item: Partial<Iris_Chart>) => void
}

const VirtualizedChartTemplateList = (
  props: VirtualizedChartTemplateListProps
) => {
  const { chartId, onItemClick } = props
  const navigate = useNavigate()
  const setChartState = useSetAtom(chartStatesFamily({ id: chartId }))
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [loadedOnce, setLoadedOnce] = useState(false)
  const [allItems, setAllItems] = useState<Partial<Iris_Chart>[]>([])
  const [paginationModel, setPaginationModel] = useState<Pagination>({
    pageSize: 100,
    page: 0
  })

  const queryVariables = useMemo(
    () => ({
      organization_id: organization_id as string,
      workspace_id: workspace_id as string,
      page: paginationModel.page + 1, // The backend indexes from 1
      page_size: paginationModel.pageSize
    }),
    [organization_id, workspace_id, paginationModel]
  )

  const { data, previousData, loading } = useQuery(GET_CHARTS, {
    fetchPolicy: 'network-only',
    variables: queryVariables,
    skip: !organization_id || !workspace_id,
    onCompleted: () => {
      setLoadedOnce(true)
    }
  })

  const totalRowCount =
    data?.get_charts.total ?? previousData?.get_charts.total ?? 0

  useEffect(() => {
    if (data == null) return
    const rawRows: Partial<Iris_Chart>[] = data.get_charts.data ?? []

    setAllItems(prev => {
      const next = uniqBy([...prev, ...rawRows], 'id')
      return isEqual(prev, next) ? prev : next
    })
  }, [data])

  return (
    <div className='flex h-[40vh] min-h-[50px] min-w-80 flex-col gap-2 py-2'>
      {totalRowCount === 0 && loadedOnce ? (
        <NoResults />
      ) : (
        <>
          <div className='flex justify-between'>
            <div>
              <Button
                className='text-xs font-medium'
                color='primary'
                onClick={() => {
                  navigate('../charts')
                }}
                size='small'
                variant='filled'
              >
                Manage
              </Button>
            </div>
            <div className='self-end text-xs font-medium text-gray-500'>
              {totalRowCount} chart templates
            </div>
          </div>
          <Divider className='!m-0' />
          <Spin
            spinning={loading}
            tip='Loading...'
            wrapperClassName='h-full relative [&_.ant-spin-container]:h-full'
          >
            <Virtuoso
              className='overflow-x-hidden'
              data={allItems}
              endReached={() => {
                setPaginationModel(prev => {
                  const next = { ...prev, page: prev.page + 1 }
                  return isEqual(prev, next) ? prev : next
                })
              }}
              itemContent={(_, item) => {
                return (
                  <div className='min-h-7 pb-2'>
                    <Button
                      key={item.id}
                      className='h-auto w-full overflow-hidden text-xs font-medium [&:focus]:bg-gray-100'
                      color='default'
                      variant='text'
                    >
                      <Tooltip
                        placement='left'
                        title='Click to apply chart template'
                      >
                        <Popconfirm
                          onConfirm={() => {
                            setChartState(prev => {
                              if (item?.chart_config == null) return prev
                              return {
                                ...prev,
                                ...(JSON.parse(
                                  item.chart_config
                                ) as ChartConfig)
                              }
                            })
                            onItemClick?.(item)
                          }}
                          placement='left'
                          title='Are you sure you want to apply this chart template? All current changes will be lost.'
                        >
                          <div className='flex w-full flex-1 flex-col items-start gap-y-0.5 overflow-hidden'>
                            <div className='w-full overflow-hidden text-ellipsis text-left text-xs'>
                              {item.title}
                            </div>
                            <pre className='text-ellipsis text-left text-xxs text-gray-500'>
                              {item.description ?? (
                                <span className='italic'>
                                  No description provided
                                </span>
                              )}
                            </pre>
                          </div>
                        </Popconfirm>
                      </Tooltip>
                    </Button>
                  </div>
                )
              }}
              totalCount={totalRowCount}
            />
          </Spin>
        </>
      )}
    </div>
  )
}

type ApplyChartTemplateProps = ButtonProps & {
  chartId: string
}
export const ApplyChartTemplate = (props: ApplyChartTemplateProps) => {
  const { chartId, className, ...buttonProps } = props

  const [popoverOpen, setPopoverOpen] = useState(false)

  return (
    <Popover
      destroyTooltipOnHide
      arrow={false}
      classNames={{
        body: 'p-2 w-[33vw] min-w-96'
      }}
      content={
        <VirtualizedChartTemplateList
          chartId={chartId}
          onItemClick={() => {
            setPopoverOpen(false)
          }}
        />
      }
      onOpenChange={(nextState: boolean) => {
        setPopoverOpen(nextState)
      }}
      open={popoverOpen}
      placement='bottomRight'
      trigger='click'
    >
      <Button
        className={cn('min-w- text-xs font-medium', className)}
        {...buttonProps}
      >
        Load chart
      </Button>
    </Popover>
  )
}
