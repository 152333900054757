import { useMutation } from '@apollo/client'
import { ChartMultitype, Copy } from '@carbon/icons-react'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button, Input, Popconfirm, notification } from '@/components'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { use_app_selector } from '@/state/redux/hooks'
import { select_user_id } from '@/user/user_slice'

import { makeTableDescriptionFromTableState } from '../charts/transform'
import { InsightsTableState } from '../jotai/charts.atoms'
import { SAVE_TABLE } from './queries/table'

type AddTableToLibraryProps = {
  tableState: InsightsTableState
  isInitialState?: boolean
  className?: string
  isReportBuilder?: boolean
}

export const AddTableToLibrary = (props: AddTableToLibraryProps) => {
  const {
    tableState,
    className,
    isInitialState = true,
    isReportBuilder
  } = props
  const [api, contextHolder] = notification.useNotification()

  const userId = use_app_selector(select_user_id)
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [tableTitle, setTableTitle] = useState<string>('')
  const [tableDescription, setTableDescription] = useState<string>('')

  const [saveTableToLibrary, { loading: savingTable }] = useMutation(
    SAVE_TABLE,
    {
      onCompleted: data => {
        api.success({
          message: 'Saved table to library',
          description: data.save_table.title
        })
      }
    }
  )

  const updateMetadataFromTableState = (tableState: InsightsTableState) => {
    setTableTitle('Untitled Table Template')
    setTableDescription(makeTableDescriptionFromTableState(tableState))
  }

  const addTableToLibrary = async () => {
    if (isInitialState || savingTable) return
    if (organization_id == null || workspace_id == null || userId == null) {
      return
    }
    const tableId = uuidv4()

    await saveTableToLibrary({
      variables: {
        id: tableId,
        title: tableTitle,
        description: tableDescription,
        table_config: JSON.stringify(tableState),
        organization_id: organization_id,
        workspace_id: workspace_id,
        owner_user_id: userId
      },
      refetchQueries: ['get_tables'],
      awaitRefetchQueries: true
    })

    updateMetadataFromTableState(tableState)
  }

  useEffect(() => {
    updateMetadataFromTableState(tableState)
  }, [tableState])

  return (
    <>
      {contextHolder}
      <Popconfirm
        description={
          <div className='py-2 flex flex-col gap-2'>
            <div>
              This will save the table as a template for future use.
              <div className='text-xs flex gap-x-1 my-2'>
                <div className='font-bold'>Note:</div>
                <div>
                  Stored templates
                  <span className='font-medium italic mx-1'>do not</span>store
                  or preselect datasets.
                </div>
              </div>
            </div>
            <label className='w-full flex flex-col gap-1'>
              <div className='font-medium'>Title:</div>
              <Input
                onBlur={e => {
                  setTableTitle(e.target.value || 'Untitled Table Template')
                }}
                onChange={e => setTableTitle(e.target.value)}
                value={tableTitle}
              />
            </label>
            <label className='w-full flex flex-col gap-1'>
              <div className='font-medium'>Description:</div>
              <Input.TextArea
                className='text-[13px]'
                onBlur={e => {
                  setTableDescription(
                    e.target.value ||
                      makeTableDescriptionFromTableState(tableState)
                  )
                }}
                onChange={e => setTableDescription(e.target.value)}
                style={{
                  minHeight: `${
                    Math.max(2, tableDescription.split('\n').length) * 2.25
                  }em`
                }}
                value={tableDescription}
              />
            </label>
          </div>
        }
        onConfirm={addTableToLibrary}
        overlayClassName='[&_.ant-popconfirm-message-text]:flex-1 [&_.ant-popconfirm-description]:flex-1'
        overlayInnerStyle={{ width: '500px' }}
        placement='topRight'
        title='Save table template to library?'
      >
        {isReportBuilder ? (
          <Button
            className='text-xs font-medium text-gray-500 px-2'
            type='text'
          >
            <Copy /> Add Table To Library
          </Button>
        ) : (
          <Button
            className={className}
            disabled={isInitialState}
            type='primary'
          >
            <ChartMultitype />
            Save as template
          </Button>
        )}
      </Popconfirm>
    </>
  )
}
