import cn from 'classnames'
import 'keyboard-css'
import { Fragment, ReactNode } from 'react'

import { isMac } from '@/utils'

interface HotkeyProps {
  keys: {
    mac?: string
    default: string
  }[]
  action: ReactNode
}

function isSpecialCharacter(key: string) {
  return key === '⇧' || key === '⌘'
}

export const Hotkey = ({ keys, action }: HotkeyProps) => {
  return (
    <>
      <div className='flex items-center gap-x-1 whitespace-nowrap text-xxs'>
        {keys.map((keyObj, index) => {
          const character =
            (isMac() ? keyObj.mac : keyObj.default) ?? keyObj.default

          const isSpecial = isSpecialCharacter(character)
          return (
            <Fragment key={index}>
              <kbd
                className={cn(
                  'kbc-button mt-0.5 pt-1 text-xxs leading-none text-gray-500',
                  { 'pt-0.5 font-[Inter]': isMac() && isSpecial }
                )}
              >
                {character}
              </kbd>
              {index < keys.length - 1 && '+'}
            </Fragment>
          )
        })}
      </div>
      <div className='flex items-center'>{action}</div>
    </>
  )
}
