import saveAs from 'file-saver'
import { useMemo } from 'react'
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python'
import { xcode } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import { Alert } from '@/components'

import { OhmOutputContainer } from './OhmOutputContainer'

SyntaxHighlighter.registerLanguage('python', python)

type OhmCodeProps = {
  code: string
  descriptor: string
  fileName?: string
}
export const OhmCode = (props: OhmCodeProps) => {
  const { code, descriptor, fileName } = props

  const sanitizedFileName =
    fileName ?? `${descriptor.replace(/[^a-zA-Z0-9 ]/g, '')}.txt`

  const downloadOutput = () => {
    saveAs(
      new Blob([code], {
        type: 'text/plain;charset=utf-8'
      }),
      sanitizedFileName
    )
  }

  const formattedCode = useMemo(
    () => (
      <SyntaxHighlighter
        showLineNumbers
        customStyle={{
          background: 'transparent',
          overflowX: 'visible'
        }}
        language='python'
        lineNumberStyle={{
          borderRight: '1px solid #DDD',
          marginRight: '1rem'
        }}
        style={xcode}
      >
        {code}
      </SyntaxHighlighter>
    ),
    [code]
  )

  return (
    <OhmOutputContainer
      modalProps={{
        children: <Alert message={formattedCode} type='info' />,
        title: descriptor
      }}
      onDownload={downloadOutput}
      outputContainerClassName='p-0'
    >
      {formattedCode}
    </OhmOutputContainer>
  )
}
