export enum WorkspaceKey {
  Camp = 'camp',
  Enpower = 'enpower',
  Lis = 'lis',
  IndianaBic = 'indiana_bic',
  Nuvvon = 'nuvvon',
  UKBIC = 'ukbic'
}
type FeatureFlagStatus = Record<string, WorkspaceKey[]>

export const WorkspaceFeatureFlags: FeatureFlagStatus = {
  chat: [WorkspaceKey.Camp, WorkspaceKey.Enpower, WorkspaceKey.Lis]
}
