import { styled } from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel
} from '@mui/x-data-grid-pro'

import { NoResults, Spin } from '@/components'

import { COLUMN_HEADER_HEIGHT, PAGE_SIZE_OPTIONS } from '../constants'
import { TemplateGridHeader } from './_TemplateGridHeader'

type TemplateGridProps = {
  columnDefs: GridColDef[]
  loading?: boolean
  loadedOnce?: boolean
  onPaginationModelChange: (model: GridPaginationModel) => void
  paginationModel: GridPaginationModel
  rowCount: number
  rowsToRender: any[]
  title: string
}
export const TemplateGrid = (props: TemplateGridProps) => {
  const {
    columnDefs,
    loading,
    loadedOnce,
    onPaginationModelChange,
    paginationModel,
    rowCount,
    rowsToRender,
    title
  } = props

  return (
    <>
      <div className='flex flex-row items-end justify-between'>
        <TemplateGridHeader
          loading={loading}
          rowCount={rowCount}
          title={title}
        />
      </div>
      <StyledDatagrid
        disableColumnMenu
        disableDensitySelector
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        pagination
        columnHeaderHeight={COLUMN_HEADER_HEIGHT}
        columns={columnDefs}
        density='compact'
        filterDebounceMs={500}
        getRowHeight={() => 'auto'}
        loading={!columnDefs || loading}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationMode='server'
        paginationModel={paginationModel}
        rowCount={rowCount}
        rows={rowsToRender}
        slotProps={{
          pagination: {
            rowsPerPageOptions: PAGE_SIZE_OPTIONS.map(size => ({
              value: size,
              label: `${size} rows per page`
            })),
            labelRowsPerPage: null
          }
        }}
        slots={{
          loadingOverlay: Spin,
          noResultsOverlay: loadedOnce ? NoResults : Spin,
          noRowsOverlay: loadedOnce ? NoResults : Spin
        }}
      />
    </>
  )
}

export const StyledDatagrid = styled(DataGridPro)(({ theme }) => ({
  height: 'max-content',
  minHeight: '50vh',
  maxHeight: 'calc(100vh - 170px)',
  borderRadius: theme.shape.borderRadius,
  '.MuiDataGrid-container--top': {
    backgroundColor: `${theme.palette.gray[100]}!important`
  },
  '.MuiDataGrid-main': {
    overflowY: 'auto'
  },
  '.MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center'
  },
  '.MuiDataGrid-row:hover': {
    background: theme.palette.gray[50]
  },
  '.MuiDataGrid-footerContainer': {
    border: 0,
    borderTop: `1px solid ${theme.palette.divider}`
  },
  '.MuiDataGrid-overlayWrapperInner': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '.MuiTablePagination-root': {
    width: '100%',
    '.MuiTablePagination-toolbar': {
      border: 0,
      '.MuiInputBase-root': {
        border: 0,
        background: 'transparent',
        boxShadow: 'none',
        margin: 0
      },
      '.MuiSelect-select': {
        border: 0
      },
      '.MuiTablePagination-spacer': {
        display: 'none'
      },
      '.MuiTablePagination-displayedRows': {
        flex: 1,
        textAlign: 'right'
      }
    }
  }
}))
