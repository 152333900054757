import { DEFAULT_ICON_COLOR } from './constants'

interface Line_Icon_Props {
  dash_array: number
  color?: string
}

export const Line_Icon = ({
  dash_array,
  color = DEFAULT_ICON_COLOR
}: Line_Icon_Props) => {
  return (
    <svg height='10' viewBox='0 0 30 10' width='30'>
      <line
        stroke={color}
        strokeDasharray={dash_array}
        strokeWidth='2'
        x1='0'
        x2='30'
        y1='5'
        y2='5'
      />
    </svg>
  )
}
