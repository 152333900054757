import cn from 'classnames'

import { useTranslator } from '../i18n'
import { Hotkey } from './Hotkey'

export const OhmHotkeys = () => {
  const { translate } = useTranslator()
  return (
    <div
      className={cn(
        'grid grid-cols-2 items-center text-xs',
        '[&>:nth-child(4n+1)]:bg-gray-100/75 [&>:nth-child(4n+2)]:bg-gray-100/75',
        '[&>*]:h-12 [&>*]:px-2'
      )}
    >
      <Hotkey
        action={
          <div className='flex flex-col'>
            {translate('clearChat')}
            <div className='text-xxs'>
              ({translate('doesNotDeselectDatasets')})
            </div>
          </div>
        }
        keys={[
          { default: 'Ctrl', mac: '⌘' },
          { default: '← Bksp', mac: 'Del' }
        ]}
      />
      <Hotkey
        action={translate('selectNotebook')}
        keys={[{ default: 'Ctrl', mac: '⌘' }, { default: 'K' }]}
      />
      <Hotkey
        action={translate('toggleDatasetFinder')}
        keys={[{ default: 'Ctrl', mac: '⌘' }, { default: 'B' }]}
      />
      <Hotkey
        action={translate('saveAsNotebook.title')}
        keys={[
          { default: 'Ctrl', mac: '⌘' },
          { default: '⇧' },
          { default: 'S' }
        ]}
      />
      <Hotkey
        action={translate('showHotkeys')}
        keys={[
          { default: 'Ctrl', mac: '⌘' },
          { default: '⇧' },
          { default: 'K' }
        ]}
      />
    </div>
  )
}
