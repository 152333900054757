import { Download } from '@carbon/icons-react'
import { Button, Modal, ModalProps } from 'antd'

import { useTranslator } from '../i18n'

type OhmOutputModalProps = ModalProps & {
  onDownload: () => void
}

export const OhmOutputModal = (props: OhmOutputModalProps) => {
  const { onDownload, children, ...rest } = props

  const { translate } = useTranslator()

  return (
    <Modal
      centered
      maskClosable
      footer={
        <div>
          <Button
            className='h-auto gap-x-1 border-blue-300 !bg-blue-100/50 px-2 text-blue-500 hover:!bg-blue-100 hover:text-blue-400'
            color='primary'
            icon={
              <div className='flex items-center'>
                <Download />
              </div>
            }
            onClick={onDownload}
            variant='filled'
          >
            {translate('download')}
          </Button>
        </div>
      }
      width={'95%'}
      zIndex={1300}
      {...rest}
    >
      <div className='max-h-[75vh] overflow-y-auto'>{children}</div>
    </Modal>
  )
}
