const messages = {
  common: {
    added: 'Añadido',
    changes: 'Cambios',
    dateCreated: 'Fecha de creación',
    description: 'Descripción',
    diff: 'Diferencia',
    download: 'Descargar',
    error: 'Error',
    expand: 'Expandir',
    fileName: 'Nombre del archivo',
    lastModified: 'Última modificación',
    modified: 'Modificado',
    note: 'Nota',
    removed: 'Eliminado',
    size: 'Tamaño',
    title: 'Título',
    xBytes_one: '{{count}} byte',
    xBytes_other: '{{count}} bytes',
    xRowsPerPage: '{{count}} filas por página'
  }
}

export default messages
