import { IStreamMsg } from '@jupyterlab/services/lib/kernel/messages'
import saveAs from 'file-saver'
import { useMemo } from 'react'
import { Fragment } from 'react/jsx-runtime'

import { Alert } from '@/components'

import { OhmOutputContainer } from './OhmOutputContainer'

type OhmStdOutProps = {
  stdOut: IStreamMsg[]
  descriptor: string
  fileName: string
  isError: boolean
}
export const OhmStdOut = (props: OhmStdOutProps) => {
  const { stdOut, descriptor, fileName } = props

  const sanitizedFileName =
    fileName ?? `${descriptor.replace(/[^a-zA-Z0-9 ]/g, '')}.txt`

  const renderedStream = useMemo(() => {
    return (
      <div>
        {stdOut.map(stream => {
          const lines = stream.content.text.split('\n')
          return lines.map((line, index) => (
            <Fragment key={index}>
              <pre>{line}</pre>
            </Fragment>
          ))
        })}
      </div>
    )
  }, [stdOut])

  const downloadOutput = () => {
    saveAs(
      new Blob([stdOut.map(stream => stream.content.text).join('')], {
        type: 'text/plain;charset=utf-8'
      }),
      sanitizedFileName
    )
  }

  return (
    <OhmOutputContainer
      modalProps={{
        children: <Alert message={renderedStream} type='info' />,
        title: descriptor
      }}
      onDownload={downloadOutput}
    >
      {renderedStream}
    </OhmOutputContainer>
  )
}
