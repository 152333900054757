import { useQuery } from '@apollo/client'
import { Add, Filter } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'
import { compact } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useParams } from 'react-router'

import { Button } from '@/components'
import {
  Filter_Option,
  Filter_Type,
  Property_Type
} from '@/gql_generated/graphql'
import {
  KEYWORD_PROPERTY_OPERATOR_OPTIONS,
  NUMERIC_PROPERTY_OPERATOR_OPTIONS,
  Numeric_Property_Types
} from '@/insights/charts'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '@/insights/charts/cycle_summaries/queries/get_cycle_summary_properties'
import { format_property_label } from '@/insights/charts/utils'
import { mapListKeysToValues } from '@/utils'

import {
  VisualizationType,
  reportAtomFamily,
  visualizationAtomFamily
} from '../../../../../store/report.molecule'
import { PropertyKeyOption, ReportRouteParams } from '../../../../../types'
import { FilterPair, MenuHeader } from '../../../controls'
import { useVisualizationItem } from '../../VisualizationContext'

export const PanelCycleFilters = () => {
  const { reportId } = useParams<ReportRouteParams>()
  const report = useAtomValue(reportAtomFamily({ id: reportId }))
  const { workspaceId, organizationId } = report

  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()
  const { config, type } = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  let additionalFilterKeys: string[] = []
  if (type === VisualizationType.Chart) {
    const { propertyFilters } = config
    additionalFilterKeys = compact(
      Object.values(propertyFilters ?? {}).map(filter => filter.property?.key)
    )
  }

  const { data, previousData, loading } = useQuery(
    GET_CYCLE_SUMMARY_PROPERTIES,
    {
      variables: {
        organization_id: organizationId
      },
      skip: !organizationId || !workspaceId
    }
  )

  const dataToUse = loading ? previousData : data

  const additionalPropertyKeyOptions = additionalFilterKeys.reduce(
    (acc: Record<string, Filter_Option[]>, property_key) => {
      acc[property_key] = []
      return acc
    },
    {}
  )

  const availablePropertyKeyOptions = mapListKeysToValues(
    dataToUse?.get_cycle_summary_properties?.cycle_summary_properties.map(
      v => ({
        ...v,
        label: format_property_label(v.label, v.units)
      })
    ) || []
  )

  if (type !== VisualizationType.Chart) return null
  const { propertyFilters = {} } = config

  const configPropertyFilterList = Object.entries(propertyFilters)

  if (configPropertyFilterList.length === 0) {
    configPropertyFilterList.push([
      nanoid(),
      { property: null, values: [], filter_type: Filter_Type.IsAnyOf }
    ])
  }

  return (
    <div>
      <MenuHeader
        title={
          <div className='flex flex-row gap-x-2 items-center'>
            <Filter />
            Filters
          </div>
        }
      />
      <div className='p-2'>
        <div className='flex flex-col gap-y-2'>
          {configPropertyFilterList.map(([key, filter]) => {
            return (
              <FilterPair
                key={key}
                centerOperatorProps={{
                  onChange: filterType => {
                    updateVisualizationConfig({
                      propertyFilters: {
                        ...propertyFilters,
                        [key]: {
                          ...filter,
                          filter_type: filterType
                        }
                      }
                    })
                  },
                  value: filter.filter_type
                }}
                filterType={filter.filter_type}
                leftInputProps={{
                  options: availablePropertyKeyOptions,
                  placeholder: 'Filter Property',
                  allowClear: false,
                  loading,
                  onChange: (_, option) => {
                    const typedOption = option as PropertyKeyOption
                    updateVisualizationConfig({
                      propertyFilters: {
                        ...propertyFilters,
                        [key]: {
                          ...filter,
                          property: typedOption,
                          filter_type: Numeric_Property_Types.includes(
                            typedOption.type as Property_Type
                          )
                            ? NUMERIC_PROPERTY_OPERATOR_OPTIONS[0].key
                            : KEYWORD_PROPERTY_OPERATOR_OPTIONS[0].key
                        }
                      }
                    })
                  },
                  value: filter.property
                }}
                onClickX={() => {
                  const { [key]: _, ...newPropertyFilters } = propertyFilters
                  updateVisualizationConfig({
                    propertyFilters: newPropertyFilters
                  })
                }}
                rightInputProps={{
                  placeholder: 'Filter Value',
                  className: 'text-xs',
                  loading,
                  disabled: filter.property == null,
                  options: filter.property
                    ? (additionalPropertyKeyOptions[filter.property.key] ?? [])
                    : [],
                  onChange: values => {
                    updateVisualizationConfig({
                      propertyFilters: {
                        ...propertyFilters,
                        [key]: {
                          ...filter,
                          values
                        }
                      }
                    })
                  },
                  value: filter.values
                }}
              />
            )
          })}
          <Button
            className='text-xs font-semibold self-start px-0.5'
            color='primary'
            onClick={() => {
              updateVisualizationConfig({
                propertyFilters: {
                  ...propertyFilters,
                  [nanoid()]: {
                    property: null,
                    values: [],
                    filter_type: Filter_Type.IsAnyOf
                  }
                }
              })
            }}
            size='small'
            variant='text'
          >
            <Add size={16} />
            Add Filter
          </Button>
        </div>
      </div>
    </div>
  )
}
