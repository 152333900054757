import { isEqual } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

// Constants for storage keys
const SS_TAB_ID = 'tabId'
const LS_WINDOWS = 'activeWindows'

// Type definition for window entries in storage
type WindowEntry = {
  tabId: string
  timestamp: number
  isMain?: boolean
}

type WindowState = {
  tabId: string
  isMainWindow: boolean
  wasEverMainWindow: boolean
  hasMultipleTabs: boolean
}

/**
 * Custom hook to manage the state of windows across multiple tabs.
 *
 * This hook provides the following state variables:
 * - `tabId`: The unique identifier for the current tab.
 * - `isMainWindow`: A boolean indicating if the current window is the main window.
 * - `wasEverMainWindow`: A boolean indicating if the current window was ever the main window.
 * - `hasMultipleTabs`: A boolean indicating if there are multiple tabs open.
 *
 * The hook uses session storage to persist the tab ID and local storage to persist the state of all active windows.
 * It also sets up a periodic registration to update the state of the windows and cleans up the state when the window is unloaded.
 *
 * @returns {Object} An object containing the window state information:
 * - `tabId`: The unique identifier for the current tab.
 * - `isMainWindow`: A boolean indicating if the current window is the main window.
 * - `wasEverMainWindow`: A boolean indicating if the current window was ever the main window.
 * - `hasMultipleTabs`: A boolean indicating if there are multiple tabs open.
 *
 * @example
 * const { tabId, isMainWindow, wasEverMainWindow, hasMultipleTabs } = useWindowState();
 *
 * @remarks
 * The hook performs the following steps:
 * 1. Retrieves or creates a unique tab ID from session storage.
 * 2. Registers or updates the window in local storage.
 * 3. Cleans up stale windows (older than 20 minutes).
 * 4. Determines if any window is marked as main.
 * 5. Updates the `isMainWindow` and `wasEverMainWindow` state variables.
 * 6. Sets up a periodic registration to update the window state every 2 seconds.
 * 7. Cleans up the state when the window is unloaded.
 *
 * The logic for determining the main window is as follows:
 * - If this is the only window, or it was main before, or no window is main, set as main.
 * - If the current window is the main window and is being unloaded, set the next oldest window as main.
 */
export const useWindowState = (): WindowState => {
  // State for current tab's ID
  const [tabId, setTabId] = useState<string>('')
  // State for all active windows across tabs
  const [windows, setWindows] = useLocalStorage<WindowEntry[]>(LS_WINDOWS, [])
  // State indicating if current window is main
  const [isMainWindow, setIsMainWindow] = useState(false)
  // State indicating if current window was ever main
  const [wasEverMainWindow, setWasEverMainWindow] = useState(false)
  // State for window state information
  const [windowState, setWindowState] = useState<WindowState>({
    tabId: '',
    isMainWindow: false,
    wasEverMainWindow: false,
    hasMultipleTabs: false
  })

  /**
   * Retrieves or creates a unique tab ID from session storage.
   */
  const getOrCreateTabId = useCallback(() => {
    let currentTabId = sessionStorage.getItem(SS_TAB_ID)
    if (!currentTabId) {
      currentTabId = nanoid()
      sessionStorage.setItem(SS_TAB_ID, currentTabId)
    }
    setTabId(currentTabId)
    return currentTabId
  }, [])

  /**
   * Registers a window with the given tab ID and updates the window state.
   *
   * This function performs the following actions:
   * - Cleans up stale windows that are older than 20 minutes.
   * - Ensures that there is always one main window. If the current window is the only window,
   *   was previously marked as main, or no window is currently marked as main, it will be set as main.
   * - Updates the `isMainWindow` state to indicate if the current window is the main window.
   * - Updates the `wasEverMainWindow` state to indicate if the current window was ever marked as main.
   *
   * @param currentTabId - The ID of the current tab to register.
   */
  const registerWindow = useCallback(
    (currentTabId: string) => {
      // If the window was ever main, do not change the state, as it implies
      // the window was already registered at some point
      if (wasEverMainWindow) return
      setWindows(prev => {
        const now = Date.now()
        const existingWindow = prev.find(w => w.tabId === currentTabId)

        // Clean up stale windows (older than 20 minutes)
        const activeWindows = prev.filter(
          w => now - w.timestamp < 20 * 60 * 1000
        )

        // Check if any window is marked as main
        const anyMainWindow = activeWindows.some(w => w.isMain)

        const newWindows = [
          ...activeWindows.filter(w => w.tabId !== currentTabId),
          {
            tabId: currentTabId,
            timestamp: existingWindow?.timestamp ?? now,
            // If this is the only window, or it was main before, or no window is main, set as main
            isMain:
              existingWindow?.isMain ||
              activeWindows.length === 0 ||
              !anyMainWindow
          }
        ].sort((a, b) => a.tabId.localeCompare(b.tabId))

        // Update isMain state
        const isCurrentMain =
          newWindows.find(w => w.tabId === currentTabId)?.isMain || false

        setIsMainWindow(isCurrentMain)

        // Update wasEverMainWindow state
        if (isCurrentMain) {
          setWasEverMainWindow(true)
        }

        return isEqual(prev, newWindows) ? prev : newWindows
      })
    },
    [setWindows, wasEverMainWindow]
  )

  /**
   * Cleans up the window state by removing the window with the given tab ID.
   */
  const cleanup = useCallback(
    (currentTabId: string) => {
      setWindows(prev => {
        const remainingWindows = prev.filter(w => w.tabId !== currentTabId)
        if (isMainWindow && remainingWindows.length > 0) {
          // Set the next oldest window as main
          remainingWindows[0].isMain = true
        }
        return remainingWindows
      })
    },
    [setWindows, isMainWindow]
  )

  // Initialize window state on mount and set up periodic registration and cleanup
  useEffect(() => {
    const currentTabId = getOrCreateTabId()

    // Initial registration
    registerWindow(currentTabId)

    // Set up periodic registration (check every 1 seconds)
    const interval = setInterval(() => registerWindow(currentTabId), 1000)

    // Cleanup function for window unload
    const handleBeforeUnload = () => cleanup(currentTabId)
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      clearInterval(interval)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [getOrCreateTabId, registerWindow, cleanup])

  // Return window state information
  useEffect(() => {
    setWindowState(prev => {
      const nextState = {
        tabId,
        isMainWindow,
        wasEverMainWindow,
        hasMultipleTabs: windows.length > 1
      }

      return isEqual(prev, nextState) ? prev : nextState
    })
  }, [tabId, isMainWindow, wasEverMainWindow, windows.length])

  return windowState
}
