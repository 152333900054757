import { Contents } from '@jupyterlab/services'

export const MAX_DATASET_SELECTIONS = 30

export const JupyterBridgeId = 'ohm-bridge'
export enum JupyterBridgeMessageType {
  Ack = 'ack',
  CellExecution = 'cell-execution',
  CopyToClipboard = 'copy-to-clipboard',
  Error = 'error',
  ExecuteTransientCode = 'execute-transient-code',
  Identify = 'identify',
  Initialized = 'initialized',
  ListNotebooksInPath = 'list-notebooks-in-path',
  LoadSharedNotebook = 'load-shared-notebook',
  IOMessage = 'io-message',
  NotebookClosed = 'notebook-closed',
  NotebookOpened = 'notebook-opened',
  OpenPath = 'open-path',
  Operation = 'operation',
  Ready = 'ready',
  SaveNotebookToPath = 'save-notebook-to-path',
  SaveSharedNotebook = 'save-shared-notebook',
  SharedExtensionBlocked = 'rename-prevented',
  Token = 'token'
}

export enum JupyterBridgeOperationState {
  InProgress = 'in-progress',
  Complete = 'complete',
  Error = 'error'
}

export type JupyterBridgeMessageEvent<T = unknown> = MessageEvent<{
  source: string
  type: JupyterBridgeMessageType | string
  payload?: string | T | T[]
  /**
   * @deprecated This should be incliuded in the payload now.
   */
  operationId?: string
  /**
   * @deprecated This should be incliuded in the payload now.
   */
  kernelId?: string
}>

export type SaveNotebookResultPayload = {
  success: boolean
  message: string
  finalPath?: string
}

export type ListNotebooksInPathPayload = {
  notebooks: Contents.IModel[]
  error?: string
}

export type ExecuteTransientCodePayload = {
  code: string
  operationId?: string
  kernelId?: string
}

export const OhmKeyword = {
  Datasets: '__DATASETS__'
}

export const OHM_GENERATED_NOTEBOOK_PATH = 'chat'
export const getPythonCodeBlock = (apiKey: string, generatedCode: string) => {
  return `
import matplotlib.pyplot as plt
import numpy as np
import pandas as pd

from byterat.client import ByteratClientSync
from byterat.filter import Filter, FilterOperator, FilterGroup, FilterGroupType
from byterat_utils.colors import GenerateColors

API_TOKEN = "${apiKey}"
client = ByteratClientSync(API_TOKEN)
${generatedCode}
  `
}
