import { useQuery } from '@apollo/client'
import { Checkbox, Grid2, LinearProgress, styled } from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSlots,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import { useAtom } from 'jotai'
import { compact, startCase } from 'lodash-es'
import { useMemo, useRef, useState } from 'react'

import { NoResults } from '@/components/NoResults'
import {
  GET_COLUMNS_FOR_INSIGHTS_DASHBOARD,
  GET_DATASETS_FOR_INSIGHTS_DASHBOARD
} from '@/datasets/queries/get_datasets_for_insights_dashboard'
import {
  Filter_Logic_Operator,
  Filter_Type,
  Sort_Direction
} from '@/gql_generated/graphql'
import {
  FIXED_COLUMN_PROPERTIES,
  GRID_FILTER_OPERATOR_TO_FILTER_TYPE,
  NULLABLE_FILTER_TYPES,
  PROPERTY_TYPE_TO_GRID_COL_TYPE,
  datasetToDatasetGridRow
} from '@/insights/home/insights_slice'
import { Insights_Datasets_Table_Filter_Panel } from '@/insights/tables/Insights_Datasets_Table_Filter_Panel'

import { reportAtomFamily } from '../../store/report.molecule'
import { TableConfig } from '../../types'
import { EditableReportTableHeader } from './controls/EditableReportTableHeader'

const COLUMN_HEADER_HEIGHT = 80
const PAGE_SIZE_OPTIONS = [25, 50, 100]

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    rowCount: number
    selectedRowCount: number
    onSearch: (new_value: string) => void
    loading: boolean
  }
}

type ReportTableProps = {
  config?: TableConfig
  title?: string
  reportId: string
}
export const EditableReportTable = (props: ReportTableProps) => {
  const { reportId = '', config } = props

  const apiRef = useGridApiRef()
  const [transientReport, setTransientReport] = useAtom(
    reportAtomFamily({ id: reportId })
  )

  const { datasetIds, workspaceId, organizationId } = transientReport

  const { columns } = config ?? {}

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE_OPTIONS[0],
    page: 0
  })
  const [queryString, setQueryString] = useState('')
  const [propertiesSort, setPropertiesSort] = useState<GridSortModel>()
  const [propertiesFilter, setPropertiesFilter] = useState<GridFilterModel>()

  const { data: columnData } = useQuery(GET_COLUMNS_FOR_INSIGHTS_DASHBOARD, {
    variables: {
      organization_id: organizationId!,
      workspace_ids: [workspaceId!]
    },
    skip: !organizationId || !workspaceId
  })

  const propertiesSortForQuery = useMemo(() => {
    return (
      propertiesSort
        ?.filter(
          ({ sort }) =>
            sort === Sort_Direction.Asc || sort === Sort_Direction.Desc
        )
        .map(({ field, sort }) => ({
          property: field,
          direction: sort as Sort_Direction
        })) ?? []
    )
  }, [propertiesSort])

  const propertiesFilterForQuery = useMemo(() => {
    const { items, logicOperator } = propertiesFilter ?? {}

    if (items == null || items.length === 0) {
      return
    }

    const filters = items
      .map(({ field, value, operator }) => {
        const filter_type = GRID_FILTER_OPERATOR_TO_FILTER_TYPE[operator]

        return {
          key: field,
          filter_type,
          value
        }
      })
      .filter(({ filter_type, value }) =>
        NULLABLE_FILTER_TYPES.includes(filter_type) ? true : !!value
      )
      .filter(({ filter_type, value }) =>
        filter_type === Filter_Type.IsAnyOf ? value.length > 0 : true
      )

    return {
      filters,
      logic_operator: (logicOperator ||
        Filter_Logic_Operator.And) as Filter_Logic_Operator
    }
  }, [propertiesFilter])

  const { data: datasetData, loading } = useQuery(
    GET_DATASETS_FOR_INSIGHTS_DASHBOARD,
    {
      variables: {
        organization_id: organizationId!,
        workspace_ids: [workspaceId!],
        page_size: paginationModel.pageSize,
        page_from: paginationModel.page,
        query_string: queryString,
        properties_sort: propertiesSortForQuery,
        properties_filter: propertiesFilterForQuery
      },
      skip: !organizationId || !workspaceId
    }
  )

  const fixedColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: 'Select',
      type: 'actions',
      width: 50,
      renderCell: ({ row }) => {
        return (
          <Checkbox
            checked={datasetIds.includes(row.id)}
            onChange={event => {
              const { checked } = event.target
              setTransientReport(prev => ({
                ...prev,
                datasetIds: checked
                  ? [...prev.datasetIds, row.id]
                  : prev.datasetIds.filter(id => id !== row.id)
              }))
            }}
          />
        )
      }
    },
    ...FIXED_COLUMN_PROPERTIES
  ]
  const datasetColumns = columnData?.get_datasets_list.columns ?? []
  const has_cycle_count = datasetColumns.some(
    property => property.key === 'cycle_count'
  )
  const cycle_count_column_index = fixedColumns.findIndex(
    column => column.field === 'cycle_count'
  )
  if (cycle_count_column_index !== -1 && !has_cycle_count) {
    fixedColumns.splice(cycle_count_column_index, 1)
  }

  const columnDefs = datasetColumns.length
    ? fixedColumns.concat(
        datasetColumns
          .filter(
            ({ key }) =>
              !FIXED_COLUMN_PROPERTIES.map(({ field }) => field).includes(key)
          )
          .sort((a, b) => {
            const aIndex = columns?.indexOf(a.key) ?? -1
            const bIndex = columns?.indexOf(b.key) ?? -1
            if (aIndex === -1 && bIndex === -1) return 0
            if (aIndex === -1) return 1
            if (bIndex === -1) return -1
            return aIndex - bIndex
          })
          .map(({ key, property_type }) => {
            const type = property_type
              ? PROPERTY_TYPE_TO_GRID_COL_TYPE[property_type]
              : 'string'
            return {
              minWidth: 150,
              width: 150,
              field: key,
              headerName: startCase(key),
              type,
              flex: 1,
              valueGetter:
                type === PROPERTY_TYPE_TO_GRID_COL_TYPE.date
                  ? value => (value != null ? new Date(value) : null)
                  : undefined
            }
          })
      )
    : []

  const validDatasets = compact(
    (datasetData?.get_datasets_list.rows ?? []).map(({ id, ...rest }) => {
      if (id == null) return null

      return datasetToDatasetGridRow({
        id,
        ...rest
      })
    })
  )

  const pinnedRows = {
    top: validDatasets.filter(v => v != null && datasetIds.includes(v.id)),
    bottom: []
  }

  // Need to ensure row_count is not set to undefined between queries
  // otherwise the pagination model gets reset to page 0.
  const totalRowCount = datasetData?.get_datasets_list.result_count || undefined
  const rowCountRef = useRef(totalRowCount || 0)
  const rowCount = useMemo(() => {
    if (totalRowCount !== undefined) {
      rowCountRef.current = totalRowCount
    }
    return rowCountRef.current
  }, [totalRowCount])

  return (
    <>
      <Styled_Dataset_Datagrid
        disableColumnMenu
        disableDensitySelector
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        pagination
        apiRef={apiRef}
        columnHeaderHeight={COLUMN_HEADER_HEIGHT}
        columns={columnDefs}
        density='compact'
        filterDebounceMs={500}
        filterMode='server'
        initialState={{ pinnedColumns: { left: ['Select'] } }}
        loading={loading}
        onFilterModelChange={setPropertiesFilter}
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setPropertiesSort}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationMode='server'
        paginationModel={paginationModel}
        pinnedRows={pinnedRows}
        rowCount={rowCount}
        rows={validDatasets || []}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            rowCount,
            selectedRowCount: datasetIds.length,
            onSearch: setQueryString,
            loading
          },
          pagination: {
            rowsPerPageOptions: PAGE_SIZE_OPTIONS.map(size => ({
              value: size,
              label: `${size} rows per page`
            })),
            labelRowsPerPage: null
          }
        }}
        slots={{
          toolbar: EditableReportTableHeader as GridSlots['toolbar'],
          loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
          noResultsOverlay: NoResults,
          noRowsOverlay: NoResults,
          filterPanel: () => (
            <Filter_Panel_Wrapper>
              <Insights_Datasets_Table_Filter_Panel />
            </Filter_Panel_Wrapper>
          )
        }}
        sortingMode='server'
      />
    </>
  )
}

const Styled_Dataset_Datagrid = styled(DataGridPro)(({ theme, rowCount }) => ({
  maxWidth: '100%',
  minHeight: rowCount === 0 ? '180px' : 'auto',
  borderRadius: theme.shape.borderRadius,
  border: 0,

  '.MuiDataGrid-columnHeaders': {
    background: theme.palette.gray[100],
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderRadius: 0,
    border: 'none',
    '[role=row]': {
      background: 'transparent'
    }
  },
  '.MuiDataGrid-columnHeader': {
    background: 'transparent',
    '&.MuiDataGrid-columnHeader--pinnedLeft': {
      background: theme.palette.gray[100]
    }
  },
  '.MuiDataGrid-main': {
    overflowY: 'auto',
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  '.MuiDataGrid-row': {
    ':hover': {
      backgroundColor: 'none',
      '.MuiDataGrid-cell--pinnedLeft': {
        backgroundColor: `${theme.palette.blue[100]}!important`
      }
    }
  },
  '.MuiDataGrid-footerContainer': {
    border: `1px solid ${theme.palette.divider}`,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius
  },
  '.MuiTablePagination-root': {
    width: '100%',
    overflow: 'hidden',
    '.MuiTablePagination-toolbar': {
      border: 0,
      '.MuiInputBase-root': {
        border: 0,
        background: 'transparent',
        boxShadow: 'none',
        margin: 0
      },
      '.MuiSelect-select': {
        border: 0
      },
      '.MuiTablePagination-spacer': {
        display: 'none'
      },
      '.MuiTablePagination-displayedRows': {
        flex: 1,
        textAlign: 'right'
      }
    }
  }
}))

const Filter_Panel_Wrapper = styled(Grid2)(({ theme }) => ({
  padding: theme.spacing(1),
  maxWidth: 420
}))
