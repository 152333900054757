import { useLazyQuery, useQuery } from '@apollo/client'
import {
  CheckmarkOutline,
  CircleDash,
  CloudUpload,
  WarningAlt
} from '@carbon/icons-react'
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Button, Upload, UploadFile } from 'antd'
import { UploadChangeParam } from 'antd/es/upload'
import dayjs from 'dayjs'
import { filesize } from 'filesize'
import { startCase, toLower } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router'

import { DateFormats } from '@/constants'

import { BaseLayout, Divider, Typography } from '../../components'
import { notification } from '../../components'
import {
  GET_ETL_STATUS,
  GET_UPLOADED_FILES,
  GET_UPLOADED_FILES_SUMMARY,
  UPLOAD_FILES
} from '../queries/upload'

const { Title } = Typography

const MAX_FILES_PER_DAY = 30
const MAX_SIZE_PER_DAY = 1000000000

enum ETL_Statuses {
  Uploaded = 'UPLOADED',
  Processing = 'RUNNING',
  Complete = 'COMPLETED',
  Failed = 'FAILED'
}

export const UploadDatasets = () => {
  const { organization_key, workspace_key } = useParams()

  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const { data: uploadedFiles } = useQuery(GET_UPLOADED_FILES, {
    variables: {
      organization_key: organization_key as string,
      workspace_key: workspace_key as string
    },
    skip: !organization_key || !workspace_key
  })

  const { data: uploadedFilesSummary } = useQuery(GET_UPLOADED_FILES_SUMMARY, {
    variables: {
      organization_key: organization_key as string,
      workspace_key: workspace_key as string
    },
    skip: !organization_key || !workspace_key
  })

  const fileRegexps =
    uploadedFilesSummary?.get_uploaded_files_summary.allowed_types.map(
      (type: string) => new RegExp(type)
    )

  const { data: etlStatuses } = useQuery(GET_ETL_STATUS, {
    variables: {
      file_keys: uploadedFiles?.get_uploaded_files?.files as string[]
    },
    pollInterval: 30000, // Poll every 30 seconds
    skip: !uploadedFiles?.get_uploaded_files?.files
  })

  const handleFileListChange = (info: UploadChangeParam<UploadFile>) => {
    setFileList(info.fileList)
  }

  const [uploadFiles] = useLazyQuery(UPLOAD_FILES)

  const handleUpload = async () => {
    setIsUploading(true)

    // Client side validation
    const requestFileSize = fileList.reduce(
      (acc, file) => acc + (file.size as number),
      0
    )
    const recentlyUploadedFileSize =
      uploadedFilesSummary?.get_uploaded_files_summary.size_uploaded ||
      (0 as number)
    const recentlyUploadedFileCount =
      uploadedFilesSummary?.get_uploaded_files_summary.num_uploaded ||
      (0 as number)

    if (
      requestFileSize + recentlyUploadedFileSize > MAX_SIZE_PER_DAY ||
      fileList.length + recentlyUploadedFileCount > MAX_FILES_PER_DAY
    ) {
      api.error({
        message: 'Upload limit reached',
        description:
          'You have reached the maximum number of files or size per day.'
      })
    }

    const fileNames = fileList.map(file => file.name)
    for (const file of fileList) {
      if (!fileRegexps || !fileRegexps.some(regex => regex.test(file.name))) {
        api.error({
          message: 'Invalid file type',
          description: `The file ${file.name} does not match the allowed file types for this workspace.`
        })

        setIsUploading(false)
        return
      }
    }

    const { data: uploadResponse, error: uploadError } = await uploadFiles({
      variables: {
        organization_key: organization_key as string,
        workspace_key: workspace_key as string,
        files: fileNames
      }
    })

    if (uploadError) {
      api.error({
        message: 'Upload failed',
        description: uploadError.message
      })
    }

    const fileUploadPromises = []
    // Upload the files to presigned S3 URLs
    if (
      uploadResponse &&
      uploadResponse.upload_files.urls &&
      uploadResponse.upload_files.urls.length === fileNames.length
    ) {
      for (let i = 0; i < fileNames.length; i++) {
        const prseignedS3Url = uploadResponse.upload_files.urls[i]

        fileUploadPromises.push(
          fetch(prseignedS3Url, {
            method: 'PUT',
            body: fileList[i].originFileObj as Blob
          }).catch(e => {
            console.error(e)
          })
        )
      }
    }

    const responses = await Promise.all(fileUploadPromises)
    if (responses.some(response => !response || !response.ok)) {
      api.error({
        message: 'Upload failed',
        description: 'Failed to upload some files.'
      })
    } else {
      api.success({
        message: 'Upload successful',
        description: 'Files uploaded successfully.'
      })

      // Reset the file list
      setFileList([])
    }

    setIsUploading(false)
  }

  const columns: GridColDef<GridValidRowModel>[] = useMemo(
    () => [
      {
        field: 'file_key',
        headerName: 'File Name',
        flex: 3,
        renderCell: params => {
          // Extract file name from S3 full key
          const fileName = params.value.split('/').pop()

          return (
            <div className='h-full flex flex-row items-center'>
              <p className='text-sm'>{fileName}</p>
            </div>
          )
        }
      },
      {
        field: 'created_at',
        headerName: 'Date Uploaded',
        flex: 1,
        renderCell: params => {
          return (
            <div>
              {params.value
                ? dayjs
                    .utc(
                      isNaN(params.value) ? params.value : Number(params.value)
                    )
                    .format(DateFormats.DATE_TIME)
                : null}
            </div>
          )
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: params => {
          return (
            <div className='h-full flex flex-row items-center space-x-2'>
              {params.value === ETL_Statuses.Uploaded && (
                <CloudUpload className='w-4 h-4' />
              )}
              {params.value === ETL_Statuses.Processing && (
                <CircleDash className='w-4 h-4' />
              )}
              {params.value === ETL_Statuses.Complete && (
                <CheckmarkOutline className='w-4 h-4' />
              )}
              {params.value === ETL_Statuses.Failed && (
                <WarningAlt className='w-4 h-4' />
              )}
              <p className='text-sm'>{startCase(toLower(params.value))}</p>
            </div>
          )
        }
      }
    ],
    []
  )

  return (
    <BaseLayout className='py-4 flex flex-col gap-y-4'>
      {contextHolder}
      <div>
        <Title level={3}>Upload Datasets</Title>
        <Divider />
      </div>

      <Upload
        multiple
        className='w-full flex flex-col items-center justify-center border border-gray-300 rounded-lg p-10'
        customRequest={(options: any) => {
          // Override the default XHR behaviour since we attempt upload only after user confirms
          options.onSuccess('success')
        }}
        listType='text'
        onChange={handleFileListChange}
        showUploadList={true}
      >
        <div className='flex flex-col items-center justify-center cursor-pointer gap-y-2 pb-10'>
          <Button icon={<CloudUpload className='w-4 h-4' />}></Button>
          <span className='flex flex-row'>
            <span className='text-blue-500'>Click to upload</span>&nbsp;or drag
            and drop
          </span>
          <span className='text-gray-500'>
            {uploadedFilesSummary?.get_uploaded_files_summary.allowed_types.join(
              ', '
            )}{' '}
            (max {filesize(MAX_SIZE_PER_DAY)} per day)
          </span>
        </div>
      </Upload>
      <Button
        className='w-48'
        disabled={fileList.length === 0}
        loading={isUploading}
        onClick={handleUpload}
        type='primary'
      >
        Confirm Upload
      </Button>

      <div className='mt-8'>
        <div className='border-t border-x border-gray-300 rounded-t-lg p-4 space-y-1'>
          <p className='font-semibold'>Latest Files In Queue</p>
          <p className='text-xs italic'>
            {filesize(
              uploadedFilesSummary?.get_uploaded_files_summary.size_uploaded ||
                0
            )}{' '}
            uploaded in the last day
          </p>
        </div>
        <DataGridPro
          autoHeight
          columns={columns}
          loading={false}
          pagination={false}
          rows={etlStatuses?.get_etl_statuses || []}
        />
      </div>
    </BaseLayout>
  )
}
