import { Grid2 } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'

import { Divider } from '@/components'

import { BaseLayout } from '../../components/BaseLayout'
import { InsightsChartView } from '../charts/InsightsChartView'
import { Insights_Datasets_Table } from '../tables/Insights_Datasets_Table'

export const InsightDashboard = () => {
  return (
    <BaseLayout>
      <Divider className='mb-4 mt-1.5' />

      <div className='my-4'>
        <InsightsChartView />
      </div>

      <Grid2 size={12}>
        <ErrorBoundary fallback={null}>
          <Insights_Datasets_Table />
        </ErrorBoundary>
      </Grid2>
    </BaseLayout>
  )
}
