import { Checkbox, Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid'

import { use_app_dispatch, use_app_selector } from '../../../state/redux/hooks'
import {
  Dataset_Grid_Row,
  add_staged_dataset_table_rows,
  clear_staged_dataset_table_rows,
  select_grid_rows,
  select_staged_dataset_table_rows
} from '../../home/insights_slice'

export const Select_Column_Header = () => {
  const dispatch = use_app_dispatch()
  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )
  const dataset_rows = use_app_selector(select_grid_rows)
  const label = staged_dataset_table_rows.length ? 'Deselect All' : 'Select All'

  function handle_stage_datasets(rows: Dataset_Grid_Row[]) {
    dispatch(add_staged_dataset_table_rows(rows))
  }

  function handle_clear_all_staged_datasets() {
    dispatch(clear_staged_dataset_table_rows())
  }

  return (
    <GridActionsCellItem
      icon={
        <Tooltip placement='right' title={label}>
          <Checkbox
            checked={!!staged_dataset_table_rows.length && !dataset_rows.length}
            indeterminate={
              !!staged_dataset_table_rows.length && !!dataset_rows.length
            }
          />
        </Tooltip>
      }
      label={label}
      onClick={() =>
        staged_dataset_table_rows.length
          ? handle_clear_all_staged_datasets()
          : handle_stage_datasets(dataset_rows)
      }
      sx={{
        '.MuiCheckbox-root': { padding: 0 }
      }}
    />
  )
}
