import { PropsWithChildren } from 'react'

import { cn } from '../utils'

type BaseLayoutProps = PropsWithChildren<{
  className?: string
}>
export function BaseLayout(props: BaseLayoutProps) {
  const { children, className } = props
  return (
    <div className={cn('flex flex-col overflow-y-auto px-6', className)}>
      {children}
    </div>
  )
}
