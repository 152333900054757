import { IErrorMsg } from '@jupyterlab/services/lib/kernel/messages'
import Ansi from 'ansi-to-react'
import { saveAs } from 'file-saver'
import { nanoid } from 'nanoid'
import { useMemo, useState } from 'react'

import { Alert } from '@/components'

import { useTranslator } from '../i18n'
import { OhmOutputContainer } from './OhmOutputContainer'

type OhmStdOutProps = {
  stdErr: IErrorMsg['content']
  descriptor: string
  fileName: string
}
export const OhmStdErr = (props: OhmStdOutProps) => {
  const { stdErr, descriptor, fileName } = props

  const { translate } = useTranslator()
  const [outputId] = useState(`ohm-std-err-${nanoid()}`)

  const sanitizedFileName =
    fileName ?? `${descriptor.replace(/[^a-zA-Z0-9 ]/g, '')}.html`

  const renderedStream = useMemo(() => {
    const firstLine = stdErr.traceback[0]

    const traceback = firstLine.startsWith('-----')
      ? stdErr.traceback.slice(1)
      : stdErr.traceback

    return (
      <pre id={outputId}>
        {traceback.map((trace, idx) => (
          <div key={idx} style={{ display: 'block' }}>
            <Ansi>{trace}</Ansi>
          </div>
        ))}
      </pre>
    )
  }, [stdErr, outputId])

  const downloadOutput = () => {
    const nodeToDownload = document.getElementById(outputId)
    if (!nodeToDownload) return

    saveAs(
      new Blob([nodeToDownload.outerHTML], { type: 'text/html;charset=utf-8' }),
      sanitizedFileName
    )
  }

  return (
    <OhmOutputContainer
      actonBarClassName='bg-red-200 text-red-700 pb-4 border-red-300'
      buttonClassName='!bg-red-100/90 py-0 text-red-500 hover:!bg-red-100 hover:text-red-400 !border-none'
      containerClassName='bg-red-100'
      modalProps={{
        children: <Alert message={renderedStream} type='error' />,
        title: (
          <>
            {translate('errors.errorRunningCode')}: <code>{stdErr.evalue}</code>
          </>
        )
      }}
      onDownload={downloadOutput}
      outputContainerClassName='scrollbar-thin scrollbar-thumb-red-400'
    >
      {renderedStream}
    </OhmOutputContainer>
  )
}
