import { ArrowLeft } from '@carbon/icons-react'
import { Divider, Stack, Typography } from '@mui/material'

import { Rounded_Icon_Button } from './shared_styled_components'

type Menu_Header_Props = {
  title: string
  on_go_back?: () => void
}
export function Menu_Header(props: Menu_Header_Props) {
  const { title, on_go_back } = props

  const show_back_button = on_go_back != null
  return (
    <Stack marginBottom={1}>
      <Stack alignItems='center' direction='row' gap={1} padding={1}>
        {show_back_button && (
          <Rounded_Icon_Button onClick={on_go_back}>
            <ArrowLeft height={20} width={20} />
          </Rounded_Icon_Button>
        )}
        <Typography
          fontSize={theme => theme.typography.sm}
          fontWeight={600}
          variant='subtitle1'
        >
          {title}
        </Typography>
      </Stack>
      <Divider />
    </Stack>
  )
}
