const dict = {
  applyDatasets_one: 'Aplicar conjunto de datos',
  applyDatasets_other: 'Aplicar conjuntos de datos',
  clearAllDatasets: 'Borrar todos los conjuntos de datos {{suffix}}',
  clearAllDatasetsSuffix: '(incl. no aplicados)',
  clearAppliedDatasets_one: 'Borrar conjunto de datos aplicado',
  clearAppliedDatasets_other: 'Borrar conjuntos de datos aplicados',
  clearChat: 'Borrar chat',
  clearDatasets_one: 'Borrar conjunto de datos',
  clearDatasets_other: 'Borrar conjuntos de datos',
  confirmOverwriteSharedNotebook: {
    inquiry: '¿Desea sobrescribir el cuaderno existente con el nuevo?',
    title: 'Se encontró un cuaderno existente',
    warning:
      'Advertencia: Este cuaderno se guardará y compartirá con todos los usuarios que tengan acceso a este espacio de trabajo.'
  },
  datasetsChanged: 'Conjuntos de datos modificados',
  deselectAllDatasets: 'Deseleccionar todos los conjuntos de datos',
  doesNotDeselectDatasets: '(no deselecciona conjuntos de datos)',
  errors: {
    errorRunningCode: 'Error al ejecutar el código'
  },
  hotkeys: 'Atajos',
  jupyterLiteError: 'Error de JupyterLite',
  jupyterStatus: {
    busy: 'Ocupado',
    error: 'Error',
    ready: 'Espacio de trabajo listo',
    starting: 'Configurando espacio de trabajo'
  },
  multiInstanceUnavailable: {
    body: `
      La funcionalidad de IA solo está disponible en la ventana principal de la aplicación Byterat.
      Asegúrese de que esta sea la única ventana abierta con Byterat para interactuar con la IA.\n
      Si tiene varias ventanas abiertas, cierre todas las demás y actualice esta página.
      Si cree que está viendo este mensaje por error, intente actualizar la página o contacte al soporte para obtener ayuda.
    `,
    headline: 'Interacción con IA no disponible'
  },
  nAppliedDatasets_one: '{{count}} conjunto de datos aplicado',
  nAppliedDatasets_other: '{{count}} conjuntos de datos aplicados',
  nDatasetsSelected_one: '{{count}} conjunto de datos seleccionado ✅',
  nDatasetsSelected_other: '{{count}} conjuntos de datos seleccionados ✅',
  nLines_one: '{{count}} línea',
  nLines_other: '{{count}} líneas',
  nUnappliedDatasets_one: '{{count}} conjunto de datos no aplicado',
  nUnappliedDatasets_other: '{{count}} conjuntos de datos no aplicados',
  noDatasetsSelected: 'No se seleccionaron conjuntos de datos',
  noVisualizations: {
    potentialCodeIssue:
      'No se devolvió ninguna visualización. Esto puede indicar un problema con el código.',
    title: 'Visualización vacía'
  },
  notebookCell: 'Celda de cuaderno',
  notebookSelected: 'Cuaderno seleccionado',
  notebooks: 'Cuadernos',
  ohmAIPromptPlaceholder: '¿Qué le gustaría graficar?',
  ohmAiGeneratedCode: 'Código generado por ByteratAI',
  output: 'Resultado',
  outputAI: 'Resultado de la ejecución de IA',
  pleaseSelectDatasets:
    'Seleccione conjuntos de datos para usar con el cuaderno',
  plot: 'Gráfica',
  processing: 'Procesando...',
  processingAIOutput: 'Analizando pensamientos...',
  quickPrompts: {
    dischargeVCycle: 'Graficar capacidad de descarga vs ciclo',
    title: 'Prompts rápidos',
    voltageAndCurrentVTime:
      'Graficar voltaje y corriente vs tiempo para el ciclo 1',
    voltageVCapacity: 'Graficar voltaje vs capacidad para ciclos 1-9'
  },
  resultTable: 'Tabla de resultados',
  saveAsNotebook: {
    description:
      'Esto guardará la conversación actual como un cuaderno de Jupyter',
    note: 'Este cuaderno se guardará en el directorio <code>{{path}}</code> de sus Cuadernos.',
    saveFailure: 'Error al guardar el cuaderno',
    saveSuccessful: 'Cuaderno guardado con éxito',
    savedToPath: 'Cuaderno guardado en {{path}}',
    title: 'Guardar como cuaderno'
  },
  selectAllOnCurrentPage: 'Seleccionar todo (en la página actual)',
  selectNotebook: 'Seleccionar cuaderno',
  selectedNDatasets_one: '{{count}} conjunto de datos seleccionado',
  selectedNDatasets_other: '{{count}} conjuntos de datos seleccionados',
  selectedNotebookExecuting: 'Ejecutando cuaderno seleccionado',
  selectedNotebookNotExecutingNoDatasets:
    'No se ejecuta {{selectedNotebookName}} porque no hay conjuntos de datos seleccionados',
  selectingDatasetsX_one: 'Seleccionando conjuntos de datos: [{{x}}]',
  selectingDatasetsX_other: 'Seleccionando conjuntos de datos: [{{x}}]',
  selectingDatasets_one: 'Seleccionando conjunto de datos',
  selectingDatasets_other: 'Seleccionando conjuntos de datos',
  selectingNotebookX: 'Seleccionando cuaderno: {{x}}',
  showHotkeys: 'Mostrar atajos de teclado',
  spotlightSearch: {
    fileType: {
      ai: 'ByteratAI',
      shared: 'Compartido'
    },
    inputPlaceholder: 'Buscar cuadernos...',
    nCodeCells_one: '{{count}} celda de código',
    nCodeCells_other: '{{count}} celdas de código'
  },
  systemMessage: 'Mensaje del sistema',
  thinkingMessages: {
    meditating: 'Meditando...',
    pondering: 'Reflexionando...',
    stillInDeepThought: 'Todavía en un pensamiento muy profundo...',
    thinking: 'Pensando...'
  },
  thisIsAnInternalInteraction: 'Esta es una interacción interna',
  toggleDatasetFinder: 'Alternar buscador de conjuntos de datos',
  untitledChat: 'Chat sin título',
  warnings: {
    maxSelections:
      'No se pueden seleccionar más de {{max}} conjuntos de datos. Deseleccione algunos conjuntos de datos para seleccionar otros.'
  },
  welcomeComponent: {
    alreadyKnowYourWayAround:
      '¿Ya conoce cómo funciona? Haga clic para <btn>ocultar consejos al inicio</btn>.',
    happyExploring: '¡Feliz exploración!',
    helloAgain:
      '¡Hola de nuevo! ¿Cómo puedo ayudarle a analizar sus datos de baterías hoy?',
    ideas: {
      createCharts: {
        bullet1:
          'Cambiar el tamaño de fuente de las etiquetas de los ejes a 12',
        bullet2: 'Establecer el rango del eje y entre 0.01 y 1.42',
        title: 'Cree gráficos y ajuste los visuales a sus necesidades'
      },
      extractInsights: {
        bullet1:
          'Agrupe datos por <code>[metadata]</code> y calcule promedios, medianas, desviaciones estándar y otras estadísticas.',
        bullet2: 'Renderice una tabla que resalte datos de voltaje anómalos',
        title: 'Extraer información'
      },
      obtainViz: {
        bullet1: 'Graficar capacidad de descarga vs ciclo',
        bullet2:
          'Graficar datos de observación: corriente vs tiempo, voltaje vs tiempo, voltaje vs capacidad de descarga, voltaje vs capacidad de carga, y convertir unidades si es necesario.',
        title: 'Obtener visualizaciones'
      },
      selectDatasets: { title: 'Seleccione conjuntos de datos y...' }
    },
    intro:
      'Estoy aquí para ayudarle a analizar y visualizar sus datos de baterías. Aquí hay algunos consejos para comenzar',
    needIdeas: '¿Necesita ideas?',
    tips: {
      tip1: 'Seleccione conjuntos de datos y cuadernos usando los botones en la parte inferior izquierda.',
      tip2: 'Escriba preguntas o comandos abajo y presione Enter para interactuar con la IA.',
      tip3: 'Use atajos de teclado para acciones rápidas. Haga clic en el ícono de fuego para ver una lista.'
    },
    tipsHidden: 'Los consejos ya no se mostrarán al inicio.',
    title: '¡Bienvenido a ByteratAI!'
  },
  xApplied: '{{x}} (aplicado)',
  xRemoved: '{{x}} (eliminado)',
  xSelected: '{{x}} seleccionado ✅',
  xUnapplied: '{{x}} (no aplicado)'
}

export default dict
