import { ChartLine, ChartScatter } from '@carbon/icons-react'
import { useAtomValue } from 'jotai'
import { ComponentProps, ReactNode } from 'react'

import { Select } from '@/components'

import { cn } from '../../../../../../../utils'
import { CHART_TYPE } from '../../../../../../charts/chart_options'
import { visualizationAtomFamily } from '../../../../../store/report.molecule'
import { ChartConfig } from '../../../../../types'
import { useVisualizationItem } from '../../VisualizationContext'

const ChartTypeOptionLabel = (props: { icon: ReactNode; label: string }) => {
  const { icon, label } = props
  return (
    <div className='flex items-center gap-2 font-bold text-primary'>
      {icon}
      {label}
    </div>
  )
}

const ChartTypeOptions = [
  {
    value: CHART_TYPE.LINE,
    label: <ChartTypeOptionLabel icon={<ChartLine />} label='Line' />
  },
  {
    value: CHART_TYPE.LINE_MARKER,
    label: <ChartTypeOptionLabel icon={<ChartScatter />} label='Scatterplot' />
  }
]

/**
 * SelectChartType component renders the chart type select input for a chart visualization.
 */
type SelectChartTypeProps = Omit<
  ComponentProps<typeof Select>,
  'value' | 'onChange'
>
export function SelectChartType(props: SelectChartTypeProps) {
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()
  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const visualizationConfig = visualization.config as ChartConfig
  const value = visualizationConfig.chartType

  const onChange = (nextChartType: CHART_TYPE) => {
    updateVisualizationConfig({ chartType: nextChartType })
  }

  return (
    <Select
      className={cn(
        '[&>.ant-select-selector]:!px-1',
        `[&_.ant-select-selection-item]:text-sm`,
        `[&_.ant-select-selection-search-input]:text-sm`,
        `[&_.ant-select-selection-placeholder]:text-sm`
      )}
      onChange={onChange}
      options={ChartTypeOptions}
      value={value}
      variant='borderless'
      {...props}
    />
  )
}
