import { useQuery } from '@apollo/client'
import { Checkbox, Grid2, LinearProgress, styled } from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPaginationModel,
  GridSlots,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import { useAtom } from 'jotai'
import { compact, isEmpty, startCase } from 'lodash-es'
import { useEffect, useMemo, useRef, useState } from 'react'

import { Tooltip, notification } from '@/components'
import { NoResults } from '@/components/NoResults'
import {
  GET_DATASETS_ROWS_FOR_INSIGHTS_DASHBOARD,
  GET_DATASET_COLUMNS_FOR_INSIGHTS_DASHBOARD
} from '@/datasets/queries/get_datasets_for_insights_dashboard'
import {
  Filter_Logic_Operator,
  Filter_Type,
  Sort_Direction
} from '@/gql_generated/graphql'
import { useSession } from '@/hooks'
import {
  FIXED_COLUMN_FIELDS,
  FIXED_COLUMN_PROPERTIES,
  GRID_FILTER_OPERATOR_TO_FILTER_TYPE,
  NULLABLE_FILTER_TYPES,
  PROPERTY_TYPE_TO_GRID_COL_TYPE,
  datasetToDatasetGridRow
} from '@/insights/home/insights_slice'
import { Insights_Datasets_Table_Filter_Panel } from '@/insights/tables/Insights_Datasets_Table_Filter_Panel'

import { MAX_DATASET_SELECTIONS } from '../constants'
import { useTranslator } from '../i18n'
import { conversationAtomFamily } from '../molecule/ohm.molecule'
import { DatasetTableHeader } from './DatasetTableHeader'

const COLUMN_HEADER_HEIGHT = 80
const PAGE_SIZE_OPTIONS = [10, 25]

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    rowCount: number
    selectedRowCount: number
    onSearch: (new_value: string) => void

    loading: boolean
  }
}

type DatasetTableProps = {
  conversationId?: string
}

export const DatasetTable = (props: DatasetTableProps) => {
  const [notificationApi, notificationContextHolder] =
    notification.useNotification()

  const { conversationId } = props

  const { organizationId, workspaceId } = useSession()
  const { translate } = useTranslator()

  const apiRef = useGridApiRef()
  const [conversation, setConversation] = useAtom(
    conversationAtomFamily({ id: conversationId })
  )
  const { transientDatasetIds = [] } = conversation

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0]
  })
  const [queryString, setQueryString] = useState('')
  const [propertiesSort, setPropertiesSort] = useState<GridSortModel>()
  const [propertiesFilter, setPropertiesFilter] = useState<GridFilterModel>()

  const { data: columnData } = useQuery(
    GET_DATASET_COLUMNS_FOR_INSIGHTS_DASHBOARD,
    {
      skip: !organizationId || !workspaceId,
      variables: {
        organization_id: organizationId!,
        workspace_ids: [workspaceId!]
      }
    }
  )

  const propertiesSortForQuery = useMemo(() => {
    return (
      propertiesSort
        ?.filter(
          ({ sort }) =>
            sort === Sort_Direction.Asc || sort === Sort_Direction.Desc
        )
        .map(({ field, sort }) => ({
          direction: sort as Sort_Direction,
          property: field
        })) ?? [
        {
          direction: 'desc' as Sort_Direction,
          property: 'start_time'
        }
      ]
    )
  }, [propertiesSort])

  const propertiesFilterForQuery = useMemo(() => {
    const { items, logicOperator } = propertiesFilter ?? {}

    if (items == null || items.length === 0) {
      return
    }

    const filters = items
      .map(({ field, value, operator }) => {
        const filter_type = GRID_FILTER_OPERATOR_TO_FILTER_TYPE[operator]

        return {
          filter_type,
          key: field,
          value
        }
      })
      .filter(({ filter_type, value }) =>
        NULLABLE_FILTER_TYPES.includes(filter_type) ? true : !!value
      )
      .filter(({ filter_type, value }) =>
        filter_type === Filter_Type.IsAnyOf ? value.length > 0 : true
      )

    return {
      filters,
      logic_operator: (logicOperator ||
        Filter_Logic_Operator.And) as Filter_Logic_Operator
    }
  }, [propertiesFilter])

  const { data: datasetData, loading } = useQuery(
    GET_DATASETS_ROWS_FOR_INSIGHTS_DASHBOARD,
    {
      skip: !organizationId || !workspaceId,
      variables: {
        organization_id: organizationId!,
        page_from: paginationModel.page * paginationModel.pageSize,
        page_size: paginationModel.pageSize,
        query_string: queryString,
        properties_filter: propertiesFilterForQuery,
        properties_sort: propertiesSortForQuery,
        workspace_ids: [workspaceId!]
      }
    }
  )

  // Need to ensure row_count is not set to undefined between queries
  // otherwise the pagination model gets reset to page 0.
  const currentPageRows = useMemo(
    () => datasetData?.get_datasets_rows.rows ?? [],
    [datasetData?.get_datasets_rows.rows]
  )
  const totalRowCount = datasetData?.get_datasets_rows.result_count || undefined
  const rowCountRef = useRef(totalRowCount || 0)
  const rowCount = useMemo(() => {
    if (totalRowCount !== undefined) {
      rowCountRef.current = totalRowCount
    }
    return rowCountRef.current
  }, [totalRowCount])

  const fixedColumns: GridColDef<GridValidRowModel>[] = useMemo(
    () => [
      {
        field: 'Select',
        headerClassName: '!p-0',
        renderCell: ({ row }) => {
          return (
            <Checkbox
              checked={transientDatasetIds.includes(row.id)}
              onChange={event => {
                const { checked } = event.target
                setConversation(prev => {
                  const { transientDatasetIds = [] } = prev
                  if (
                    checked &&
                    transientDatasetIds.length >= MAX_DATASET_SELECTIONS
                  ) {
                    notificationApi.info({
                      message: translate('warnings.maxSelections', {
                        max: MAX_DATASET_SELECTIONS
                      })
                    })
                    return prev
                  }

                  const updatedTransientDatasetIds = checked
                    ? [...transientDatasetIds, row.id]
                    : transientDatasetIds.filter(id => id !== row.id)

                  return {
                    ...prev,
                    transientDatasetIds: updatedTransientDatasetIds
                  }
                })
              }}
            />
          )
        },
        renderHeader: () => {
          const isChecked = transientDatasetIds.length > 0
          const isIndeterminate =
            transientDatasetIds.length > 0 &&
            transientDatasetIds.length < rowCount
          return (
            <Tooltip
              placement='topLeft'
              title={
                isChecked
                  ? translate('deselectAllDatasets')
                  : translate('selectAllOnCurrentPage')
              }
            >
              <Checkbox
                checked={isChecked}
                indeterminate={isIndeterminate}
                onChange={event => {
                  const { checked } = event.target
                  setConversation(prev => ({
                    ...prev,
                    transientDatasetIds: checked
                      ? currentPageRows.map(row => row.id ?? '')
                      : []
                  }))
                }}
              />
            </Tooltip>
          )
        },
        type: 'actions',
        width: 50
      },
      ...FIXED_COLUMN_PROPERTIES
    ],
    [
      currentPageRows,
      notificationApi,
      rowCount,
      setConversation,
      transientDatasetIds,
      translate
    ]
  )

  const columnDefs = useMemo(() => {
    const datasetColumns = columnData?.get_dataset_columns.columns ?? []
    const has_cycle_count = datasetColumns.some(
      property => property.key === 'cycle_count'
    )
    const cycle_count_column_index = fixedColumns.findIndex(
      column => column.field === 'cycle_count'
    )
    if (cycle_count_column_index !== -1 && !has_cycle_count) {
      fixedColumns.splice(cycle_count_column_index, 1)
    }
    return datasetColumns.length
      ? fixedColumns.concat(
          datasetColumns
            .filter(
              ({ key }) =>
                !FIXED_COLUMN_PROPERTIES.map(({ field }) => field).includes(key)
            )
            .sort((a, b) => {
              if (a.pinned && !b.pinned) return -1
              if (!a.pinned && b.pinned) return 1
              return a.key.localeCompare(b.key)
            })
            .map(({ key, property_type, pinned }) => {
              const type = property_type
                ? PROPERTY_TYPE_TO_GRID_COL_TYPE[property_type]
                : 'string'
              return {
                field: key,
                flex: 1,
                headerName: startCase(key),
                minWidth: 150,
                pinned,
                type,
                valueGetter:
                  type === PROPERTY_TYPE_TO_GRID_COL_TYPE.date
                    ? value => (value != null ? new Date(value) : null)
                    : undefined
              }
            })
        )
      : []
  }, [columnData?.get_dataset_columns.columns, fixedColumns])

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({})

  useEffect(() => {
    const columnVisibility = columnData?.get_dataset_columns.columns ?? []
    if (isEmpty(columnVisibility)) return
    const visibilityModel = columnVisibility.reduce(
      (acc, property) => {
        if (!property.pinned && !FIXED_COLUMN_FIELDS.includes(property.key)) {
          acc[property.key] = false
        }
        return acc
      },
      {} as Record<string, boolean>
    )
    setColumnVisibilityModel(visibilityModel)
  }, [columnData?.get_dataset_columns.columns])

  const validDatasets = compact(
    currentPageRows.map(({ id, ...rest }) => {
      if (id == null) return null

      return datasetToDatasetGridRow({
        id,
        ...rest
      })
    })
  )

  return (
    <>
      {notificationContextHolder}
      <Styled_Dataset_Datagrid
        key={workspaceId}
        disableDensitySelector
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        pagination
        apiRef={apiRef}
        autosizeOptions={{
          columns: ['dataset_key'],
          includeHeaders: true,
          includeOutliers: true
        }}
        columnHeaderHeight={COLUMN_HEADER_HEIGHT}
        columns={columnDefs}
        columnVisibilityModel={columnVisibilityModel}
        density='compact'
        filterDebounceMs={500}
        filterMode='server'
        initialState={{ pinnedColumns: { left: ['Select'] } }}
        loading={loading}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        onFilterModelChange={setPropertiesFilter}
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setPropertiesSort}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationMode='server'
        paginationModel={paginationModel}
        rowCount={rowCount}
        rows={validDatasets || []}
        slotProps={{
          pagination: {
            labelRowsPerPage: null,
            rowsPerPageOptions: PAGE_SIZE_OPTIONS.map(size => ({
              label: translate('xRowsPerPage', { count: size }),
              value: size
            }))
          },
          toolbar: {
            loading,
            onSearch: setQueryString,
            rowCount,
            selectedRowCount: transientDatasetIds.length,
            showQuickFilter: true
          }
        }}
        slots={{
          filterPanel: () => (
            <Filter_Panel_Wrapper>
              <Insights_Datasets_Table_Filter_Panel />
            </Filter_Panel_Wrapper>
          ),
          loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
          noResultsOverlay: NoResults,
          noRowsOverlay: NoResults,
          toolbar: DatasetTableHeader as GridSlots['toolbar']
        }}
        sortingMode='server'
      />
    </>
  )
}

const Styled_Dataset_Datagrid = styled(DataGridPro)(({ theme, rowCount }) => ({
  '.MuiDataGrid-columnHeader': {
    '&.MuiDataGrid-columnHeader--pinnedLeft': {
      background: theme.palette.gray[100]
    },
    background: 'transparent'
  },
  '.MuiDataGrid-columnHeaders': {
    '[role=row]': {
      background: 'transparent'
    },
    background: theme.palette.gray[100],
    border: 'none',
    borderRadius: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  '.MuiDataGrid-footerContainer': {
    border: `1px solid ${theme.palette.divider}`,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius
  },
  '.MuiDataGrid-main': {
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    overflowY: 'auto'
  },
  '.MuiDataGrid-row': {
    ':hover': {
      '.MuiDataGrid-cell--pinnedLeft': {
        backgroundColor: `${theme.palette.blue[100]}!important`
      },
      backgroundColor: 'none'
    }
  },

  '.MuiTablePagination-root': {
    '.MuiTablePagination-toolbar': {
      '.MuiInputBase-root': {
        background: 'transparent',
        border: 0,
        boxShadow: 'none',
        margin: 0
      },
      '.MuiSelect-select': {
        border: 0
      },
      '.MuiTablePagination-displayedRows': {
        flex: 1,
        textAlign: 'right'
      },
      '.MuiTablePagination-spacer': {
        display: 'none'
      },
      border: 0
    },
    overflow: 'hidden',
    width: '100%'
  },
  border: 0,
  borderRadius: theme.shape.borderRadius,
  height: 'calc(100% - 40px)',
  maxWidth: '100%',
  minHeight: rowCount === 0 ? '180px' : 'auto'
}))

const Filter_Panel_Wrapper = styled(Grid2)(({ theme }) => ({
  maxWidth: 420,
  padding: theme.spacing(0.5)
}))
