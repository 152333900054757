import {
  IDisplayDataMsg,
  IErrorMsg,
  IStreamMsg
} from '@jupyterlab/services/lib/kernel/messages'
import { CollapseProps } from 'antd'
import { compact, isEmpty, isEqual, partition } from 'lodash-es'
import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { Fragment } from 'react/jsx-runtime'
import scrollIntoView from 'scroll-into-view-if-needed'

import { Collapse, Spin } from '@/components'
import { cn } from '@/utils'

import { useTranslator } from '../i18n'
import {
  OhmAiMessage,
  OhmAiMessageType,
  OhmUserInteractionMessage
} from '../types'
import { OhmCode } from './OhmCode'
import { OhmImage } from './OhmImage'
import { OhmStdErr } from './OhmStdErr'
import { OhmStdOut } from './OhmStdOut'
import { OhmTable } from './OhmTable'
import { OhmThinking } from './OhmThinking'
import { OhmUserMessage } from './OhmUserMessage'

type OhmPromptFeedProps = {
  parentContainerRef?: RefObject<HTMLDivElement>
  anchorRef?: RefObject<HTMLDivElement>
  promptId: string
  messages: OhmAiMessage[]
  active?: boolean
  isLastPrompt?: boolean
}

const defaultClassNames = {
  body: cn(
    'scrollbar-thumb-rounded-md scrollbar-thumb-gray-400',
    'flex justify-start overflow-hidden !p-0 text-xs',
    'overflow-auto !rounded-t-none'
  ),
  header: 'text-xs !items-center pl-4 transition-all duration-300'
}

const DEFAULT_COLLAPSED = [
  OhmAiMessageType.OhmThinking,
  OhmAiMessageType.OhmPythonCode,
  OhmAiMessageType.OhmJupyterCell
]

export const OhmPromptFeed = (props: OhmPromptFeedProps) => {
  const {
    promptId,
    messages,
    active = false,
    isLastPrompt = false,
    parentContainerRef,
    anchorRef
  } = props

  const { translate } = useTranslator()

  const parentSize = useResizeDetector({
    targetRef: parentContainerRef
  })

  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const [userMessages, setUserMessages] = useState<OhmUserInteractionMessage[]>(
    []
  )
  const [ohmMessages, setOhmMessages] = useState<OhmAiMessage[]>([])

  const containerRef = useRef<HTMLDivElement>(null)
  const innerContainerRef = useRef<HTMLDivElement>(null)

  const isInternal = userMessages.some(msg => msg.internal)
  const hasUserMessages = !isEmpty(userMessages)

  const containersReady =
    containerRef.current != null && parentContainerRef?.current != null

  useEffect(() => {
    const [newUserMessages, newOhmMessages] = partition(
      messages,
      message => message.type === OhmAiMessageType.UserMessage
    )

    setUserMessages(prev =>
      isEqual(prev, newUserMessages) ? prev : newUserMessages
    )
    setOhmMessages(prev =>
      isEqual(prev, newOhmMessages) ? prev : newOhmMessages
    )
  }, [messages])

  useLayoutEffect(() => {
    if (!hasUserMessages || !containersReady) return

    const visibleHeight = getComputedStyle(
      parentContainerRef.current
    ).getPropertyValue('height')

    if (isLastPrompt) {
      containerRef.current.style.setProperty('min-height', visibleHeight)

      if (anchorRef?.current != null) {
        scrollIntoView(anchorRef.current, {
          behavior: 'smooth',
          block: 'end',
          inline: 'end'
        })
      }
      return
    }

    // When the prompt is not the last one, we need to calculate the height of the
    // inner container to a discrete value to ensure we get a smooth transition.
    // However, we also need to remove the min-height property after the transition,
    // as these are collapsib;e containeres that will change height, and we need to
    // ensure they can shrink back to their natural height when collapsed. This is
    // done by setting the min-height to auto after a short delay of 150ms, which
    // is the duration of the transition (per tailwind).
    if (!isLastPrompt && innerContainerRef.current != null) {
      containerRef.current.style.setProperty(
        'min-height',
        `${innerContainerRef.current.clientHeight ?? 0}px`
      )
      setTimeout(() => {
        if (containerRef.current == null) return
        containerRef.current.style.setProperty('min-height', 'auto')
      }, 150 + 20) // Duration matching tailwind's default for `transition-all`
    }
  }, [
    active,
    isInternal,
    parentContainerRef,
    anchorRef,
    hasUserMessages,
    isLastPrompt,
    containersReady,
    parentSize
  ])

  useEffect(() => {
    const newExpandedIndexes = ohmMessages
      .map((msg, idx) => (DEFAULT_COLLAPSED.includes(msg.type) ? -1 : `${idx}`))
      .filter(i => i !== -1)

    setExpandedKeys(prev => {
      const newKeys = newExpandedIndexes.filter(index => !prev.includes(index))
      return newKeys.length > 0 ? [...prev, ...newKeys] : prev
    })
  }, [ohmMessages])

  const renderedUserMessages = userMessages.map((message, index) => {
    return <OhmUserMessage key={index} loading={active} message={message} />
  })

  const ohmMessageItems: CollapseProps['items'] = compact(
    ohmMessages.map((message, index) => {
      const { type, content } = message
      const isLast = index === ohmMessages.length - 1

      const defaultItemProps = {
        classNames: {
          ...defaultClassNames,
          body: cn(
            defaultClassNames.body,
            isLast && !active ? '!rounded-b-md' : ''
          )
        },
        key: `${index}`
      }

      switch (type) {
        case OhmAiMessageType.OhmTextResponse:
          return {
            ...defaultItemProps,
            children: (
              <div className='px-4 pt-2'>
                <pre className='whitespace-pre-wrap'>{content}</pre>
              </div>
            ),
            label: <div>{translate('systemMessage')}</div>
          }
        case OhmAiMessageType.OhmThinking:
          return {
            ...defaultItemProps,
            collapsible: 'disabled',
            label: <OhmThinking />,
            showArrow: false
          }
        case OhmAiMessageType.OhmPythonCode:
        case OhmAiMessageType.OhmJupyterCell:
          const contentStr = content?.toString() ?? ''
          const lines = contentStr.split('\n').length

          return {
            ...defaultItemProps,
            children: (
              <OhmCode
                code={contentStr}
                descriptor={translate('ohmAiGeneratedCode')}
              />
            ),
            label: (
              <div className='flex flex-1 items-baseline gap-x-1'>
                {type === OhmAiMessageType.OhmJupyterCell
                  ? translate('notebookCell')
                  : translate('ohmAiGeneratedCode')}
                <span className='text-xxs'>
                  ({translate('nLines', { count: lines })})
                </span>
              </div>
            )
          }
        case OhmAiMessageType.JupyterVisualization:
          const _message = content as IDisplayDataMsg
          const msgContent = _message.content
          const pngData = msgContent.data['image/png']
          const jpgData = msgContent.data['image/jpeg']
          const imgData = pngData || jpgData
          const htmlData = msgContent.data['text/html']?.toString()
          const descriptor = msgContent.data['text/plain']?.toString()

          const hasVisualization = imgData || htmlData
          if (!hasVisualization) {
            return {
              ...defaultItemProps,
              children: (
                <pre className='p-4 italic'>
                  {translate('noVisualizations.potentialCodeIssue')}
                </pre>
              ),
              label: translate('noVisualizations.title')
            }
          }

          if (imgData) {
            const fileType = pngData ? 'png' : 'jpeg'
            const sanitizedFileName = `${descriptor.replace(/[^a-zA-Z0-9 ]/g, '')}.${fileType}`
            return {
              ...defaultItemProps,
              children: (
                <OhmImage
                  descriptor={descriptor}
                  fileName={sanitizedFileName}
                  format={fileType}
                  imgData={imgData}
                />
              ),
              label: translate('plot')
            }
          }

          const isHtmlTable = htmlData?.includes('<table') ?? false
          const tableDescriptor = isHtmlTable
            ? translate('resultTable')
            : descriptor
          return {
            ...defaultItemProps,
            children: (
              <OhmTable descriptor={tableDescriptor} htmlData={htmlData} />
            ),
            label: tableDescriptor
          }
        case OhmAiMessageType.JupyterStdOut:
          const stdOut = content as IStreamMsg[]
          return {
            ...defaultItemProps,
            children: (
              <OhmStdOut
                descriptor={translate('output')}
                fileName='output'
                isError={false}
                stdOut={stdOut}
              />
            ),
            label: translate('outputAI')
          }
        case OhmAiMessageType.JupyterError:
          const isActive = expandedKeys.includes(`${index}`)
          const errMsg = content as IErrorMsg
          const stdErr = errMsg.content
          return {
            ...defaultItemProps,
            children: (
              <OhmStdErr
                descriptor={translate('error')}
                fileName='error'
                stdErr={stdErr}
              />
            ),
            classNames: {
              ...defaultItemProps.classNames,
              body: cn(defaultItemProps.classNames.body, 'bg-red-100'),
              header: cn(
                defaultItemProps.classNames.header,
                '!text-red-500 !font-medium',
                { '!rounded-b-none': isActive }
              )
            },
            label: (
              <>
                <span className='mr-1 font-semibold'>
                  {translate('errors.errorRunningCode')}
                </span>
                <code>{stdErr.evalue}</code>
              </>
            )
          }

        default:
          return null
      }
    })
  )

  const promptIsThinking = ohmMessages.some(
    message => message.type === OhmAiMessageType.OhmThinking
  )
  if (active && !isInternal && !promptIsThinking) {
    ohmMessageItems.push({
      children: <></>,
      classNames: {
        ...defaultClassNames,
        header: cn(defaultClassNames.header, 'text-gray-500')
      },
      collapsible: 'disabled',
      key: 'processing',
      label: (
        <div className='flex items-center gap-x-2 text-gray-500'>
          <Spin /> {translate('processingAIOutput')}
        </div>
      ),
      showArrow: false
    })
  }

  const onCollapseStateChange = (keys: string[]) => {
    setExpandedKeys(keys)
  }

  const renderedOhmMessages =
    ohmMessages.length === 1 &&
    ohmMessages[0].type === OhmAiMessageType.OhmTextResponse ? (
      <div className='flex w-fit max-w-[60vw] flex-col gap-2 px-4 pt-2 text-sm'>
        {ohmMessages[0].content}
      </div>
    ) : (
      <Collapse
        ghost
        activeKey={expandedKeys}
        className={cn('w-fit max-w-[60vw]')}
        expandIconPosition='start'
        items={ohmMessageItems}
        onChange={onCollapseStateChange}
        size='small'
      />
    )

  const allMessages = [...renderedUserMessages, renderedOhmMessages]

  if (isEmpty(messages)) return null
  return (
    <div
      ref={containerRef}
      data-prompt-container
      className='transition-all' // If changing the durtion of this transition, also change the transition duration in the layout effect
      id={promptId}
    >
      <div ref={innerContainerRef} className='flex flex-col gap-y-2'>
        {allMessages.map((message, index) => (
          <Fragment key={index}>{message}</Fragment>
        ))}
      </div>
    </div>
  )
}
