import { type InputProps, InputRef } from 'antd'
import { debounce } from 'lodash-es'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'

import { Input } from '@/components'

type DebouncedInputProps = Omit<InputProps, 'onChange'> & {
  value?: string
  onChange: (value: string) => void
}

export const DebouncedInput = forwardRef<InputRef, DebouncedInputProps>(
  (props, ref) => {
    const { value, onChange, ...rest } = props
    const [internalValue, setInternalValue] = useState(value)

    const debouncedChange = useMemo(
      () =>
        debounce((val: string) => {
          onChange(val)
        }, 250),
      [onChange]
    )

    useEffect(() => {
      setInternalValue(value)
    }, [value])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInternalValue(e.target.value)
      debouncedChange(e.target.value)
    }

    return (
      <Input
        ref={ref}
        onChange={handleChange}
        value={internalValue}
        {...rest}
      />
    )
  }
)

DebouncedInput.displayName = 'DebouncedInput'
