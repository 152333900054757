import { useMutation, useQuery } from '@apollo/client'
import { Add, TrashCan } from '@carbon/icons-react'
import { styled } from '@mui/material'
import {
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowsProp,
  GridSlots,
  GridToolbarContainer
} from '@mui/x-data-grid'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { Select, Spin, Tooltip, message } from 'antd'
import { nanoid } from 'nanoid'
import { useMemo, useState } from 'react'

import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'

import { BaseLayout, Button, Typography } from '../../components'
import { Numeric_Property_Types } from '../charts'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../charts/cycle_summaries/queries/get_cycle_summary_properties'
import { PropertyKeyOption } from '../reports/types'
import {
  LOAD_METRIC_TARGET_LIMITS,
  SAVE_METRIC_TARGET_LIMITS
} from './queries/load_metric_target_limits'

const { Title } = Typography

const COLUMN_HEADER_HEIGHT = 80

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    setRows: (rows: any) => void
  }
}

export default function CellSpecifications() {
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const { data: cycleSummariesData } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id
  })
  // Compute the observation property options
  const observationPropertyOptions = useMemo(() => {
    return (
      (cycleSummariesData as any)?.get_cycle_summary_properties
        ?.dataset_properties || []
    )
      .filter((property: any) => Numeric_Property_Types.includes(property.type))
      .map(({ key, label }: any) => ({
        value: key,
        label
      }))
  }, [cycleSummariesData])

  const [rows, setRows] = useState<GridRowsProp>([])
  const [changesMade, setChangesMade] = useState(false)
  const { loading: metricTargetLimitsLoading } = useQuery(
    LOAD_METRIC_TARGET_LIMITS,
    {
      variables: {
        organization_id: organization_id || '',
        workspace_id: workspace_id || ''
      },
      skip: !organization_id || !workspace_id,
      onCompleted: newData => {
        setRows(
          newData.load_metric_target_limits.metric_target_limits.map(
            _limit => ({
              ..._limit,
              id: nanoid()
            })
          )
        )
      }
    }
  )

  const [saveMetricTargets, { loading: saveLoading }] = useMutation(
    SAVE_METRIC_TARGET_LIMITS,
    {
      refetchQueries: [LOAD_METRIC_TARGET_LIMITS]
    }
  )
  const handleSave = () => {
    saveMetricTargets({
      variables: {
        organization_id: organization_id || '',
        workspace_id: workspace_id || '',
        metric_target_limits: rows.map(_row => {
          return {
            metric_name: _row.metric_name,
            lower_limit: _row.lower_limit,
            upper_limit: _row.upper_limit
          }
        }) as any
      },
      onCompleted: d => {
        setChangesMade(false)
        message.success('Saved')
      }
    })
  }

  const columnDefs: GridColDef[] = [
    {
      field: 'metric_name',
      headerName: 'Name',
      flex: 1,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        const label = observationPropertyOptions.find(
          (_option: PropertyKeyOption) => _option.value === params.value
        )?.label

        return label
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <Select
            autoFocus
            defaultOpen={true}
            defaultValue={params.value}
            onChange={value => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value
              })
            }}
            options={observationPropertyOptions}
            style={{ width: '100%' }}
          />
        )
      }
    },
    {
      field: 'lower_limit',
      headerName: 'Lower',
      type: 'number',
      editable: true,
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'upper_limit',
      headerName: 'Upper',
      type: 'number',
      editable: true,
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <Button
            danger
            className='align-middle'
            onClick={() => {
              setRows((oldRows: any) =>
                oldRows.filter((row: any) => row.id !== params.id)
              )

              setChangesMade(true)
            }}
            size='small'
            type='text'
          >
            <TrashCan />
          </Button>
        )
      }
    }
  ]

  function EditToolbar(props: any) {
    const { setRows } = props

    const handleClick = () => {
      const id = nanoid()

      setRows((oldRows: any) => [...oldRows, { id }])
    }

    return (
      <GridToolbarContainer className='mb-1'>
        <Button
          icon={<Add className='-mx-2 -mr-1 w-5 h-5' />}
          onClick={handleClick}
          type='primary'
        >
          Add record
        </Button>
      </GridToolbarContainer>
    )
  }

  return (
    <BaseLayout className='py-4 flex flex-col gap-y-4'>
      <Spin spinning={metricTargetLimitsLoading}>
        <div className='flex flex-row justify-between'>
          <Title level={3}>Cell Specifications</Title>

          <div className='flex-grow' />

          <div className='flex gap-2'>
            <Tooltip
              placement='left'
              title={
                changesMade === false
                  ? 'Click on table rows to make changes'
                  : undefined
              }
            >
              <Button
                disabled={
                  changesMade === false ||
                  !!rows.find(
                    _row =>
                      _row.metric_name === undefined ||
                      _row.lower_limit === undefined ||
                      _row.upper_limit === undefined
                  )
                }
                loading={saveLoading}
                onClick={handleSave}
                type='primary'
              >
                Save
              </Button>
            </Tooltip>
          </div>
        </div>

        <div>
          <Styled_Dataset_Datagrid
            disableColumnMenu
            disableDensitySelector
            disableRowSelectionOnClick
            hideFooter
            pagination
            columnHeaderHeight={COLUMN_HEADER_HEIGHT}
            columns={columnDefs}
            density='compact'
            processRowUpdate={updatedRow => {
              setRows(prevRows => {
                return prevRows.map(row => {
                  if (row.id === updatedRow.id) {
                    // if at least one value is different, set changesMade to true
                    if (
                      updatedRow.metric_name &&
                      updatedRow.lower_limit !== undefined &&
                      updatedRow.upper_limit !== undefined &&
                      (row.metric_name !== updatedRow.metric_name ||
                        row.lower_limit !== updatedRow.lower_limit ||
                        row.upper_limit !== updatedRow.upper_limit)
                    ) {
                      setChangesMade(true)
                    }

                    return updatedRow
                  }

                  return row
                })
              })

              return updatedRow
            }}
            rowCount={rows.length}
            rows={rows}
            slotProps={{
              toolbar: { setRows }
            }}
            slots={{
              toolbar: EditToolbar as GridSlots['toolbar']
            }}
          />
        </div>
      </Spin>
    </BaseLayout>
  )
}

const Styled_Dataset_Datagrid = styled(DataGridPro)(({ theme }) => ({
  height: 'max-content',
  maxHeight: 'calc(100vh - 140px)',
  borderRadius: theme.shape.borderRadius,
  '.MuiDataGrid-container--top': {
    backgroundColor: `${theme.palette.gray[100]}!important`
  },
  '.MuiDataGrid-main': {
    overflowY: 'auto'
  },
  '.MuiDataGrid-row:hover': {
    background: theme.palette.gray[50]
  }
}))
