import { useQuery } from '@apollo/client'
import { useSetAtom } from 'jotai'
import { useAtomCallback } from 'jotai/utils'
import { isEqual } from 'lodash-es'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router'

import { BaseLayout, Spin } from '@/components'
import { mapChartConfigV0ToV2 } from '@/insights/charts/transform'
import { omitNullishOrEmptyValues } from '@/utils'

import { LOAD_REPORT } from '../../queries/reports'
import {
  VisualizationType,
  reportAtomFamily,
  visualizationAtomFamily
} from '../../store/report.molecule'
import { FullReportAtom, ReportRouteParams, Visualization } from '../../types'
import { ReportItem } from './ReportItem'

export const ReportView = () => {
  const { reportId } = useParams<ReportRouteParams>()
  const setReport = useSetAtom(reportAtomFamily({ id: reportId }))

  const [parsedReport, setParsedReport] = useState<FullReportAtom>()

  const { visualizations = [] } = parsedReport ?? {}

  // Reset the visualization to the original state by setting the atom to the original state
  // from the parsed report (from the backend)
  const resetVisualization = useAtomCallback(
    useCallback(
      (get, set, visualizationId: string) => {
        const visualizationItem = get(
          visualizationAtomFamily({ id: visualizationId })
        )
        if (!visualizationItem) return

        const parsedReportVisualization =
          visualizations?.find(v => v.id === visualizationId) ?? {}

        set(visualizationAtomFamily({ id: visualizationId }), {
          ...visualizationItem,
          ...parsedReportVisualization
        })
      },
      [visualizations]
    )
  )

  // Load the report data from the backend
  useQuery(LOAD_REPORT, {
    variables: {
      id: reportId as string
    },
    onCompleted: data => {
      if (reportId == null) return // This is a safety check, but should never happen

      const { report_query, schema_version } = data?.load_report ?? {}
      const _parsedReport = JSON.parse(report_query ?? '{}') as FullReportAtom

      _parsedReport.visualizations?.forEach((v: Visualization) => {
        if (v.type === VisualizationType.Chart) {
          if (schema_version == null || schema_version <= 1) {
            console.log('Adapting chart schema from v0 to v2')
            v.config = mapChartConfigV0ToV2(v.config)
          }

          // When duplicating a chart, the config ID should be the same as the visualization ID...
          // but there are cases where it coud've gone awry... so this handles those cases
          v.config = {
            ...omitNullishOrEmptyValues(v.config),
            id: v.id
          }
        }
        visualizationAtomFamily(v)
      })

      setReport(prev => {
        const next = {
          id: reportId,
          organizationId: _parsedReport.organizationId,
          workspaceId: _parsedReport.workspaceId,
          title: _parsedReport.title,
          datasetIds: _parsedReport.datasetIds,
          schemaVersion: schema_version ?? 0,
          visualizationIds: _parsedReport.visualizations.map(v => v.id)
        }

        // Only update the atom if the new report data is different from the previous
        return isEqual(prev, next) ? prev : next
      })

      setParsedReport(_parsedReport)
    },
    skip: reportId == null
  })

  return (
    <BaseLayout className='py-4'>
      {parsedReport == null ? (
        <Spin />
      ) : (
        <ReportItem
          onResetVisualization={resetVisualization}
          report={parsedReport}
        />
      )}
    </BaseLayout>
  )
}
