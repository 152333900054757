import { useQuery } from '@apollo/client'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useParams } from 'react-router'

import { Normalize_Type, Property_Option } from '@/gql_generated/graphql'
import { AGGREGATION_TYPE_NONE } from '@/insights/charts'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '@/insights/charts/cycle_summaries/queries/get_cycle_summary_properties'
import { InsightsChartTypes } from '@/insights/charts/types'
import { format_property_label } from '@/insights/charts/utils'
import { PLOTTABLE_PROPERTY_TYPES } from '@/insights/types'

import { reportAtomFamily } from '../../../../store/report.molecule'
import { ReportRouteParams } from '../../../../types'
import { SelectChartType } from './controls/SelectChartType'
import {
  PanelCycleFilters,
  PanelGroupBy,
  PanelXAxis,
  PanelYAxis
} from './panels'

export function MenuDatasetChart() {
  const { reportId } = useParams<ReportRouteParams>()
  const report = useAtomValue(reportAtomFamily({ id: reportId }))
  const { workspaceId, organizationId } = report

  const {
    data: cycleSummaryPropertiesData,
    loading: cycleSummaryPropertiesLoading
  } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organizationId as string
    },
    skip: !organizationId || !workspaceId
  })

  const propertyOptions =
    cycleSummaryPropertiesData?.get_cycle_summary_properties?.dataset_properties
      ?.filter(
        (property: Property_Option) =>
          property.type && PLOTTABLE_PROPERTY_TYPES.includes(property.type)
      )
      .map(v => ({
        ...v,
        label: format_property_label(v.label, v.units)
      })) || []

  const datasetProperties: Property_Option[] = useMemo(
    () =>
      cycleSummaryPropertiesData?.get_cycle_summary_properties?.dataset_properties.map(
        v => ({
          ...v,
          label: format_property_label(v.label, v.units)
        })
      ) || [],
    [cycleSummaryPropertiesData]
  )

  const normalizeByOptions = useMemo(() => {
    return datasetProperties
      .filter(({ type }) => type && PLOTTABLE_PROPERTY_TYPES.includes(type))
      .map(({ key, label, units }: Property_Option) => ({
        key,
        units,
        type: Normalize_Type.DatasetProperty,
        group: 'Dataset Metrics',
        label: format_property_label(label, units)
      }))
  }, [datasetProperties])

  const groupByPropertyOptions = useMemo(
    () =>
      datasetProperties.map(v => ({
        ...v,
        label: format_property_label(v.label, v.units)
      })),
    [datasetProperties]
  )

  const aggregateByOptions = [AGGREGATION_TYPE_NONE]

  return (
    <>
      <PanelXAxis
        loading={cycleSummaryPropertiesLoading}
        normalizeOptions={normalizeByOptions}
        propertyOptions={propertyOptions}
      />
      <PanelYAxis
        loading={cycleSummaryPropertiesLoading}
        normalizeOptions={normalizeByOptions}
        propertyOptions={propertyOptions}
      />
      <PanelGroupBy
        aggregateByOptions={aggregateByOptions}
        groupByOptions={groupByPropertyOptions}
        loading={cycleSummaryPropertiesLoading}
        metricType={InsightsChartTypes.CELL_METRICS}
      />
      <PanelCycleFilters />
      <SelectChartType disabled />
    </>
  )
}
