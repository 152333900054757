import { Download, Maximize } from '@carbon/icons-react'
import { ModalProps } from 'antd'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { useBoolean } from 'usehooks-ts'

import { cn } from '@/utils'

import { useTranslator } from '../i18n'
import { OhmButton } from './OhmButton'
import { OhmOutputModal } from './OhmOutputModal'

type OhmOutputContainerProps = PropsWithChildren<{
  onDownload: () => void
  showActions?: boolean
  containerClassName?: clsx.ClassValue
  actonBarClassName?: clsx.ClassValue
  buttonClassName?: clsx.ClassValue
  outputContainerClassName?: clsx.ClassValue
  modalProps?: ModalProps
}>
export const OhmOutputContainer = (props: OhmOutputContainerProps) => {
  const {
    onDownload,
    children,
    containerClassName = '',
    actonBarClassName = '',
    buttonClassName = '',
    outputContainerClassName = '',
    showActions = true,
    modalProps
  } = props

  const {
    value: modalOpen,
    setTrue: showModal,
    setFalse: hideModal
  } = useBoolean(false)

  const { translate } = useTranslator()

  return (
    <>
      <div
        className={cn(
          'flex items-stretch justify-between overflow-hidden',
          containerClassName
        )}
      >
        {showActions && (
          <div
            className={cn(
              'flex flex-col items-center gap-y-1 border-r border-gray-200 bg-gray-100 p-0.5 !pb-4',
              actonBarClassName
            )}
          >
            <div className='flex flex-col gap-1 p-0.5'>
              <OhmButton
                buttonProps={{
                  className: cn(buttonClassName),
                  icon: <Download size={14} />,
                  onClick: onDownload
                }}
                tooltipProps={{
                  placement: 'right',
                  title: translate('Download')
                }}
              />
              <OhmButton
                buttonProps={{
                  className: cn(buttonClassName),
                  icon: <Maximize size={12} />,
                  onClick: showModal
                }}
                tooltipProps={{
                  placement: 'right',
                  title: translate('expand')
                }}
              />
            </div>
          </div>
        )}
        <div
          className={cn(
            'scrollbar-thumb-rounded-md max-h-[400px] flex-1 overflow-auto p-4 scrollbar-thin scrollbar-thumb-gray-400',
            outputContainerClassName
          )}
        >
          {children}
        </div>
      </div>
      <OhmOutputModal
        onCancel={hideModal}
        onDownload={onDownload}
        open={modalOpen}
        {...modalProps}
      />
    </>
  )
}
