import { CatalogPublish } from '@carbon/icons-react'
import { Contents } from '@jupyterlab/services'
import { ButtonProps } from 'antd'
import { useAtom } from 'jotai'
import { useHotkeys } from 'react-hotkeys-hook'
import { Trans } from 'react-i18next'
import { useBoolean } from 'usehooks-ts'

import { Button, Input, Popconfirm, Tooltip } from '@/components'
import { useSession } from '@/hooks'
import { I18nNamespaces } from '@/i18n'

import { useTranslator } from './i18n'
import { conversationAtomFamily } from './molecule/ohm.molecule'
import { convertToNotebook } from './utils/convertToNotebook'

type SaveAsNotebookProps = ButtonProps & {
  conversationId: string
  path?: string
  onSave: (model: Contents.IModel) => void
}

export const SaveAsNotebook = (props: SaveAsNotebookProps) => {
  const {
    className,
    conversationId,
    path = 'temp',
    onSave,
    ...buttonProps
  } = props

  const { translate } = useTranslator()
  const { workspaceKey } = useSession()
  const [conversation, setConversation] = useAtom(
    conversationAtomFamily({ id: conversationId })
  )

  const defaultTitle = `${translate('untitledChat')} ${conversationId}`

  const {
    value: confirmVisible,
    setFalse: hideConfirm,
    setTrue: showConfirm,
    toggle: toggleConfirmVisible
  } = useBoolean(false)

  const saveConversation = async () => {
    const notebookData = convertToNotebook(conversation.messages)
    notebookData.metadata = {
      ...notebookData.metadata,
      $conversationDescription: conversation.description,
      $conversationId: conversationId,
      $conversationTitle: conversation.title,
      $owner: workspaceKey?.toLowerCase()
    }

    const contentsModel: Contents.IModel = {
      content: notebookData,
      created: new Date().toISOString(),
      format: 'json',
      last_modified: new Date().toISOString(),
      mimetype: 'application/json',
      name: `${conversation.title || defaultTitle}.ipynb`,
      path: `${path}/${conversation.title || defaultTitle}.ipynb`,
      type: 'notebook',
      writable: true
    }

    onSave(contentsModel)
    hideConfirm()
  }

  useHotkeys('meta+shift+s, ctrl+shift+s', showConfirm, {
    enableOnFormTags: true,
    preventDefault: true
  })

  return (
    <>
      <Popconfirm
        classNames={{
          body: 'w-[500px] [&_.ant-popconfirm-message-text]:flex-1'
        }}
        description={
          <div className='flex flex-col gap-2 py-2'>
            <div>
              {translate('saveAsNotebook.description')}
              <div className='my-2 flex gap-x-1 text-xs'>
                <div className='font-bold'>{translate('note')}:</div>
                <div>
                  <Trans
                    components={{
                      code: <code className='font-medium' />
                    }}
                    i18nKey='saveAsNotebook.note'
                    ns={I18nNamespaces.OhmAi}
                    values={{ path }}
                  />
                </div>
              </div>
            </div>
            <label className='flex w-full flex-col gap-1'>
              <div className='font-medium'>{translate('title')}:</div>
              <Input
                onBlur={e => {
                  setConversation(prev => ({
                    ...prev,
                    title: e.target.value || prev.title
                  }))
                }}
                onChange={e =>
                  setConversation(prev => ({
                    ...prev,
                    title: e.target.value
                  }))
                }
                value={conversation.title || defaultTitle}
              />
            </label>
            <label className='flex w-full flex-col gap-1'>
              <div className='font-medium'>{translate('description')}:</div>
              <Input.TextArea
                autoSize={{ maxRows: 4, minRows: 2 }}
                className='text-[13px]'
                onBlur={e => {
                  setConversation(prev => ({
                    ...prev,
                    description: e.target.value || prev.description
                  }))
                }}
                onChange={e =>
                  setConversation(prev => ({
                    ...prev,
                    description: e.target.value
                  }))
                }
                value={conversation.description}
              />
            </label>
          </div>
        }
        onCancel={hideConfirm}
        onConfirm={saveConversation}
        open={confirmVisible}
        placement='topRight'
        title={translate('saveAsNotebook.title')}
      >
        <Button
          className='border text-xs'
          color='default'
          onClick={toggleConfirmVisible}
          shape='circle'
          variant='filled'
          {...buttonProps}
        >
          <Tooltip
            arrow={{ pointAtCenter: true }}
            placement='left'
            title={translate('saveAsNotebook.title')}
          >
            <CatalogPublish />
          </Tooltip>
        </Button>
      </Popconfirm>
    </>
  )
}
