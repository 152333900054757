import { INotebookContent, isCode } from '@jupyterlab/nbformat'
import { Contents } from '@jupyterlab/services'
import { useMemo } from 'react'

import { Button, List, Tag } from '@/components'
import { cn } from '@/utils'

import { useTranslator } from '../i18n'

type OhmSpotlightSearchItemProps = {
  item: Contents.IModel
  isLastItem: boolean
  onSelectItem: () => void
}

export const OhmSpotlightSearchItem = (props: OhmSpotlightSearchItemProps) => {
  const { item, isLastItem, onSelectItem } = props
  const { name } = item

  const { translate } = useTranslator()

  const content = item.content as INotebookContent
  const codeCells = content.cells.filter(
    cell => isCode(cell) && cell.source.toString().trim().length > 0
  )

  const renderedTag = useMemo(() => {
    if (name.includes('.shared.ipynb')) {
      return (
        <Tag className='text-xxs' color='orange'>
          {translate('spotlightSearch.fileType.shared')}
        </Tag>
      )
    }
    return (
      <Tag className='text-xxs' color='green'>
        {translate('spotlightSearch.fileType.ai')}
      </Tag>
    )
  }, [name, translate])

  return (
    <List.Item className='border-none !p-0'>
      <Button
        className={cn(
          'grid w-full grid-cols-[70px,1fr,auto] gap-2 overflow-hidden rounded-none text-left',
          'focus:bg-blue-100 focus:outline-none focus-visible:!outline-none',
          { 'rounded-b-md': isLastItem }
        )}
        color='primary'
        onClick={onSelectItem}
        size='large'
        variant='text'
      >
        <div className='text-center'>{renderedTag}</div>
        <div className='overflow-hidden text-ellipsis text-xs'>
          {name.replace('.shared.ipynb', '').replace('.ipynb', '')}
        </div>
        <div className='text-xs text-gray-500'>
          {translate('spotlightSearch.nCodeCells', { count: codeCells.length })}
        </div>
      </Button>
    </List.Item>
  )
}
