import { useMutation } from '@apollo/client'
import { Popconfirm } from 'antd'
import { useAtom } from 'jotai'
import { isEmpty, isEqual } from 'lodash-es'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'
import { useLocation } from 'react-router'

import {
  BaseLayout,
  Button,
  Input,
  Steps,
  Typography,
  notification
} from '@/components'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'

import { GET_REPORTS, SAVE_REPORT } from '../../queries/reports'
import {
  fullReportAtomFamily,
  reportAtomFamily
} from '../../store/report.molecule'
import { ReportRouteParams } from '../../types'

const { Title } = Typography
const { Step } = Steps

const steps = [
  { title: 'Choose Data', routeKey: 'data' },
  { title: 'Add Tables & Charts', routeKey: 'visualizations' },
  { title: 'Preview', routeKey: 'preview' }
]

export type ReportBuilderOutletContext = {
  setCanContinue: (canContinue: boolean) => void
}

export const ReportBuilder = () => {
  const { reportId } = useParams<ReportRouteParams>()
  const [report, setReport] = useAtom(reportAtomFamily({ id: reportId }))
  const [fullReport] = useAtom(fullReportAtomFamily(reportId ?? ''))

  const [canContinue, setCanContinue] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [api, contextHolder] = notification.useNotification()

  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  useEffect(() => {
    if (report.organizationId && report.workspaceId) return
    if (organization_id == null || workspace_id == null) return

    setReport(prev => {
      const next = {
        ...prev,
        organizationId: organization_id,
        workspaceId: workspace_id
      }
      return isEqual(prev, next) ? prev : next
    })
  }, [report, organization_id, workspace_id, setReport])

  const localStorageKey = `report-${reportId}`
  // const [storedReport, updateStoredReport] = useLocalStorage(
  //   localStorageKey,
  //   fullReport
  // )
  const { title, workspaceId, organizationId, schemaVersion } = report

  // TODO: Rip this out once we no longer rely on redux for the staged dataset table rows.
  // This effect will update the staged dataset table rows when the datasetIds in the report change.
  // This can happen if the user refreshes and we have the report data in local storage, so we need
  // redux to be in sync with the report data.
  // const dispatch = use_app_dispatch()
  // const dataset_rows = use_app_selector(select_grid_rows)
  // // useEffect(() => {
  // //   const storedReportDatasetRows = dataset_rows.filter(row =>
  // //     (storedReport?.datasetIds ?? []).includes(row.id)
  // //   )
  // //   if (!isEqual(storedReportDatasetRows, dataset_rows)) {
  // //     dispatch(add_staged_dataset_table_rows(storedReportDatasetRows))
  // //   }
  // // }, [dispatch, dataset_rows, storedReport])
  // /** End of temporary solution */

  // if (storedReport && !report) {
  //   const { visualizations, ...rest } = storedReport
  //   setReport({
  //     ...rest,
  //     visualizationIds: visualizations.map((viz: any) => viz.id)
  //   })
  //   visualizations.forEach(visualizationAtomFamily)
  // }

  // console.log('Report', storedReport)
  // useEffect(() => {
  //   updateStoredReport(fullReport)
  // }, [fullReport, updateStoredReport])

  const [saveReport, { loading: savingReport }] = useMutation(SAVE_REPORT)

  const handleSaveReport = async () => {
    if (reportId == null || savingReport || title == null) return
    try {
      await saveReport({
        variables: {
          id: reportId,
          report_query: JSON.stringify(fullReport),
          title,
          workspace_id: workspaceId,
          organization_id: organizationId,
          schema_version: schemaVersion
        },
        refetchQueries: [GET_REPORTS],
        awaitRefetchQueries: true
      })
      api.success({
        message: 'Report saved successfully',
        description: <div>Redirecting you to your report: "{title}"</div>
      })
      setTimeout(() => {
        navigate(`../../reports/item/${reportId}`)
      }, 2000)
    } catch (e) {
      console.error(e)
    }
  }

  const currentStep = steps.findIndex(step =>
    location.pathname.includes(step.routeKey)
  )

  const nextStepTitle =
    currentStep < steps.length - 1 ? steps[currentStep + 1].title : 'Finish'

  // This navigate needs to happen in an effect, as `react-router` will spit out a warning if it's called in the render phase.
  useEffect(() => {
    if (currentStep === -1) {
      navigate(steps[0].routeKey)
    }
  }, [currentStep, navigate])

  const handleBackClick = () => {
    navigate(steps[currentStep - 1].routeKey)
  }

  const handleNextClick = () => {
    navigate(steps[currentStep + 1].routeKey)
  }

  const handleFinishClick = () => {
    handleSaveReport()
  }

  const handleCancelClick = () => {
    navigate('../../reports')
  }

  const isPreview = currentStep === steps.length - 1
  const nextStepIsFinish = currentStep === steps.length - 1
  const isProcessing = savingReport

  return (
    <div className='mt-5 flex flex-col gap-y-5 overflow-hidden'>
      {contextHolder}
      <BaseLayout>
        <div className='flex flex-row justify-between'>
          <Title level={3}>
            {isPreview ? 'Preview Report' : 'Create Report'}
          </Title>
          <div className='flex flex-row gap-x-2'>
            <Button onClick={handleCancelClick} type='text'>
              Cancel
            </Button>
            {currentStep !== 0 && (
              <>
                <Button onClick={handleBackClick} type='default'>
                  Back
                </Button>
              </>
            )}
            <Popconfirm
              description={
                <div className='flex flex-col gap-2 py-2'>
                  <label className='flex w-full flex-col gap-1'>
                    <div className='font-medium'>Title:</div>
                    <Input
                      ref={input => setTimeout(() => input?.focus(), 0)}
                      onChange={e =>
                        setReport(prev => ({
                          ...prev,
                          title: e.target.value
                        }))
                      }
                      placeholder='Choose a title for your report'
                    />
                  </label>
                </div>
              }
              disabled={!nextStepIsFinish}
              okButtonProps={{
                disabled: isEmpty(report.title)
              }}
              onConfirm={handleFinishClick}
              overlayClassName='[&_.ant-popconfirm-message-text]:flex-1 [&_.ant-popconfirm-description]:flex-1'
              overlayInnerStyle={{ width: '500px' }}
              placement='topRight'
              title='Save Report?'
            >
              <Button
                disabled={!canContinue}
                loading={isProcessing}
                onClick={!nextStepIsFinish ? handleNextClick : undefined}
                type='primary'
              >
                Next: {nextStepTitle}
              </Button>
            </Popconfirm>
          </div>
        </div>
        <Steps current={currentStep} size='small'>
          {steps.map((s, index) => (
            <Step key={index} title={s.title} />
          ))}
        </Steps>
      </BaseLayout>
      <BaseLayout className='flex-1 bg-gray-50 py-4'>
        <Outlet context={{ setCanContinue }} />
      </BaseLayout>
    </div>
  )
}
