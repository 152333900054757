import { useMutation, useQuery } from '@apollo/client'
import { Edit, TrashCan, User, WarningHexFilled } from '@carbon/icons-react'
import { styled } from '@mui/system'
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel
} from '@mui/x-data-grid-pro'
import { useAuthInfo } from '@propelauth/react'
import dayjs from 'dayjs'
import { startCase } from 'lodash-es'
import { useMemo, useRef, useState } from 'react'

import { Button, Popconfirm, Popover, notification } from '@/components'
import { DateFormats } from '@/constants'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import {
  GET_USERS_IN_WORKSPACE,
  REMOVE_USER_FROM_WORKSPACE
} from '@/user/queries/admin'

import { PAGE_SIZE_OPTIONS, UserRoleKeys } from '../constants'
import { isByteratUser } from '../utils'
import { AddUserPopover } from './AddUserPopover'
import { UserRolePopover } from './UserRolePopover'

export default function WorkspaceUsersList() {
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const { user } = useAuthInfo()
  const [api, contextHolder] = notification.useNotification()

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE_OPTIONS[0],
    page: 0
  })

  const queryVariables = {
    organization_id: organization_id as string,
    workspace_ids: [workspace_id as string],
    page_size: paginationModel.pageSize,
    page_from: paginationModel.page
  }

  const { data, previousData, loading } = useQuery(GET_USERS_IN_WORKSPACE, {
    variables: queryVariables,
    skip: !organization_id || !workspace_id
  })

  const [addUserPopoverOpen, setAddUserPopoverOpen] = useState(false)
  const [userPopoverOpenId, setUserPopoverOpenId] = useState<string | null>(
    null
  )

  const [removeUser] = useMutation(REMOVE_USER_FROM_WORKSPACE)
  const handlePopoverOpen = (id: string) => {
    setUserPopoverOpenId(id)
  }

  const handlePopoverClose = () => {
    setUserPopoverOpenId(null)
  }

  const handleRemove = async (id: string) => {
    const { data, errors } = await removeUser({
      variables: {
        organization_id: organization_id as string,
        workspace_ids: [workspace_id as string],
        user_id: id
      },
      refetchQueries: [GET_USERS_IN_WORKSPACE]
    })

    if (errors || !data) {
      api.error({
        message: 'Failed to remove user from workspace'
      })
    } else {
      api.success({
        message: 'User removed from workspace successfully'
      })
    }
  }

  const totalRowCount = data?.get_users_in_workspace?.total
  const rowCountRef = useRef(totalRowCount ?? 0)
  const rowCount = useMemo(() => {
    if (totalRowCount != null) {
      rowCountRef.current = totalRowCount
    }
    return rowCountRef.current
  }, [totalRowCount])

  const previousRows = previousData?.get_users_in_workspace.data ?? []
  const currentRows = data?.get_users_in_workspace.data ?? []
  const rowsToRender = loading ? previousRows : currentRows

  const columnDefs: GridColDef[] = Object.values(UserRoleKeys).map(key => ({
    field: key,
    headerName: key,
    minWidth: key === UserRoleKeys.Id ? 120 : 200,
    flex: key === UserRoleKeys.Id ? undefined : 1,
    disableReorder: true,
    sortable: true,
    renderHeader: ({ field }) => {
      switch (field) {
        case UserRoleKeys.Id:
          return ''
        case UserRoleKeys.Role:
          return 'Workspace Role'
        default:
          return startCase(field)
      }
    },
    renderCell: ({ value, row }) => {
      switch (key) {
        case UserRoleKeys.UserName:
          return (
            <div className='flex flex-row items-center gap-x-2'>
              {user?.email === row.email_address ? (
                <User size={16} />
              ) : (
                <div className='bg-none w-4 h-4 rounded-full'></div>
              )}
              <div className='py-1 overflow-hidden text-ellipsis'>{value}</div>
            </div>
          )
        case UserRoleKeys.Email:
          return (
            <div className='py-1 overflow-hidden text-ellipsis'>{value}</div>
          )
        case UserRoleKeys.Role:
          return (
            <div className='flex flex-row items-center gap-x-2'>
              {isByteratUser(row.email_address) ? (
                <img
                  alt='Byterat Logo'
                  height={16}
                  src='/byterat_circle_logo.png'
                  width={16}
                />
              ) : (
                <div className='bg-none w-4 h-4 rounded-full'></div>
              )}
              <div className='py-1 overflow-hidden text-ellipsis'>
                {startCase(value)}
              </div>
            </div>
          )
        case UserRoleKeys.CreatedAt:
          return (
            <div className='flex flex-row items-center gap-x-2'>
              {value !== null
                ? dayjs.utc(value).local().format(DateFormats.DATE_TIME_WITH_TZ)
                : null}
            </div>
          )

        case UserRoleKeys.Id:
          // Users cannot edit their own role
          const disabled =
            row.email_address === user?.email ||
            (isByteratUser(row.email_address) &&
              !isByteratUser(user?.email ?? ''))

          return (
            <div className='flex py-2 self-start'>
              <Popover
                content={
                  <UserRolePopover
                    onClose={() => handlePopoverClose()}
                    row={row}
                  />
                }
                onOpenChange={open => {
                  if (!open) {
                    handlePopoverClose()
                  }
                }}
                open={userPopoverOpenId === row.email_address}
                placement='bottomRight'
                trigger='click'
              >
                <Button
                  className='px-1.5 text-xs font-medium'
                  disabled={disabled}
                  onClick={() => handlePopoverOpen(row.email_address)}
                  type='text'
                >
                  <Edit size={16} />
                </Button>
              </Popover>
              <Popconfirm
                key='delete'
                cancelText='No'
                description={
                  <div className='my-2 text-xs'>
                    <span className='font-mono font-medium mr-1 p-0.5 bg-yellow-100'>
                      {row.user_name}
                    </span>
                    will be removed from the workspace
                  </div>
                }
                icon={
                  <WarningHexFilled className='mr-1 text-amber-500' size={22} />
                }
                okText='Yes'
                onConfirm={() => handleRemove(row.id)}
                placement='topRight'
                title='Are you sure you want to remove this user from the workspace?'
              >
                <Button
                  danger
                  className='px-1.5 text-xs font-medium'
                  disabled={disabled}
                  type='text'
                >
                  <TrashCan />
                </Button>
              </Popconfirm>
            </div>
          )

        default:
          return value
      }
    }
  }))

  return (
    <div className='flex flex-col gap-y-4'>
      {contextHolder}
      <div className='flex flex-row justify-end'>
        <Popover
          content={
            <AddUserPopover onClose={() => setAddUserPopoverOpen(false)} />
          }
          onOpenChange={open => {
            if (!open) {
              setAddUserPopoverOpen(false)
            }
          }}
          open={addUserPopoverOpen}
          placement='bottomRight'
          trigger='click'
        >
          <Button onClick={() => setAddUserPopoverOpen(true)} type='primary'>
            Add User
          </Button>
        </Popover>
      </div>
      <StyledDatagrid
        columns={columnDefs}
        loading={loading}
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        rowCount={rowCount}
        rows={rowsToRender}
      />
    </div>
  )
}

export const StyledDatagrid = styled(DataGridPro)(({ theme }) => ({
  height: 'max-content',
  minHeight: '50vh',
  maxHeight: 'calc(100vh - 170px)',
  borderRadius: theme.shape.borderRadius,
  '.MuiDataGrid-container--top': {
    backgroundColor: `${theme.palette.gray[100]}!important`
  },
  '.MuiDataGrid-main': {
    overflowY: 'auto'
  },
  '.MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center'
  },
  '.MuiDataGrid-row:hover': {
    background: theme.palette.gray[50]
  },
  '.MuiDataGrid-footerContainer': {
    border: 0,
    borderTop: `1px solid ${theme.palette.divider}`
  },
  '.MuiDataGrid-overlayWrapperInner': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '.MuiTablePagination-root': {
    width: '100%',
    '.MuiTablePagination-toolbar': {
      border: 0,
      '.MuiInputBase-root': {
        border: 0,
        background: 'transparent',
        boxShadow: 'none',
        margin: 0
      },
      '.MuiSelect-select': {
        border: 0
      },
      '.MuiTablePagination-spacer': {
        display: 'none'
      },
      '.MuiTablePagination-displayedRows': {
        flex: 1,
        textAlign: 'right'
      }
    }
  }
}))
