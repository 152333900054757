import { Contents } from '@jupyterlab/services'
import dayjs from 'dayjs'
import { Differ, Viewer } from 'json-diff-kit'
import 'json-diff-kit/dist/viewer.css'
import { createContext } from 'react'

import { DateFormats } from '@/constants'
import { Iris_Shared_Notebook } from '@/gql_generated/graphql'
import { cn } from '@/utils'

import { useTranslator } from '../i18n'

type PotentialMatch = {
  existingNotebook?: Iris_Shared_Notebook
  newNotebook: Contents.IModel
}
export type NotebookMatches = {
  [fileName: string]: PotentialMatch
}

type ModalParentContextType = {
  matches: NotebookMatches
}

const defaultContextValue: ModalParentContextType = {
  matches: {}
}
const ModalParentContext =
  createContext<ModalParentContextType>(defaultContextValue)

function ModalTitle() {
  const { translate } = useTranslator()
  return translate('confirmOverwriteSharedNotebook.title')
}

function ModalContent({ matches }: { matches: NotebookMatches }) {
  const { translate } = useTranslator()

  const differ = new Differ({
    arrayDiffMethod: 'lcs'
  })
  if (matches == null) return null

  const { newNotebook, existingNotebook } = Object.values(matches)[0]

  if (existingNotebook == null) return null

  const existingNotebookJson = JSON.parse(existingNotebook.ipynb_json)
  const diff = differ.diff(existingNotebookJson, newNotebook)

  const linesAdded = diff[1].filter(d => d.type === 'add').length
  const linesRemoved = diff[0].filter(d => d.type === 'remove').length
  const linesModified = diff[1].filter(d => d.type === 'modify').length

  // Scroll to the first diff
  setTimeout(() => {
    document
      .querySelector('.json-diff-viewer td:not(.line-equal)')
      ?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
  }, 0)

  return (
    <div>
      <div>
        <p>{translate('confirmOverwriteSharedNotebook.inquiry')}</p>
        <strong className='text-amber-500'>
          {translate('confirmOverwriteSharedNotebook.warning')}
        </strong>
      </div>
      <div className='my-4 rounded-md bg-gray-100 p-4'>
        <div className='grid grid-cols-4 gap-4 font-bold'>
          <div>{translate('fileName')}</div>
          <div>{translate('lastModified')}</div>
          <div>{translate('dateCreated')}</div>
          <div>{translate('size')}</div>
        </div>
        <div key={newNotebook.name} className='grid grid-cols-4 gap-4'>
          <div>{existingNotebook.file_name}</div>
          <div>
            {dayjs
              .utc(existingNotebook.updated_at)
              .local()
              .format(DateFormats.DATE_TIME_WITH_TZ)}
          </div>
          <div>
            {dayjs
              .utc(existingNotebook.created_at)
              .local()
              .format(DateFormats.DATE_TIME_WITH_TZ)}
          </div>
          <div>{translate('xBytes', { count: existingNotebookJson.size })}</div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='text-md mb-2 flex flex-row justify-between font-semibold'>
          <div>{translate('diff')}:</div>
          <div className='ml-2 flex flex-row gap-x-2 rounded-md border border-gray-300 px-2 py-0.5 text-sm'>
            {translate('changes')}:
            <span className='text-green-500' title={translate('added')}>
              +{linesAdded}
            </span>
            <span className='text-red-500' title={translate('removed')}>
              -{linesRemoved}
            </span>
            <span className='text-amber-500' title={translate('modified')}>
              ~{linesModified}
            </span>
          </div>
        </div>
        <div className='h-[40vh] overflow-y-auto'>
          <Viewer
            lineNumbers
            diff={diff}
            highlightInlineDiff={true}
            inlineDiffOptions={{
              mode: 'word',
              wordSeparator: ' '
            }}
          />
        </div>
      </div>
    </div>
  )
}

export const ConfirmOverwriteSharedNotebooksConfig = {
  className: cn('!w-3/5 h-5/6 p-0'),
  content: (
    <ModalParentContext.Consumer>
      {({ matches }) => <ModalContent matches={matches} />}
    </ModalParentContext.Consumer>
  ),
  title: (
    <ModalParentContext.Consumer>
      {({ matches }) => <ModalTitle />}
    </ModalParentContext.Consumer>
  )
}

export const ConfirmOverwriteSharedNotebooksProvider =
  ModalParentContext.Provider
