import { ApolloQueryResult, useMutation } from '@apollo/client'
import {
  EventWarning,
  OverflowMenuHorizontal,
  ZoomReset
} from '@carbon/icons-react'
import { Divider } from '@mui/material'
import { useAtom, useAtomValue } from 'jotai'
import { RefObject, useMemo, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

import {
  Button,
  Popconfirm,
  Popover,
  Select,
  Tooltip,
  notification
} from '@/components'
import { SAVE_ANNOTATIONS } from '@/datasets/queries/annotations'
import { InCyclePanelCycleFilters } from '@/insights/charts/cycle_observations/controls/InCyclePanelCycleFilters'
import { InsightsChartFilterPanelRoot } from '@/insights/controls/InsightsChartFilterPanelRoot'
import { InsightsPanelPropertyFilters } from '@/insights/controls/InsightsPanelPropertyFilters'
import { InsightsPanelXAxis } from '@/insights/controls/InsightsPanelXAxis'
import { InsightsPanelYAxis } from '@/insights/controls/InsightsPanelYAxis'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { mapListKeysToValues } from '@/utils/mapKeyToValue'

import { CHART_TYPE } from '../charts'
import {
  chartSelectedAnnotationsAtom,
  chartStatesFamily
} from '../jotai/charts.atoms'
import { AnnotationMap, SelectorOption } from '../types'
import { ANNOTATION_TYPES } from '../types'
import { InsightsPanelGroupBy } from './InsightsPanelGroupBy'
import { InsightsPanelTarget } from './InsightsPanelTarget'
import { Menu_Nav_Provider, Menu_Panel } from './menus'
import { Menu_Panel_Id } from './menus/constants'

type InsightsChartFiltersProps = {
  chartId: string
  chartRef: RefObject<ReactApexChart>
  filtersDisabled?: boolean
  annotationsDisabled?: boolean
  refetchGraph: () => Promise<ApolloQueryResult<any>>
}

export const InsightsChartFilters = (props: InsightsChartFiltersProps) => {
  const {
    chartId,
    chartRef,
    filtersDisabled = false,
    annotationsDisabled = false,
    refetchGraph
  } = props

  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const chartState = useAtomValue(chartStatesFamily({ id: chartId }))
  const { metricType, chartType } = chartState

  // Popover state
  const [annotationsPopoverOpen, setAnnotationsPopoverOpen] = useState(false)
  const [filtersPopoverOpen, setFiltersPopoverOpen] = useState(false)
  const popoverRef = useRef<HTMLDivElement | null>(null)

  const [annotation, setAnnotation] = useState<SelectorOption | undefined>(
    ANNOTATION_TYPES[0]
  )
  const [api, contextHolder] = notification.useNotification()

  const [selectedAnnotations] = useAtom(chartSelectedAnnotationsAtom)

  const annotationsData = useMemo(() => {
    const _annotationsData: AnnotationMap = {}
    for (const annotation of selectedAnnotations) {
      const { datasetKey, xPropertyValue, yProperty, yPropertyValue } =
        annotation

      if (!_annotationsData[datasetKey]) {
        _annotationsData[datasetKey] = {}
      }

      if (!_annotationsData[datasetKey][yProperty.label]) {
        _annotationsData[datasetKey][yProperty.label] = []
      }

      _annotationsData[datasetKey][yProperty.label].push({
        xPropertyValue,
        yPropertyValue: yPropertyValue
      })
    }
    return _annotationsData
  }, [selectedAnnotations])

  const [saveAnnotations] = useMutation(SAVE_ANNOTATIONS, {
    variables: {
      organization_id: organization_id!,
      workspace_id: workspace_id!,
      chart_type: metricType,
      data_points: selectedAnnotations.map(annotation => ({
        dataset_id: annotation.datasetKey,
        x_axis_property: annotation.xProperty.key,
        x_axis_value: annotation.xPropertyValue,
        y_axis_property: annotation.yProperty.key,
        y_axis_value: annotation.yPropertyValue
      })),
      column_name: '',
      comment: annotation?.label || ''
    },
    onCompleted: async () => {
      api.success({
        message: 'Annotations saved'
      })

      await refetchGraph()
    },
    onError: () => {
      api.error({
        message: 'Error saving annotations'
      })
    }
  })

  const onAnnotationChange = (value: string | null) => {
    setAnnotation(ANNOTATION_TYPES.find(a => a.key === value))
  }

  return (
    <div className='flex items-center gap-1'>
      {contextHolder}
      <Tooltip placement='topRight' title='Reset Zoom'>
        <Button
          className='-mb-2'
          onClick={() => {
            // @ts-expect-error Not sure why the type for this isn't correct, but it works
            const _chartInstance = chartRef?.current?.chart as ApexCharts
            _chartInstance?.resetSeries()
          }}
          shape='circle'
        >
          <ZoomReset size={20} />
        </Button>
      </Tooltip>
      <Popconfirm
        classNames={{
          body: 'w-[500px] [&_.ant-popconfirm-message-text]:flex-1 p-4'
        }}
        description={
          <div className='flex flex-col gap-2 py-2'>
            <Divider sx={{ marginBlock: 0 }} />
            <div className='max-h-[350px] overflow-y-auto'>
              {Object.entries(annotationsData).length > 0 ? (
                <AnnotationDatasetGroup annotationsData={annotationsData} />
              ) : (
                <div className='italic'>No data selected</div>
              )}
            </div>

            <label className='mt-2 flex w-full flex-col gap-1'>
              <Select
                allowClear
                showSearch
                aria-labelledby='annotation'
                className='flex-1'
                id='annotation'
                onChange={onAnnotationChange}
                options={mapListKeysToValues(ANNOTATION_TYPES)}
                placeholder='Select Annotation'
                value={annotation}
              />
            </label>
          </div>
        }
        disabled={annotationsDisabled}
        icon={null}
        onConfirm={async () => await saveAnnotations()}
        placement='topRight'
        title='Tag Selected Data'
      >
        <Tooltip title='Tag selected data'>
          <Button
            className='-mb-2'
            disabled={annotationsDisabled || chartType === CHART_TYPE.LINE}
            shape='circle'
          >
            <EventWarning size={18} />
          </Button>
        </Tooltip>
      </Popconfirm>

      <Popover
        classNames={{
          body: '!py-0 !px-2'
        }}
        content={
          <div ref={popoverRef}>
            <Menu_Nav_Provider>
              <Menu_Panel panel_id={Menu_Panel_Id.ROOT}>
                <InsightsChartFilterPanelRoot
                  chartId={chartId}
                  chartRef={chartRef}
                  chartTitle='Chart configuration'
                />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.X_AXIS}>
                <InsightsPanelXAxis chartId={chartId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.Y_AXIS}>
                <InsightsPanelYAxis chartId={chartId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.GROUP_BY}>
                <InsightsPanelGroupBy chartId={chartId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.IN_CYCLE_FILTERS}>
                <InsightsPanelPropertyFilters chartId={chartId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.CYCLE_FILTERS}>
                <InCyclePanelCycleFilters chartId={chartId} />
              </Menu_Panel>

              <Menu_Panel panel_id={Menu_Panel_Id.TARGET}>
                <InsightsPanelTarget chartId={chartId} />
              </Menu_Panel>
            </Menu_Nav_Provider>
          </div>
        }
        open={filtersPopoverOpen}
        placement='bottomRight'
        trigger={['click']}
      >
        <Button
          className='-mb-2'
          disabled={filtersDisabled}
          onClick={() => {
            setFiltersPopoverOpen(!filtersPopoverOpen)
          }}
          shape='circle'
        >
          <OverflowMenuHorizontal size={24} />
        </Button>
      </Popover>

      {(filtersPopoverOpen || annotationsPopoverOpen) && (
        <div
          className='fixed left-0 top-0 z-[1000] h-full w-full'
          onClick={() => {
            setFiltersPopoverOpen(false)
            setAnnotationsPopoverOpen(false)
          }}
        />
      )}
    </div>
  )
}

const AnnotationDatasetGroup = ({
  annotationsData
}: {
  annotationsData: AnnotationMap
}) => {
  return (
    <div className='flex flex-col gap-4'>
      {Object.entries(annotationsData).map(([datasetKey, yProperties]) => (
        <div key={datasetKey}>
          <label key={datasetKey} className='font-bold'>
            {datasetKey}:
          </label>
          {Object.entries(yProperties).map(([yProperty, dataPoints]) => (
            <AnnotationYPropertyGroup
              key={yProperty}
              dataPoints={dataPoints}
              yProperty={yProperty}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

const AnnotationYPropertyGroup = ({
  yProperty,
  dataPoints
}: {
  yProperty: string
  dataPoints: { xPropertyValue: number; yPropertyValue: number }[]
}) => {
  return (
    <div key={yProperty}>
      <label key={yProperty} className='indent-2'>
        {yProperty}:
      </label>
      <div className='flex flex-col gap-2 indent-4'>
        {dataPoints.map(({ xPropertyValue, yPropertyValue }) => (
          <AnnotationDataPoint
            key={`${xPropertyValue}-${yPropertyValue}`}
            xPropertyValue={xPropertyValue}
            yPropertyValue={yPropertyValue}
          />
        ))}
      </div>
    </div>
  )
}

const AnnotationDataPoint = ({
  xPropertyValue,
  yPropertyValue
}: {
  xPropertyValue: number
  yPropertyValue: number
}) => {
  return (
    <div className='flex flex-col'>
      <label className='text-sm text-gray-500'>x: {xPropertyValue}</label>
      <label className='text-sm text-gray-500'>y: {yPropertyValue}</label>
    </div>
  )
}
