import { useMutation } from '@apollo/client'
import { Box } from '@mui/system'
import { Select, notification } from 'antd'
import { startCase } from 'lodash-es'
import { useState } from 'react'

import { Button, Input } from '@/components'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import {
  CREATE_USER_AND_ADD_TO_WORKSPACE,
  GET_USERS_IN_WORKSPACE
} from '@/user/queries/admin'
import { ADD_USER_TO_WORKSPACE } from '@/user/queries/admin'

import { EMAIL_REGEXP, UserRoleValues } from '../constants'

export const AddUserPopover = ({ onClose }: { onClose: () => void }) => {
  const [api, contextHolder] = notification.useNotification()
  const [selectedRole, setSelectedRole] = useState(UserRoleValues.Member)
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [email, setEmail] = useState('')

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userName, setUserName] = useState('')

  const [showCreateUserFields, setShowCreateUserFields] = useState(false)

  const [addUserToWorkspace, { loading: addingUserToWorkspace }] = useMutation(
    ADD_USER_TO_WORKSPACE
  )

  const [
    createUserAndAddToWorkspace,
    { loading: creatingUserAndAddingToWorkspace }
  ] = useMutation(CREATE_USER_AND_ADD_TO_WORKSPACE)

  const handleAddUser = async () => {
    if (!EMAIL_REGEXP.test(email)) {
      api.error({
        message: 'Invalid email address'
      })
      return
    }

    const { data, errors } = await addUserToWorkspace({
      variables: {
        organization_id: organization_id as string,
        workspace_ids: [workspace_id as string],
        email,
        role: selectedRole
      },
      refetchQueries: [GET_USERS_IN_WORKSPACE]
    })

    if (errors || !data) {
      api.error({
        message: 'Failed to add user to workspace'
      })
    } else {
      const { existing_user, existing_in_workspace } =
        data.add_user_to_workspace
      if (!existing_user) {
        const defaultUserName = email.split('@')[0]
        setUserName(defaultUserName)
        setShowCreateUserFields(true)
      } else {
        const message = existing_in_workspace
          ? 'User already exists in workspace'
          : 'User added to workspace successfully'

        api.success({
          message
        })

        onClose()
      }
    }
  }

  const handleCreateUser = async () => {
    const { data, errors } = await createUserAndAddToWorkspace({
      variables: {
        organization_id: organization_id as string,
        workspace_ids: [workspace_id as string],
        create_user_input: {
          email: email,
          user_name: userName,
          role: selectedRole,
          first_name: firstName,
          last_name: lastName,
        }
      },
      refetchQueries: [GET_USERS_IN_WORKSPACE]
    })

    if (errors || !data) {
      api.error({
        message: 'Failed to create user and add to workspace'
      })
    } else {
      api.success({
        message: 'User created and added to workspace successfully'
      })
    }

    onClose()
  }

  return (
    <Box className='flex flex-col gap-y-1 p-1' width={400}>
      {contextHolder}
      <div className='font-semibold'>Create User in Workspace</div>

      <div className='flex flex-col gap-y-1 mt-2'>
        <div className='text-xs text-gray-500'>Email</div>
        <Input
          disabled={showCreateUserFields}
          onChange={e => setEmail(e.target.value)}
          placeholder='example@gmail.com'
          value={email}
        />
      </div>

      <div className='flex flex-col gap-y-1 mt-2'>
        <div className='text-xs text-gray-500'>Workspace Role</div>
        <Select
          disabled={showCreateUserFields}
          onChange={setSelectedRole}
          options={Object.values(UserRoleValues).map(role => ({
            label: startCase(role),
            value: role
          }))}
          value={selectedRole}
        />
      </div>
      {showCreateUserFields && (
        <div className='mt-4'>
          <label className='text-sm italic'>
            User not found. Please fill out the following fields
          </label>

          <div className='flex gap-x-2 mt-2'>
            <div className='flex flex-col gap-y-1 flex-1'>
              <div className='text-xs text-gray-500'>First Name</div>
              <Input
                onChange={e => setFirstName(e.target.value)}
                placeholder='John'
                value={firstName}
              />
            </div>

            <div className='flex flex-col gap-y-1 flex-1'>
              <div className='text-xs text-gray-500'>Last Name</div>
              <Input
                onChange={e => setLastName(e.target.value)}
                placeholder='Doe'
                value={lastName}
              />
            </div>
          </div>

          <div className='flex flex-col gap-y-1 mt-2'>
            <div className='text-xs text-gray-500'>User Name</div>
            <Input
              onChange={e => setUserName(e.target.value)}
              placeholder='example'
              value={userName}
            />
          </div>
        </div>
      )}

      <div className='flex justify-end gap-x-2 mt-2'>
        <Button onClick={onClose} type='text'>
          Cancel
        </Button>
        <Button
          loading={
            showCreateUserFields
              ? creatingUserAndAddingToWorkspace
              : addingUserToWorkspace
          }
          onClick={showCreateUserFields ? handleCreateUser : handleAddUser}
          type='primary'
        >
          Save
        </Button>
      </div>
    </Box>
  )
}
